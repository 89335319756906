import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: any,
  Object: any,
  Upload: any,
};

export type AcceptedMentorCommentRewardNotification = SimpleNotification & {
   __typename?: 'AcceptedMentorCommentRewardNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ActivateChannelPayload = {
   __typename?: 'ActivateChannelPayload',
  channel?: Maybe<Channel>,
};

export type ActivitiesDeletePayload = {
   __typename?: 'ActivitiesDeletePayload',
  activities?: Maybe<Array<Maybe<Activity>>>,
};

export type ActivitiesStatusUpdateInput = {
  ids: Array<Scalars['ID']>,
  refusalReason?: Maybe<Scalars['String']>,
  status?: Maybe<ActivityStatus>,
};

export type ActivitiesStatusUpdatePayload = {
   __typename?: 'ActivitiesStatusUpdatePayload',
  activities?: Maybe<Array<Maybe<Activity>>>,
  count?: Maybe<Scalars['Int']>,
};

export type ActivitiesUpdateInput = {
  ids: Array<Scalars['ID']>,
  refusalReason?: Maybe<Scalars['String']>,
  status?: Maybe<ActivityStatus>,
};

export type Activity = {
   __typename?: 'Activity',
  id: Scalars['ID'],
  organizationName?: Maybe<Scalars['String']>,
  activityTypeID?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  chatRoom?: Maybe<ChatRoom>,
  activityEndAt?: Maybe<Scalars['Date']>,
  activityReference?: Maybe<ActivityReference>,
  activityStartAt?: Maybe<Scalars['Date']>,
  activityType?: Maybe<ActivityType>,
  additionalBenefit?: Maybe<Scalars['String']>,
  addresses: Array<Maybe<ActivityAddress>>,
  advertisement?: Maybe<Advertisement>,
  applicationFiles?: Maybe<Array<Maybe<ActivityFile>>>,
  applicationProcedure: Array<Maybe<ActivityApplicationProcedure>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypes?: Maybe<Array<Maybe<ApplyType>>>,
  awards?: Maybe<Scalars['String']>,
  benefits?: Maybe<Array<Maybe<Benefit>>>,
  categories?: Maybe<Array<Maybe<Category>>>,
  cost?: Maybe<Scalars['Int']>,
  country?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  dateRepresentation?: Maybe<DateRepresentationType>,
  deadlineStatus?: Maybe<ActivityDeadlineStatus>,
  detailText?: Maybe<ActivityText>,
  distinctInternTypes?: Maybe<Array<ActivityInternType>>,
  duties: DutyConnection,
  education?: Maybe<ActivityQualificationEducation>,
  educationTypes?: Maybe<Array<ActivityEducationDetailType>>,
  facetimeDay?: Maybe<Scalars['String']>,
  facetimePeriod?: Maybe<Scalars['String']>,
  facetimeTime?: Maybe<Scalars['String']>,
  facetimeWeekday?: Maybe<Scalars['String']>,
  files?: Maybe<Array<Maybe<ActivityFile>>>,
  hasQuestionTemplate: Scalars['Boolean'],
  homepageURL?: Maybe<Scalars['String']>,
  industries: Array<Maybe<Industry>>,
  integerTypes?: Maybe<Array<Maybe<ActivityIntegerType>>>,
  integers?: Maybe<Array<Maybe<ActivityInteger>>>,
  interests?: Maybe<Array<Maybe<Interest>>>,
  isAd?: Maybe<Scalars['Boolean']>,
  isAllSameQuestion?: Maybe<Scalars['Boolean']>,
  isApplicationProcedureVisible?: Maybe<Scalars['Boolean']>,
  isApplied: Scalars['Boolean'],
  isConfirmed: Scalars['Boolean'],
  isDeleted: Scalars['Boolean'],
  isExpectedGraduateAllowed?: Maybe<Scalars['Boolean']>,
  isHidden?: Maybe<Scalars['Boolean']>,
  isManagerAuthed: Scalars['Boolean'],
  isManagerEmailVisible: Scalars['Boolean'],
  isManagerPhoneNumberVisible: Scalars['Boolean'],
  isSalaryDecidedAfterInterview?: Maybe<Scalars['Boolean']>,
  isSalaryDecidedByCompanyPolicy?: Maybe<Scalars['Boolean']>,
  isScrapped: Scalars['Boolean'],
  isSponsored?: Maybe<Scalars['Boolean']>,
  isWorkHourDecidedAfterInterview?: Maybe<Scalars['Boolean']>,
  jobTypes?: Maybe<Array<JobType>>,
  logoImage?: Maybe<ActivityFile>,
  manager?: Maybe<User>,
  managerDepartment?: Maybe<Scalars['String']>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerOneline: Scalars['String'],
  managerPhoneNumber?: Maybe<Scalars['String']>,
  managerReplies?: Maybe<Array<Maybe<Reply>>>,
  maxSalary?: Maybe<Scalars['Int']>,
  minSalary?: Maybe<Scalars['Int']>,
  onoffline?: Maybe<Onoffline>,
  onofflineId?: Maybe<Scalars['ID']>,
  ordinal: Scalars['Int'],
  organizationBusinessRegistrationNumber?: Maybe<Scalars['String']>,
  organizationType?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  originManagerEmail?: Maybe<Scalars['String']>,
  originManagerPhoneNumber?: Maybe<Scalars['String']>,
  outLinks?: Maybe<Array<Maybe<ActivityOutLink>>>,
  percent: Scalars['Int'],
  posterImage?: Maybe<ActivityFile>,
  questionType?: Maybe<QuestionType>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitInformations: Array<Maybe<RecruitInformation>>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType: ActivityRecruitType,
  refusalReason: Scalars['String'],
  regionDistricts?: Maybe<Array<Maybe<RegionDistrict>>>,
  regions?: Maybe<Array<Maybe<Region>>>,
  relatedContentUpdatedAt?: Maybe<Scalars['Date']>,
  replies?: Maybe<Array<Maybe<Reply>>>,
  replyCount?: Maybe<Scalars['Int']>,
  rootCategories: Array<Category>,
  salaryType?: Maybe<ActivityWorkingConditionSalarySalaryType>,
  scrapCount: Scalars['Int'],
  scrapUsers: Array<User>,
  skills?: Maybe<Array<Maybe<Skill>>>,
  status?: Maybe<ActivityStatus>,
  statusUpdatedAt?: Maybe<Scalars['Date']>,
  targets?: Maybe<Array<Maybe<ActivityTarget>>>,
  tenThousandUnitOfReward?: Maybe<Scalars['Int']>,
  texts?: Maybe<Array<Maybe<ActivityText>>>,
  thumbnailImage?: Maybe<ActivityFile>,
  type?: Maybe<Scalars['Int']>,
  userScraps?: Maybe<Array<Maybe<UserScrap>>>,
  viewCount: Scalars['Int'],
  welfareBenefits: Array<Maybe<WelfareBenefit>>,
  workEndTime?: Maybe<Scalars['String']>,
  workHourType?: Maybe<ActivityWorkingConditionWorkHourType>,
  workStartTime?: Maybe<Scalars['String']>,
  workingHours?: Maybe<Scalars['String']>,
  youtubeURL?: Maybe<Scalars['String']>,
};


export type ActivityAdvertisementArgs = {
  adType: AdType
};


export type ActivityDutiesArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<DutyFeedFilters>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<DutyOrder>
};

export type ActivityAddress = {
   __typename?: 'ActivityAddress',
  address: Scalars['String'],
  bcode: Scalars['String'],
  bname: Scalars['String'],
  detailAddress?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isAbroad: Scalars['Boolean'],
  isPossibleWorkingFromHome: Scalars['Boolean'],
  sido: Scalars['String'],
  sigungu: Scalars['String'],
  sigunguCode: Scalars['String'],
  zoneCode: Scalars['String'],
};

export type ActivityApplicationProcedure = {
   __typename?: 'ActivityApplicationProcedure',
  createdAt: Scalars['Date'],
  description: Scalars['String'],
  id: Scalars['ID'],
  procedureOrder: Scalars['Int'],
  procedureType: Scalars['String'],
};

export enum ActivityApplicationProcedureProcedureType {
  APTITUDE = 'APTITUDE',
  DOCUMENT = 'DOCUMENT',
  INTERVIEW = 'INTERVIEW',
  OTHERS = 'OTHERS',
  REFERENCE = 'REFERENCE'
}

export type ActivityApprovedNotification = SimpleNotification & {
   __typename?: 'ActivityApprovedNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ActivityCalendar = {
   __typename?: 'ActivityCalendar',
  end: ActivityCalendarConnection,
  start: ActivityCalendarConnection,
};

export type ActivityCalendarConnection = {
   __typename?: 'ActivityCalendarConnection',
  nodes: Array<ActivityCalendarMap>,
  totalCount: Scalars['Int'],
};

export type ActivityCalendarEntry = {
   __typename?: 'ActivityCalendarEntry',
  date: Scalars['Date'],
  end?: Maybe<ActivityConnection>,
  scrapEnd?: Maybe<ActivityConnection>,
  scrapStart?: Maybe<ActivityConnection>,
  scrapStartAndEnd?: Maybe<ActivityConnection>,
  start?: Maybe<ActivityConnection>,
  startAndEnd?: Maybe<ActivityConnection>,
};


export type ActivityCalendarEntryEndArgs = {
  filterBy?: Maybe<ActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryScrapEndArgs = {
  filterBy?: Maybe<ScrapActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryScrapStartArgs = {
  filterBy?: Maybe<ScrapActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryScrapStartAndEndArgs = {
  filterBy?: Maybe<ScrapActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryStartArgs = {
  filterBy?: Maybe<ActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryStartAndEndArgs = {
  filterBy?: Maybe<ActivityFilter>,
  pagination?: Maybe<Pagination>
};

export type ActivityCalendarEntryConnection = {
   __typename?: 'ActivityCalendarEntryConnection',
  nodes: Array<ActivityCalendarEntry>,
  totalCount: Scalars['Int'],
};

export enum ActivityCalendarGroupByField {
  RECRUIT_CLOSEAT = 'RECRUIT_CLOSEAT',
  RECRUIT_STARTAT = 'RECRUIT_STARTAT'
}

export type ActivityCalendarMap = {
   __typename?: 'ActivityCalendarMap',
  date: Scalars['String'],
  items: Array<Activity>,
};

export type ActivityCardNewsListInput = {
  activityID: Scalars['ID'],
};

export type ActivityCardNewsListPayload = {
   __typename?: 'ActivityCardNewsListPayload',
  cardNews?: Maybe<Array<ActivityFile>>,
  count: Scalars['Int'],
};

export type ActivityConnection = {
   __typename?: 'ActivityConnection',
  nodes: Array<Activity>,
  totalCount: Scalars['Int'],
};

export type ActivityCountsPayload = {
   __typename?: 'ActivityCountsPayload',
  activity: Scalars['Int'],
  club: Scalars['Int'],
  contest: Scalars['Int'],
  education: Scalars['Int'],
  intern: Scalars['Int'],
  recruit: Scalars['Int'],
};

export enum ActivityDeadlineStatus {
  ALL = 'ALL',
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type ActivityDeletePayload = {
   __typename?: 'ActivityDeletePayload',
  activity?: Maybe<Activity>,
};

export type ActivityEducationDetailType = {
   __typename?: 'ActivityEducationDetailType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ActivityFile = {
   __typename?: 'ActivityFile',
  activity?: Maybe<Activity>,
  attachment?: Maybe<Attachment>,
  filename?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  type?: Maybe<ActivityFileType>,
  url?: Maybe<Scalars['String']>,
};

export type ActivityFileAttachmentInput = {
  attachmentID?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['Int']>,
  url?: Maybe<Scalars['String']>,
};

export type ActivityFileCreateInput = {
  activityID?: Maybe<Scalars['ID']>,
  attachmentID?: Maybe<Scalars['ID']>,
  fileTypeID: Scalars['ID'],
  filename?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type ActivityFileDeletePayload = {
   __typename?: 'ActivityFileDeletePayload',
  activityFile?: Maybe<ActivityFile>,
};

export type ActivityFileType = {
   __typename?: 'ActivityFileType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ActivityFilter = {
  activityTypeID?: Maybe<Scalars['ID']>,
  activityTypeIDs?: Maybe<Array<Scalars['ID']>>,
  benefitIDs?: Maybe<Array<Scalars['ID']>>,
  categoryIDs?: Maybe<Array<Scalars['ID']>>,
  cost?: Maybe<Array<EducationCost>>,
  createdAtTerm?: Maybe<CreatedAtTerm>,
  educationTypeIds?: Maybe<Array<Scalars['ID']>>,
  excludedStatus?: Maybe<ActivityStatus>,
  interestIDs?: Maybe<Array<Scalars['ID']>>,
  internTypeIds?: Maybe<Array<Scalars['ID']>>,
  isAd?: Maybe<Scalars['Boolean']>,
  isDeleted?: Maybe<Scalars['Boolean']>,
  isHidden?: Maybe<Scalars['Boolean']>,
  jobTypes?: Maybe<Array<Maybe<JobType>>>,
  onofflineId?: Maybe<Scalars['ID']>,
  orgTypeIDs?: Maybe<Array<Scalars['ID']>>,
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeIDs?: Maybe<Array<RecruitOrganizationType>>,
  organizationTypes?: Maybe<Array<Scalars['ID']>>,
  period?: Maybe<ActivityPeriod>,
  q?: Maybe<Scalars['String']>,
  regionIDs?: Maybe<Array<Scalars['ID']>>,
  reward?: Maybe<ActivityReward>,
  status?: Maybe<ActivityStatus>,
  targetIDs?: Maybe<Array<Scalars['ID']>>,
  withClosed?: Maybe<Scalars['Boolean']>,
};

export type ActivityFilterCollection = {
   __typename?: 'ActivityFilterCollection',
  benefits: Array<Benefit>,
  categories: Array<Category>,
  interests: Array<Interest>,
  organizationTypes: Array<OrganizationType>,
  periods: Array<Period>,
  rewards: Array<Reward>,
};

export type ActivityFilterItem = ActivityFilterItemById | ActivityFitlerItemByRange;

export type ActivityFilterItemBase = {
  name: Scalars['String'],
};

export type ActivityFilterItemById = ActivityFilterItemBase & {
   __typename?: 'ActivityFilterItemByID',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ActivityFilterListInput = {
  activityTypeID: Scalars['Int'],
};

export type ActivityFilterListItem = {
   __typename?: 'ActivityFilterListItem',
  duplicatable?: Maybe<Scalars['Boolean']>,
  items?: Maybe<Array<Maybe<ActivityFilterItem>>>,
  type: Scalars['String'],
};

export enum ActivityFilterType {
  BENEFIT = 'BENEFIT',
  CATEGORY = 'CATEGORY',
  INTEREST = 'INTEREST',
  ORGANIZATION = 'ORGANIZATION',
  PERIOD = 'PERIOD',
  PRIZE = 'PRIZE',
  REGION = 'REGION',
  TARGET = 'TARGET'
}

export type ActivityFitlerItemByRange = ActivityFilterItemBase & {
   __typename?: 'ActivityFitlerItemByRange',
  from?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  to?: Maybe<Scalars['Int']>,
};

export type ActivityForRelatedContentFilter = {
  activityIDs?: Maybe<Array<Scalars['ID']>>,
  activityTypeID?: Maybe<Scalars['ID']>,
  createdAtTerm?: Maybe<CreatedAtTerm>,
  organizationName?: Maybe<Scalars['String']>,
  status?: Maybe<ActivityStatus>,
  title?: Maybe<Scalars['String']>,
};

export type ActivityInteger = {
   __typename?: 'ActivityInteger',
  activity: Activity,
  id: Scalars['ID'],
  integer: Scalars['String'],
  type: ActivityIntegerType,
};

export type ActivityIntegerType = {
   __typename?: 'ActivityIntegerType',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  name: Scalars['String'],
  unit: Scalars['String'],
};

export type ActivityInternType = {
   __typename?: 'ActivityInternType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ActivityManagerPreset = {
   __typename?: 'ActivityManagerPreset',
  department: Scalars['String'],
  email: Scalars['String'],
  id: Scalars['ID'],
  name: Scalars['String'],
  phoneNumber: Scalars['String'],
};

export type ActivityManagerPresetOffsetConnection = {
   __typename?: 'ActivityManagerPresetOffsetConnection',
  nodes: Array<ActivityManagerPreset>,
  totalCount: Scalars['Int'],
};

export type ActivityOrder = {
  direction: OrderDirection,
  field: ActivityOrderField,
};

export enum ActivityOrderField {
  CREATED_AT = 'CREATED_AT',
  DEAD_LINE = 'DEAD_LINE',
  RECENT = 'RECENT',
  RECRUIT_CLOSE_AT = 'RECRUIT_CLOSE_AT',
  REPLY_COUNT = 'REPLY_COUNT',
  SCRAP_COUNT = 'SCRAP_COUNT',
  STATUS_UPDATED_AT = 'STATUS_UPDATED_AT',
  VIEW_COUNT = 'VIEW_COUNT'
}

export type ActivityOrganizationLogoInput = {
  attachmentId?: Maybe<Scalars['ID']>,
  logoFile?: Maybe<Scalars['Upload']>,
};

export type ActivityOrganizationPreset = {
   __typename?: 'ActivityOrganizationPreset',
  homepageURL?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  industries: Array<Industry>,
  logo: Attachment,
  name: Scalars['String'],
  organizationType: OrganizationType,
  welfareBenefits: Array<WelfareBenefit>,
};

export type ActivityOrganizationPresetOffsetConnection = {
   __typename?: 'ActivityOrganizationPresetOffsetConnection',
  nodes: Array<ActivityOrganizationPreset>,
  totalCount: Scalars['Int'],
};

export type ActivityOutLink = {
   __typename?: 'ActivityOutLink',
  activity: Activity,
  clickedCount: Scalars['Int'],
  id: Scalars['ID'],
  link: Scalars['String'],
  type: ActivityOutLinkType,
};

export enum ActivityOutLinkType {
  APPLY_FILE = 'APPLY_FILE',
  APPLY_SITE = 'APPLY_SITE',
  BLOG = 'BLOG',
  CAFE = 'CAFE',
  FACEBOOK = 'FACEBOOK',
  SITE = 'SITE',
  YOUTUBE_CHANNEL = 'YOUTUBE_CHANNEL'
}

export type ActivityPeriod = {
  from?: Maybe<Scalars['Int']>,
  to?: Maybe<Scalars['Int']>,
};

export enum ActivityQualificationEducation {
  COLLEGE_GRADUATE_2_3_YEARS = 'COLLEGE_GRADUATE_2_3_YEARS',
  COLLEGE_GRADUATE_4_YEARS = 'COLLEGE_GRADUATE_4_YEARS',
  DOCTOR_GRADUATE = 'DOCTOR_GRADUATE',
  HIGH_SCHOOL_GRADUATE = 'HIGH_SCHOOL_GRADUATE',
  MASTER_GRADUATE = 'MASTER_GRADUATE',
  NO_LIMIT = 'NO_LIMIT'
}

export type ActivityQualificationInput = {
  education: ActivityQualificationEducation,
  isExpectedGraduateAllowed: Scalars['Boolean'],
};

export type ActivityRange = {
  recruitCloseAt?: Maybe<DateRange>,
  recruitStartAt?: Maybe<DateRange>,
};

export enum ActivityRecruitType {
  ALWAYS = 'ALWAYS',
  ASAP = 'ASAP',
  NORMAL = 'NORMAL',
  SCHEDULED = 'SCHEDULED'
}

export type ActivityReference = {
   __typename?: 'ActivityReference',
  activity: Activity,
  activityType?: Maybe<ActivityType>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  organization?: Maybe<Organization>,
  title: Scalars['String'],
};

export type ActivityReferenceUpdateInput = {
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
};

export type ActivityReferenceUpdatePayload = {
   __typename?: 'ActivityReferenceUpdatePayload',
  activityReference?: Maybe<ActivityReference>,
};

export type ActivityRefusedNotification = SimpleNotification & {
   __typename?: 'ActivityRefusedNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ActivityReward = {
  from?: Maybe<Scalars['Int']>,
  to?: Maybe<Scalars['Int']>,
};

export type ActivityScrapUpdateInput = {
  activityID: Scalars['ID'],
  referer: Scalars['String'],
};

export type ActivityScrapUpdatePayload = {
   __typename?: 'ActivityScrapUpdatePayload',
  activity?: Maybe<Activity>,
  scrapped?: Maybe<Scalars['Boolean']>,
  userScrap?: Maybe<UserScrap>,
};

export type ActivityScrapUserAgeRangeStatistics = {
   __typename?: 'ActivityScrapUserAgeRangeStatistics',
  ageRangeStatistics: Array<AgeRangeCount>,
  statisticsTotalCount: Scalars['Int'],
};

export type ActivityScrapUserJobcategoryStatistics = {
   __typename?: 'ActivityScrapUserJobcategoryStatistics',
  jobCategoryStatistics: Array<JobcategoryCount>,
  statisticsTotalCount: Scalars['Int'],
};

export type ActivityScrapUserListConnection = {
   __typename?: 'ActivityScrapUserListConnection',
  nodes: Array<User>,
  totalCount: Scalars['Int'],
};

export type ActivityScrapUserListInput = {
  activityID: Scalars['ID'],
};

export type ActivityScrapUserMajorStatistics = {
   __typename?: 'ActivityScrapUserMajorStatistics',
  majorStatistics: Array<MajorCount>,
  statisticsTotalCount: Scalars['Int'],
};

export type ActivityScrapUserSchoolStatistics = {
   __typename?: 'ActivityScrapUserSchoolStatistics',
  schoolStatistics: Array<SchoolCount>,
  statisticsTotalCount: Scalars['Int'],
};

export type ActivitySearchConnection = {
   __typename?: 'ActivitySearchConnection',
  nodes: Array<ActivitySearchResult>,
  totalCount: Scalars['Int'],
};

export type ActivitySearchFilter = {
  isClosed?: Maybe<Scalars['Boolean']>,
  query?: Maybe<Scalars['String']>,
};

export type ActivitySearchOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<ActivitySearchOrderType>,
};

export enum ActivitySearchOrderType {
  CREATED_AT = 'CREATED_AT',
  RECRUIT_CLOSEAT = 'RECRUIT_CLOSEAT',
  RELEVANCE = 'RELEVANCE',
  SCRAP_COUNT = 'SCRAP_COUNT',
  VIEW_COUNT = 'VIEW_COUNT'
}

export type ActivitySearchResult = {
   __typename?: 'ActivitySearchResult',
  score?: Maybe<Scalars['Float']>,
  source?: Maybe<Activity>,
};

export type ActivitySelfIntroductionStatistic = {
   __typename?: 'ActivitySelfIntroductionStatistic',
  activity?: Maybe<Activity>,
  createdAt: Scalars['Date'],
  date: Scalars['String'],
  id: Scalars['ID'],
  selfIntroductionCount: Scalars['Int'],
  selfIntroductionUserCount: Scalars['Int'],
};

export type ActivitySelfIntroductionStatisticConnection = {
   __typename?: 'ActivitySelfIntroductionStatisticConnection',
  nodes: Array<ActivitySelfIntroductionStatistic>,
  totalCount: Scalars['Int'],
};

export type ActivitySelfIntroductionStatisticFilters = {
  dateRange?: Maybe<DateRange>,
};

export type ActivitySelfIntroductionStatisticOrder = {
  direction: OrderDirection,
  field: ActivitySelfIntroductionStatisticOrderField,
};

export enum ActivitySelfIntroductionStatisticOrderField {
  SELF_INTRODUCTION_COUNT = 'SELF_INTRODUCTION_COUNT',
  SELF_INTRODUCTION_USER_COUNT = 'SELF_INTRODUCTION_USER_COUNT'
}

export enum ActivityStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  REJECTED_ETC = 'REJECTED_ETC',
  TEMP = 'TEMP'
}

export type ActivityTarget = {
   __typename?: 'ActivityTarget',
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
};

export type ActivityText = {
   __typename?: 'ActivityText',
  activity: Activity,
  id: Scalars['ID'],
  text: Scalars['String'],
  type: ActivityTextType,
};

export type ActivityTextType = {
   __typename?: 'ActivityTextType',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ActivityType = {
   __typename?: 'ActivityType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ActivityTypeDto = {
   __typename?: 'ActivityTypeDto',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ActivityUpdateInput = {
  id: Scalars['ID'],
  refusalReason?: Maybe<Scalars['String']>,
  status?: Maybe<ActivityStatus>,
  title?: Maybe<Scalars['String']>,
};

export type ActivityUpdatePayload = {
   __typename?: 'ActivityUpdatePayload',
  activity?: Maybe<Activity>,
};

export type ActivityViewCountUpdatePayload = {
   __typename?: 'ActivityViewCountUpdatePayload',
  activity?: Maybe<Activity>,
};

export type ActivityWithAd = {
   __typename?: 'ActivityWithAd',
  activity: Activity,
  ad?: Maybe<Ad>,
};

export type ActivityWithJobCurationActivityFilters = {
  type: JobCurationActivityType,
};

export enum ActivityWorkingConditionSalarySalaryType {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY'
}

export enum ActivityWorkingConditionWorkHourType {
  FIVE_DAYS_A_WEEK = 'FIVE_DAYS_A_WEEK',
  FLEXIBLE = 'FLEXIBLE',
  OTHERS = 'OTHERS',
  SIX_DAYS_A_WEEK = 'SIX_DAYS_A_WEEK',
  THREE_DAYS_A_WEEK = 'THREE_DAYS_A_WEEK'
}

export type Actor = Channel | User;

export type ActorConnection = {
   __typename?: 'ActorConnection',
  edges: Array<ActorEdge>,
  nodes: Array<Maybe<Actor>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ActorEdge = {
   __typename?: 'ActorEdge',
  cursor: Scalars['String'],
  node: Actor,
};

export type Ad = {
   __typename?: 'Ad',
  activity?: Maybe<Activity>,
  adPlacement?: Maybe<AdPlacement>,
  advertiser?: Maybe<Scalars['String']>,
  campaign?: Maybe<Campaign>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  image?: Maybe<Attachment>,
  order: Scalars['Int'],
  relatedId?: Maybe<Scalars['ID']>,
  relationType?: Maybe<AdRelationType>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type AdConnection = {
   __typename?: 'AdConnection',
  nodes: Array<Ad>,
  totalCount: Scalars['Int'],
};

export type AdCreateInput = {
  adPlacementID: Scalars['ID'],
  campaignID: Scalars['ID'],
  imageID: Scalars['ID'],
};

export type AddBookmarkPayload = {
   __typename?: 'AddBookmarkPayload',
  newsItem?: Maybe<NewsItem>,
};

export type AddCommunityBookmarkPayLoad = {
   __typename?: 'addCommunityBookmarkPayLoad',
  bookmarkable?: Maybe<CommunityBookmarkable>,
};

export type AddCommunityMenuBookmarkPayLoad = {
   __typename?: 'addCommunityMenuBookmarkPayLoad',
  bookmarkable?: Maybe<CommunityBookmarkable>,
};

export type AddLikePayload = {
   __typename?: 'AddLikePayload',
  newsItem?: Maybe<NewsItem>,
};

export type AddressId = {
  districtID?: Maybe<Scalars['ID']>,
  regionID?: Maybe<Scalars['ID']>,
};

export type AddTypeByOrgNameRegexInput = {
  orgNameRegex: Scalars['String'],
  type: CoverLetterType,
};

export type AddTypeByOrgNameRegexPayload = {
   __typename?: 'AddTypeByOrgNameRegexPayload',
  updatedCoverLetters?: Maybe<Array<Maybe<CoverLetter>>>,
};

export type AdminCampaignByCampaignGroupIdFilters = {
  campaignGroupId: Scalars['ID'],
  dateRange?: Maybe<DateRange>,
  status?: Maybe<AdStatus>,
  unifiedAdPlacementIds?: Maybe<Array<Scalars['ID']>>,
};

export type AdminCampaignByUnifiedAdPlacementIdFilters = {
  campaignGroupName?: Maybe<Scalars['String']>,
  dateRange?: Maybe<DateRange>,
  status: AdStatus,
  unifiedAdPlacementId: Scalars['ID'],
};

export type AdminCampaignConnection = {
   __typename?: 'AdminCampaignConnection',
  nodes: Array<Campaign>,
  totalCount: Scalars['Int'],
};

export enum AdminCampaignOrderField {
  END_AT = 'END_AT',
  ORDER = 'ORDER',
  START_AT = 'START_AT'
}

export type AdminCampaignOrders = {
  direction: OrderDirection,
  field: AdminCampaignOrderField,
};

export type AdPlacement = {
   __typename?: 'AdPlacement',
  ads: Array<Ad>,
  code: Scalars['String'],
  createdAt: Scalars['Date'],
  defaultImage?: Maybe<Attachment>,
  defaultLandingURL?: Maybe<Scalars['String']>,
  height: Scalars['Int'],
  id: Scalars['ID'],
  isActive?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
  width: Scalars['Int'],
};

export type AdPlacementClickLog = {
   __typename?: 'AdPlacementClickLog',
  adPlacement?: Maybe<AdPlacement>,
  adPlacementImressionLog?: Maybe<AdPlacementImpressionLog>,
  campaign?: Maybe<Campaign>,
  createdAt?: Maybe<Scalars['Date']>,
  createdDate?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  user?: Maybe<User>,
};

export type AdPlacementConnection = {
   __typename?: 'AdPlacementConnection',
  nodes: Array<AdPlacement>,
  totalCount: Scalars['Int'],
};

export type AdPlacementCreateInput = {
  code: Scalars['String'],
  defaultImageID?: Maybe<Scalars['ID']>,
  defaultLandingURL?: Maybe<Scalars['String']>,
  height: Scalars['Int'],
  isActive: Scalars['Boolean'],
  name: Scalars['String'],
  width: Scalars['Int'],
};

export type AdPlacementCreatePayload = {
   __typename?: 'AdPlacementCreatePayload',
  adPlacement?: Maybe<AdPlacement>,
};

export type AdPlacementFilters = {
  isActive?: Maybe<Scalars['Boolean']>,
  q?: Maybe<Scalars['String']>,
};

export type AdPlacementImpressionLog = {
   __typename?: 'AdPlacementImpressionLog',
  adPlacement?: Maybe<AdPlacement>,
  campaign?: Maybe<Campaign>,
  createdAt?: Maybe<Scalars['Date']>,
  createdDate?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  user?: Maybe<User>,
};

export type AdPlacementLandingUrl = {
   __typename?: 'AdPlacementLandingURL',
  landingURL?: Maybe<Scalars['String']>,
};

export type AdPlacementOrder = {
  direction: OrderDirection,
  field: AdPlacementOrderField,
};

export enum AdPlacementOrderField {
  CREATED_AT = 'CREATED_AT',
  HEIGHT = 'HEIGHT',
  ID = 'ID',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT',
  WIDTH = 'WIDTH'
}

export type AdPlacementsByCampaignIdPayload = {
   __typename?: 'AdPlacementsByCampaignIDPayload',
  adPlacements: Array<AdPlacement>,
};

export type AdPlacementUpdateInput = {
  code?: Maybe<Scalars['String']>,
  defaultImageID?: Maybe<Scalars['ID']>,
  defaultLandingURL?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
};

export type AdPlacementUpdatePayload = {
   __typename?: 'AdPlacementUpdatePayload',
  adPlacement?: Maybe<AdPlacement>,
};

export enum AdPlaceType {
  ALL = 'ALL',
  MOBILE = 'MOBILE',
  PC = 'PC'
}

export enum AdRelationType {
  ACTIVITY = 'ACTIVITY'
}

export enum AdStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  RESERVED = 'RESERVED'
}

export enum AdType {
  HOTLINE_BANNER = 'HOTLINE_BANNER',
  LIVE_ACTIVITY_INFO = 'LIVE_ACTIVITY_INFO',
  LIVE_POPULAR_RECRUIT = 'LIVE_POPULAR_RECRUIT',
  POPULAR_ACTIVITY = 'POPULAR_ACTIVITY',
  RECOMMEND_A_BANNER = 'RECOMMEND_A_BANNER',
  RECOMMEND_S_BANNER = 'RECOMMEND_S_BANNER'
}

export type AdUpsertInput = {
  adPlacementID?: Maybe<Scalars['ID']>,
  campaignID?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>,
  imageID?: Maybe<Scalars['ID']>,
};

export type Advertisement = {
   __typename?: 'Advertisement',
  activity?: Maybe<Activity>,
  activityID?: Maybe<Scalars['ID']>,
  adType?: Maybe<AdType>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  endAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  mobileImage?: Maybe<Scalars['String']>,
  outLink?: Maybe<Scalars['String']>,
  pcImage?: Maybe<Scalars['String']>,
  seq?: Maybe<Scalars['Int']>,
  startAt?: Maybe<Scalars['Date']>,
  status?: Maybe<AdStatus>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type AdvertisementBulkDeletePayload = {
   __typename?: 'AdvertisementBulkDeletePayload',
  advertisements?: Maybe<Array<Advertisement>>,
};

export type AdvertisementCreateInput = {
  activityID?: Maybe<Scalars['ID']>,
  adType: AdType,
  endAt: Scalars['Date'],
  mobileImage?: Maybe<Scalars['String']>,
  outLink?: Maybe<Scalars['String']>,
  pcImage?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  startAt: Scalars['Date'],
  title?: Maybe<Scalars['String']>,
};

export type AdvertisementCreatePayload = {
   __typename?: 'AdvertisementCreatePayload',
  advertisement?: Maybe<Advertisement>,
};

export type AdvertisementDeletePayload = {
   __typename?: 'AdvertisementDeletePayload',
  advertisement?: Maybe<Advertisement>,
};

export type AdvertisementFilter = {
  adType: AdType,
  overlappingTerm?: Maybe<AdvertisementTerm>,
  seq?: Maybe<Scalars['Int']>,
  status?: Maybe<AdStatus>,
};

export type AdvertisementListPayload = {
   __typename?: 'AdvertisementListPayload',
  activities?: Maybe<Array<Activity>>,
  advertisements?: Maybe<Array<Advertisement>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type AdvertisementOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<AdvertisementOrderField>,
};

export enum AdvertisementOrderField {
  ID = 'ID',
  SEQ = 'SEQ'
}

export type AdvertisementTerm = {
  endAt: Scalars['Date'],
  startAt: Scalars['Date'],
};

export type AdvertisementUpdateInput = {
  activityID?: Maybe<Scalars['ID']>,
  adType: AdType,
  endAt: Scalars['Date'],
  id: Scalars['ID'],
  mobileImage?: Maybe<Scalars['String']>,
  outLink?: Maybe<Scalars['String']>,
  pcImage?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  startAt: Scalars['Date'],
  title?: Maybe<Scalars['String']>,
};

export type AdvertisementUpdatePayload = {
   __typename?: 'AdvertisementUpdatePayload',
  advertisement?: Maybe<Advertisement>,
};

export type AgeRangeCount = {
   __typename?: 'AgeRangeCount',
  ageRange: Scalars['String'],
  userCount: Scalars['Int'],
};

export type AggregateResponse = {
   __typename?: 'AggregateResponse',
  message: Scalars['String'],
};

export type ApplicationProcedureInput = {
  description?: Maybe<Scalars['String']>,
  procedureType: ActivityApplicationProcedureProcedureType,
};

export type ApplyFile = {
   __typename?: 'ApplyFile',
  filename?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  url?: Maybe<Scalars['String']>,
};

export type ApplyType = {
   __typename?: 'ApplyType',
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
};

export type Attachment = {
   __typename?: 'Attachment',
  id: Scalars['ID'],
  name: Scalars['String'],
  path?: Maybe<Scalars['String']>,
  size: Scalars['Int'],
  type: Scalars['String'],
  url: Scalars['String'],
};

export type AttachmentCompleteUploadPayload = {
   __typename?: 'AttachmentCompleteUploadPayload',
  attachment?: Maybe<Attachment>,
};

export type AttachmentConnection = {
   __typename?: 'AttachmentConnection',
  nodes: Array<Maybe<Attachment>>,
  totalCount: Scalars['Int'],
};

export type AttachmentDeletePayload = {
   __typename?: 'AttachmentDeletePayload',
  attachment?: Maybe<Attachment>,
};

export type AttachmentPrepareUploadPayload = {
   __typename?: 'AttachmentPrepareUploadPayload',
  attachment: Attachment,
  signedURL: Scalars['String'],
};

export type AuthenticateEmailCallbackInput = {
  authenticationToken: Scalars['String'],
  emailCode: Scalars['String'],
};

export type AuthenticateEmailCallbackPayload = {
   __typename?: 'AuthenticateEmailCallbackPayload',
  confirmToken: Scalars['String'],
  email: Scalars['String'],
};

export type AuthenticateEmailInput = {
  duplicateCheck?: Maybe<Scalars['Boolean']>,
  email: Scalars['String'],
  ttl?: Maybe<Scalars['Int']>,
};

export type AuthenticateEmailPayload = {
   __typename?: 'AuthenticateEmailPayload',
  authenticationToken: Scalars['String'],
};

export enum BannedUserSearchField {
  CHAT_NICKNAME = 'CHAT_NICKNAME',
  USER_ID = 'USER_ID'
}

export type BannedUserSearchInput = {
  searchField: BannedUserSearchField,
  searchKeyword: Scalars['String'],
};

export type BanUserInChatInput = {
  reason: Scalars['String'],
  until: Scalars['Date'],
};

export type BanUserInChatPayload = {
   __typename?: 'BanUserInChatPayload',
  userBan: UserBanInChat,
};

export type Benefit = {
   __typename?: 'Benefit',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
  users?: Maybe<Array<Maybe<User>>>,
};

export enum BestNewsItemType {
  COMMENT = 'COMMENT',
  LIKE = 'LIKE',
  RECENT = 'RECENT',
  SCRAP = 'SCRAP'
}

export type BizActivityCountByStatusPayload = {
   __typename?: 'BizActivityCountByStatusPayload',
  count?: Maybe<Scalars['Int']>,
  status?: Maybe<ActivityDeadlineStatus>,
};

export type BizActivityFilter = {
  activityTypeID?: Maybe<Scalars['ID']>,
  deadLineStatus?: Maybe<ActivityDeadlineStatus>,
  status?: Maybe<ActivityStatus>,
  title?: Maybe<Scalars['String']>,
};

export type BizActivityOrder = {
  direction: OrderDirection,
  field: BizActivityOrderField,
};

export enum BizActivityOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type BizCreateActivityInput = {
  activityTypeID: Scalars['ID'],
  title: Scalars['String'],
};

export type BizCreateActivityPayload = {
   __typename?: 'BizCreateActivityPayload',
  activity?: Maybe<Activity>,
};

export type BizFindLoginInput = {
  managerEmail: Scalars['String'],
  managerName: Scalars['String'],
};

export type BizFindLoginPayload = {
   __typename?: 'BizFindLoginPayload',
  createdAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  login: Scalars['String'],
};

export type BizResetPasswordInput = {
  emailConfirmToken: Scalars['String'],
  login: Scalars['String'],
  managerEmail: Scalars['String'],
  password: Scalars['String'],
};

export type BizResetPasswordPayload = {
   __typename?: 'BizResetPasswordPayload',
  user: User,
};

export type BizSignupInput = {
  email: Scalars['String'],
  emailConfirmToken: Scalars['String'],
  licenseImages?: Maybe<Array<Scalars['Upload']>>,
  login: Scalars['String'],
  managerName: Scalars['String'],
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeSMS?: Maybe<Scalars['Boolean']>,
};

export type BizUpdateActivityInput = {
  activityEndAt?: Maybe<Scalars['Date']>,
  activityID: Scalars['ID'],
  activityStartAt?: Maybe<Scalars['Date']>,
  activityTypeID: Scalars['ID'],
  additionalBenefit?: Maybe<Scalars['String']>,
  applicationFileAttachmentIDs?: Maybe<Array<Scalars['ID']>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypeIDs?: Maybe<Array<Scalars['String']>>,
  awards?: Maybe<Scalars['String']>,
  benefits?: Maybe<Array<Scalars['ID']>>,
  categories?: Maybe<Array<Scalars['ID']>>,
  country?: Maybe<Scalars['String']>,
  dateRepresentation?: Maybe<DateRepresentationType>,
  detailText?: Maybe<Scalars['String']>,
  detailTextID?: Maybe<Scalars['ID']>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  facetimeDay?: Maybe<Scalars['String']>,
  facetimePeriod?: Maybe<Scalars['String']>,
  facetimeTime?: Maybe<Scalars['String']>,
  facetimeWeekday?: Maybe<Scalars['String']>,
  interests?: Maybe<Array<Scalars['ID']>>,
  isManagerEmailVisible?: Maybe<Scalars['Boolean']>,
  isManagerPhoneNumberVisible?: Maybe<Scalars['Boolean']>,
  isSubmit?: Maybe<Scalars['Boolean']>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName: Scalars['String'],
  organizationTypeID?: Maybe<Scalars['ID']>,
  outlink?: Maybe<Scalars['String']>,
  percent?: Maybe<Scalars['Int']>,
  posterAttachmentID?: Maybe<Scalars['ID']>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType?: Maybe<ActivityRecruitType>,
  regionDistricts?: Maybe<Array<Scalars['ID']>>,
  regions?: Maybe<Array<Scalars['ID']>>,
  skills?: Maybe<Array<Scalars['ID']>>,
  targets?: Maybe<Array<Scalars['ID']>>,
  thumbnailAttachmentID?: Maybe<Scalars['ID']>,
  title: Scalars['String'],
  youtubeURL?: Maybe<Scalars['String']>,
};

export type BizUpdateActivityPayload = {
   __typename?: 'BizUpdateActivityPayload',
  activity?: Maybe<Activity>,
};

export type BizUpdateRecruitCloseAtInput = {
  activityID: Scalars['ID'],
  recruitCloseAt: Scalars['Date'],
};

export type BizUpdateRecruitCloseAtPayload = {
   __typename?: 'BizUpdateRecruitCloseAtPayload',
  activity?: Maybe<Activity>,
};

export type BizUpdateRecruitInput = {
  activityID: Scalars['ID'],
  applicationFileAttachmentIDs?: Maybe<Array<Scalars['String']>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypeID?: Maybe<Scalars['ID']>,
  categories?: Maybe<Array<Scalars['ID']>>,
  detailText?: Maybe<Scalars['String']>,
  detailTextID?: Maybe<Scalars['ID']>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  isManagerEmailVisible?: Maybe<Scalars['Boolean']>,
  isManagerPhoneNumberVisible?: Maybe<Scalars['Boolean']>,
  isSubmit?: Maybe<Scalars['Boolean']>,
  jobTypes?: Maybe<Array<JobType>>,
  logo?: Maybe<ActivityFileAttachmentInput>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  outlink?: Maybe<Scalars['String']>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType?: Maybe<ActivityRecruitType>,
  regionDistricts?: Maybe<Array<Scalars['ID']>>,
  regions?: Maybe<Array<Scalars['ID']>>,
  title: Scalars['String'],
};

export type BizUpdateRecruitPayload = {
   __typename?: 'BizUpdateRecruitPayload',
  activity?: Maybe<Activity>,
};

export type BizUpdateUserInput = {
  alias?: Maybe<Scalars['String']>,
  deleteLicenseImageIds?: Maybe<Array<Scalars['ID']>>,
  email?: Maybe<Scalars['String']>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  licenseImages?: Maybe<Array<Scalars['Upload']>>,
  managerName?: Maybe<Scalars['String']>,
  newPassword?: Maybe<Scalars['String']>,
  oldPassword?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  phoneNumber?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeSMS?: Maybe<Scalars['Boolean']>,
};

export type BizUpdateUserPayload = {
   __typename?: 'BizUpdateUserPayload',
  user?: Maybe<User>,
};

export type BizVerifyLoginInput = {
  login: Scalars['String'],
  managerEmail: Scalars['String'],
};

export type BizVerifyLoginPayload = {
   __typename?: 'BizVerifyLoginPayload',
  user: User,
};

export enum BlankType {
  WITHOUT_BLANK = 'WITHOUT_BLANK',
  WITH_BLANK = 'WITH_BLANK'
}

export type BlockUserInChatPayload = {
   __typename?: 'BlockUserInChatPayload',
  user: User,
};

export type Board = {
   __typename?: 'Board',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
};

export type BookmarkedConnection = {
   __typename?: 'BookmarkedConnection',
  edges: Array<BookmarkedEdge>,
  nodes: Array<Maybe<NewsItem>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type BookmarkedEdge = {
   __typename?: 'BookmarkedEdge',
  bookmarkedAt?: Maybe<Scalars['Date']>,
  cursor: Scalars['String'],
  node: NewsItem,
};

export type BookmarkerConnection = {
   __typename?: 'BookmarkerConnection',
  edges: Array<BookmarkerEdge>,
  nodes: Array<Maybe<Actor>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type BookmarkerEdge = {
   __typename?: 'BookmarkerEdge',
  bookmarkedAt?: Maybe<Scalars['Date']>,
  cursor: Scalars['String'],
  node?: Maybe<Actor>,
};

export type BookmarkToNewsItemNotification = SimpleNotification & {
   __typename?: 'BookmarkToNewsItemNotification',
  bookmaker?: Maybe<Actor>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  newsItem?: Maybe<NewsItem>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export type Campaign = {
   __typename?: 'Campaign',
  ads: Array<Ad>,
  campaignGroup: CampaignGroupDto,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  endAt: Scalars['Date'],
  id: Scalars['ID'],
  isActive: Scalars['Boolean'],
  isLandingDisabled: Scalars['Boolean'],
  landingURL: Scalars['String'],
  name: Scalars['String'],
  order?: Maybe<Scalars['Int']>,
  schedules: Array<CampaignSchedule>,
  startAt: Scalars['Date'],
  statistic: CampaignStatistics,
  status: AdStatus,
  unifiedAdPlacement: UnifiedAdPlacementDto,
  updatedAt?: Maybe<Scalars['Date']>,
  user?: Maybe<User>,
};

export type CampaignAdCreateInput = {
  adPlacementID: Scalars['ID'],
  imageID: Scalars['ID'],
};

export type CampaignConnection = {
   __typename?: 'CampaignConnection',
  nodes: Array<Campaign>,
  totalCount: Scalars['Int'],
};

export type CampaignCreateInput = {
  ads: Array<CampaignAdCreateInput>,
  content?: Maybe<Scalars['String']>,
  endAt: Scalars['Date'],
  isActive: Scalars['Boolean'],
  isLandingDisabled?: Maybe<Scalars['Boolean']>,
  landingURL: Scalars['String'],
  name: Scalars['String'],
  schedules: Array<CampaignScheduleInput>,
  startAt: Scalars['Date'],
};

export type CampaignCreatePayload = {
   __typename?: 'CampaignCreatePayload',
  campaign?: Maybe<Campaign>,
};

export type CampaignDeletePayload = {
   __typename?: 'CampaignDeletePayload',
  campaign?: Maybe<Campaign>,
};

export type CampaignFilters = {
  endAt?: Maybe<Scalars['Date']>,
  isActive?: Maybe<Scalars['Boolean']>,
  q?: Maybe<Scalars['String']>,
  startAt?: Maybe<Scalars['Date']>,
  status?: Maybe<AdStatus>,
};

export type CampaignGroupConnection = {
   __typename?: 'CampaignGroupConnection',
  nodes: Array<CampaignGroupDto>,
  totalCount: Scalars['Int'],
};

export type CampaignGroupDto = {
   __typename?: 'CampaignGroupDto',
  campaigns: Array<Campaign>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type CampaignGroupFilters = {
  dateRange?: Maybe<DateRange>,
  name?: Maybe<Scalars['String']>,
};

export enum CampaignGroupOrderField {
  ID = 'ID'
}

export type CampaignGroupOrders = {
  direction: OrderDirection,
  field: CampaignGroupOrderField,
};

export type CampaignOrder = {
  direction: OrderDirection,
  field: CampaignOrderField,
};

export enum CampaignOrderField {
  CREATE_AT = 'CREATE_AT',
  END_AT = 'END_AT',
  ID = 'ID',
  NAME = 'NAME',
  START_AT = 'START_AT',
  UPDATE_AT = 'UPDATE_AT'
}

export type CampaignSchedule = {
   __typename?: 'CampaignSchedule',
  createdAt?: Maybe<Scalars['Date']>,
  dayOfWeek?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  startTime?: Maybe<Scalars['String']>,
};

export type CampaignScheduleInput = {
  campaignID?: Maybe<Scalars['ID']>,
  dayOfWeek: Scalars['String'],
  endTime: Scalars['String'],
  startTime: Scalars['String'],
};

export type CampaignStatistic = {
   __typename?: 'CampaignStatistic',
  adPlacement?: Maybe<AdPlacement>,
  campaign?: Maybe<Campaign>,
  clickCount: Scalars['Int'],
  createdAt: Scalars['Date'],
  ctr: Scalars['Float'],
  date: Scalars['Date'],
  id: Scalars['ID'],
  impressionCount: Scalars['Int'],
};

export type CampaignStatisticConnection = {
   __typename?: 'CampaignStatisticConnection',
  nodes: Array<CampaignStatistic>,
  totalClickCount: Scalars['Int'],
  totalCount: Scalars['Int'],
  totalImpressionCount: Scalars['Int'],
};

export type CampaignStatisticFilters = {
  adPlacementIDs?: Maybe<Array<Scalars['ID']>>,
  campaignID?: Maybe<Scalars['ID']>,
  dateRange?: Maybe<DateRange>,
};

export type CampaignStatisticGroups = {
  period: Period,
};

export type CampaignStatistics = {
   __typename?: 'CampaignStatistics',
  clickCount: Scalars['Int'],
  ctr: Scalars['Float'],
  impressionCount: Scalars['Int'],
};

export type CampaignStatisticsConnection = {
   __typename?: 'CampaignStatisticsConnection',
  nodes: Array<CampaignStatisticsWithDate>,
  totalCount: Scalars['Int'],
};

export type CampaignStatisticsReport = {
   __typename?: 'CampaignStatisticsReport',
  clickCount: Scalars['Int'],
  ctr: Scalars['Float'],
  endDate: Scalars['Date'],
  impressionCount: Scalars['Int'],
  startDate: Scalars['Date'],
  statisticsWithDate: Array<CampaignStatisticsWithDate>,
  statisticsWithUnifiedAdPlacement: Array<CampaignStatisticsWithAdPlacement>,
};

export type CampaignStatisticsWithAdPlacement = {
   __typename?: 'CampaignStatisticsWithAdPlacement',
  clickCount: Scalars['Int'],
  ctr: Scalars['Float'],
  impressionCount: Scalars['Int'],
  unifiedAdPlacementName: Scalars['String'],
};

export type CampaignStatisticsWithDate = {
   __typename?: 'CampaignStatisticsWithDate',
  clickCount: Scalars['Int'],
  ctr: Scalars['Float'],
  date?: Maybe<Scalars['String']>,
  impressionCount: Scalars['Int'],
};

export type CampaignUpdateInput = {
  content?: Maybe<Scalars['String']>,
  deleteAdIDs: Array<Scalars['ID']>,
  endAt?: Maybe<Scalars['Date']>,
  isActive?: Maybe<Scalars['Boolean']>,
  isLandingDisabled?: Maybe<Scalars['Boolean']>,
  landingURL?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  schedules: Array<CampaignScheduleInput>,
  startAt?: Maybe<Scalars['Date']>,
  upsertAds: Array<AdUpsertInput>,
};

export type CampaignUpdatePayload = {
   __typename?: 'CampaignUpdatePayload',
  campaign?: Maybe<Campaign>,
};

export type CareerAdvantageCondition = {
   __typename?: 'CareerAdvantageCondition',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  employmentSupportTargets: Array<CareerEmploymentSupportTarget>,
  handicapLevel?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  isEmploymentSupportTarget?: Maybe<Scalars['Boolean']>,
  isHandicap?: Maybe<Scalars['Boolean']>,
  isMilitary?: Maybe<Scalars['Boolean']>,
  isPublic: Scalars['Boolean'],
  isVeteran?: Maybe<Scalars['Boolean']>,
  isWritten: Scalars['Boolean'],
  militaryEndYearMonth?: Maybe<Scalars['Int']>,
  militaryRank?: Maybe<MilitaryRank>,
  militaryStartYearMonth?: Maybe<Scalars['Int']>,
  militaryStatus?: Maybe<MilitaryStatus>,
  militaryTarget?: Maybe<MilitaryTarget>,
  militaryType?: Maybe<MilitaryType>,
  updatedAt?: Maybe<Scalars['Date']>,
  veteranReason?: Maybe<Scalars['String']>,
};

export type CareerAward = {
   __typename?: 'CareerAward',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  items: Array<CareerAwardItem>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerAwardItem = {
   __typename?: 'CareerAwardItem',
  acquiredDate?: Maybe<Scalars['Date']>,
  award: CareerAward,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  institutionName?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerEmploymentSupportTarget = {
   __typename?: 'CareerEmploymentSupportTarget',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type CareerExperience = {
   __typename?: 'CareerExperience',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  items: Array<CareerExperienceItem>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerExperienceItem = {
   __typename?: 'CareerExperienceItem',
  createdAt: Scalars['Date'],
  duties: Array<Category>,
  endYearMonth?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  inOffice?: Maybe<Scalars['Boolean']>,
  isExternal: Scalars['Boolean'],
  isOrganizationNamePrivate: Scalars['Boolean'],
  jobPosition?: Maybe<JobPosition>,
  jobRank?: Maybe<JobRank>,
  organization?: Maybe<CareerOrganization>,
  pay?: Maybe<Scalars['Int']>,
  performancePart?: Maybe<Scalars['String']>,
  startYearMonth?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerExternship = {
   __typename?: 'CareerExternship',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  items: Array<CareerExternshipItem>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerExternshipItem = {
   __typename?: 'CareerExternshipItem',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  endYearMonth?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  inProgress: Scalars['Boolean'],
  institutionName?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  startYearMonth?: Maybe<Scalars['Int']>,
  type?: Maybe<CareerExternshipType>,
  updatedAt?: Maybe<Scalars['Date']>,
  url?: Maybe<Scalars['String']>,
};

export enum CareerExternshipType {
  CLUB_ACTIVITY = 'CLUB_ACTIVITY',
  EXTERNAL_ACTIVITY = 'EXTERNAL_ACTIVITY',
  INTERNSHIP = 'INTERNSHIP',
  ON_CAMPUS_ACTIVITY = 'ON_CAMPUS_ACTIVITY',
  OVERSEAS_TRAINING = 'OVERSEAS_TRAINING',
  PART_TIME_JOB = 'PART_TIME_JOB',
  PROJECT = 'PROJECT',
  SOCIAL_ACTIVITY = 'SOCIAL_ACTIVITY',
  VOLUNTEER = 'VOLUNTEER'
}

export type CareerIntroduction = {
   __typename?: 'CareerIntroduction',
  briefContent?: Maybe<Scalars['String']>,
  careerProfile?: Maybe<CareerProfile>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerJobPreference = {
   __typename?: 'CareerJobPreference',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  decidedAfterInterview: Scalars['Boolean'],
  decidedByOrganizationPolicy: Scalars['Boolean'],
  duties: Array<Category>,
  id: Scalars['ID'],
  industries: Array<Industry>,
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  pay?: Maybe<Scalars['Int']>,
  regionDistricts: Array<RegionDistrict>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerLanguage = {
   __typename?: 'CareerLanguage',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  items: Array<CareerLanguageItem>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerLanguageConversation = CareerLanguageItem & {
   __typename?: 'CareerLanguageConversation',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  language: CareerLanguage,
  name?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  status?: Maybe<CareerLanguageConversationStatus>,
  type: CareerLanguageType,
};

export enum CareerLanguageConversationStatus {
  BUSINESS = 'BUSINESS',
  DAILY = 'DAILY',
  NATIVE = 'NATIVE'
}

export type CareerLanguageItem = {
  id: Scalars['ID'],
  language: CareerLanguage,
  seq: Scalars['Int'],
  type: CareerLanguageType,
};

export type CareerLanguageTest = CareerLanguageItem & {
   __typename?: 'CareerLanguageTest',
  acquiredDate?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  language: CareerLanguage,
  level?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['Int']>,
  seq: Scalars['Int'],
  type: CareerLanguageType,
};

export enum CareerLanguageType {
  CONVERSATION = 'CONVERSATION',
  TEST = 'TEST'
}

export type CareerLicense = {
   __typename?: 'CareerLicense',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  items: Array<CareerLicenseItem>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerLicenseItem = {
   __typename?: 'CareerLicenseItem',
  acquiredDate?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  institutionName?: Maybe<Scalars['String']>,
  isExternal: Scalars['Boolean'],
  license: CareerLicense,
  name?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerOrganization = {
   __typename?: 'CareerOrganization',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type CareerPortfolio = {
   __typename?: 'CareerPortfolio',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  files: Array<CareerPortfolioFile>,
  id: Scalars['ID'],
  images: Array<CareerPortfolioImage>,
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
  urls: Array<CareerPortfolioUrl>,
};

export type CareerPortfolioFile = {
   __typename?: 'CareerPortfolioFile',
  createdAt: Scalars['Date'],
  file?: Maybe<Attachment>,
  id: Scalars['ID'],
};

export type CareerPortfolioImage = {
   __typename?: 'CareerPortfolioImage',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  image?: Maybe<Attachment>,
  seq: Scalars['Int'],
};

export type CareerPortfolioUrl = {
   __typename?: 'CareerPortfolioUrl',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  type: CareerPortfolioUrlType,
  url: Scalars['String'],
};

export enum CareerPortfolioUrlType {
  BEHANCE = 'BEHANCE',
  BRUNCH = 'BRUNCH',
  GITHUB = 'GITHUB',
  INSTAGRAM = 'INSTAGRAM',
  LINK = 'LINK',
  LINKEDIN = 'LINKEDIN',
  NOTION = 'NOTION',
  YOU_TUBE = 'YOU_TUBE'
}

export type CareerProfile = {
   __typename?: 'CareerProfile',
  advantageCondition?: Maybe<CareerAdvantageCondition>,
  award?: Maybe<CareerAward>,
  createdAt: Scalars['Date'],
  experience?: Maybe<CareerExperience>,
  externship?: Maybe<CareerExternship>,
  id: Scalars['ID'],
  introduction?: Maybe<CareerIntroduction>,
  isSharing: Scalars['Boolean'],
  jobPreference?: Maybe<CareerJobPreference>,
  language?: Maybe<CareerLanguage>,
  license?: Maybe<CareerLicense>,
  portfolio?: Maybe<CareerPortfolio>,
  profileOrder: CareerProfileOrder,
  school?: Maybe<CareerSchool>,
  skill?: Maybe<CareerSkill>,
  template?: Maybe<CareerProfileTemplate>,
  theme?: Maybe<CareerProfileTheme>,
  updatedAt?: Maybe<Scalars['Date']>,
  userInformation?: Maybe<CareerUserInformation>,
};

export type CareerProfileKpi = {
   __typename?: 'CareerProfileKpi',
  basicCareerProfileCount: Scalars['Int'],
  createdAt: Scalars['Date'],
  date: Scalars['Date'],
  id: Scalars['ID'],
  newCareerProfileCount: Scalars['Int'],
  totalCareerProfileCount: Scalars['Int'],
};

export type CareerProfileKpiConnection = {
   __typename?: 'CareerProfileKpiConnection',
  nodes: Array<CareerProfileKpi>,
  totalCount: Scalars['Int'],
};

export type CareerProfileKpiFilters = {
  dateRange: DateRange,
};

export type CareerProfileKpiGroups = {
  period: Period,
};

export type CareerProfileOrder = {
   __typename?: 'CareerProfileOrder',
  advantageConditionOrder: Scalars['Int'],
  awardOrder: Scalars['Int'],
  careerProfile: CareerProfile,
  experienceOrder: Scalars['Int'],
  externshipOrder: Scalars['Int'],
  introductionOrder: Scalars['Int'],
  jobPreferenceOrder: Scalars['Int'],
  languageOrder: Scalars['Int'],
  licenseOrder: Scalars['Int'],
  portfolioOrder: Scalars['Int'],
  schoolOrder: Scalars['Int'],
  skillOrder: Scalars['Int'],
  userInformationOrder: Scalars['Int'],
};

export enum CareerProfileTemplate {
  ORANGE = 'ORANGE',
  PURPLE = 'PURPLE',
  SKY = 'SKY'
}

export enum CareerProfileTheme {
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  PINK = 'PINK',
  PURPLE = 'PURPLE',
  SKY = 'SKY'
}

export type CareerSchool = {
   __typename?: 'CareerSchool',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  items: Array<CareerSchoolItem>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum CareerSchoolGraduationStatus {
  COMPLETED = 'COMPLETED',
  DROPPED = 'DROPPED',
  ENROLLED = 'ENROLLED',
  EXPECTED_GRADUATION = 'EXPECTED_GRADUATION',
  GRADUATED = 'GRADUATED',
  ON_LEAVE = 'ON_LEAVE',
  SELF_DROPPED = 'SELF_DROPPED'
}

export type CareerSchoolItem = {
   __typename?: 'CareerSchoolItem',
  careerSchool: CareerSchool,
  createdAt: Scalars['Date'],
  entranceYearMonth?: Maybe<Scalars['Int']>,
  gradePerfectScore?: Maybe<Scalars['Float']>,
  gradeScore?: Maybe<Scalars['Float']>,
  graduationStatus?: Maybe<CareerSchoolGraduationStatus>,
  graduationYearMonth?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  isTransfer: Scalars['Boolean'],
  majors: Array<CareerSchoolMajor>,
  school?: Maybe<School>,
  seq: Scalars['Int'],
  type?: Maybe<CareerSchoolType>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerSchoolMajor = {
   __typename?: 'CareerSchoolMajor',
  id: Scalars['ID'],
  name: Scalars['String'],
  type: CareerSchoolMajorType,
};

export enum CareerSchoolMajorType {
  DOUBLE_MAJOR = 'DOUBLE_MAJOR',
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
  PLURAL_MAJOR = 'PLURAL_MAJOR'
}

export enum CareerSchoolType {
  FOUR_YEAR_COLLEGE = 'FOUR_YEAR_COLLEGE',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  MASTER_COLLEGE = 'MASTER_COLLEGE',
  PH_D_COLLEGE = 'PH_D_COLLEGE',
  TWO_THREE_YEAR_COLLEGE = 'TWO_THREE_YEAR_COLLEGE'
}

export type CareerSkill = {
   __typename?: 'CareerSkill',
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isPublic: Scalars['Boolean'],
  isWritten: Scalars['Boolean'],
  skillTags: Array<SkillTag>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerUserInformation = {
   __typename?: 'CareerUserInformation',
  address?: Maybe<Scalars['String']>,
  addressDetail?: Maybe<Scalars['String']>,
  birthday?: Maybe<Scalars['Date']>,
  careerProfile?: Maybe<CareerProfile>,
  createdAt: Scalars['Date'],
  email?: Maybe<Scalars['String']>,
  gender?: Maybe<Gender>,
  id: Scalars['ID'],
  isWritten: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  profileImage?: Maybe<Attachment>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type Category = {
   __typename?: 'Category',
  activityType?: Maybe<ActivityType>,
  children?: Maybe<Array<Category>>,
  id: Scalars['ID'],
  name: Scalars['String'],
  parent?: Maybe<Category>,
  seq?: Maybe<Scalars['Int']>,
  users?: Maybe<Array<Maybe<User>>>,
};

export enum CategoryActivityType {
  ACTIVITY = 'ACTIVITY',
  CLUB = 'CLUB',
  CONTEST = 'CONTEST',
  EDUCATION = 'EDUCATION',
  INTERN = 'INTERN',
  RECRUIT = 'RECRUIT'
}

export type CategoryFilter = {
  activityType?: Maybe<CategoryActivityType>,
  parentId?: Maybe<Scalars['ID']>,
};

export type Certification = {
   __typename?: 'Certification',
  count: Scalars['Int'],
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  name: Scalars['String'],
};

export type ChangeChatNicknameInput = {
  nickname: Scalars['String'],
};

export type ChangeChatNicknamePayload = {
   __typename?: 'ChangeChatNicknamePayload',
  user: User,
};

export type ChangeChatProfileImageInput = {
  profileImageTemplateId: Scalars['ID'],
};

export type ChangeChatProfileImagePayload = {
   __typename?: 'ChangeChatProfileImagePayload',
  user: User,
};

export type Channel = {
   __typename?: 'Channel',
  activities?: Maybe<ActivityConnection>,
  bookmarkCount: Scalars['Int'],
  bookmarks?: Maybe<BookmarkedConnection>,
  briefDescription: Scalars['String'],
  completedAt?: Maybe<Scalars['Date']>,
  coverImage?: Maybe<Attachment>,
  createdAt?: Maybe<Scalars['Date']>,
  dailyStatistic?: Maybe<ChannelStatistic>,
  deactivatedAt?: Maybe<Scalars['Date']>,
  description?: Maybe<Scalars['String']>,
  followerCount: Scalars['Int'],
  followers: FollowerConnection,
  followings: FollowingConnection,
  hasNewNewsItem: Scalars['Boolean'],
  id: Scalars['ID'],
  likeCount: Scalars['Int'],
  monthlyStatistic?: Maybe<ChannelStatistic>,
  name: Scalars['String'],
  newsCommentCount: Scalars['Int'],
  newsComments: NewsCommentConnection,
  newsItemCount: Scalars['Int'],
  newsItemFeed: NewsItemConnection,
  newsItems: NewsItemConnection,
  owner?: Maybe<Organization>,
  shareCount: Scalars['Int'],
  statistics?: Maybe<ChannelStatisticConnection>,
  tags?: Maybe<Array<Maybe<Tag>>>,
  url: Scalars['String'],
  viewCount: Scalars['Int'],
  viewerHasFollowed: Scalars['Boolean'],
  viewerIsOwner: Scalars['Boolean'],
};


export type ChannelActivitiesArgs = {
  filterBy?: Maybe<ChannelActivityFilter>,
  orderBy?: Maybe<ChannelActivityOrder>,
  pagination?: Maybe<Pagination>
};


export type ChannelBookmarksArgs = {
  pagination?: Maybe<Pagination>
};


export type ChannelDailyStatisticArgs = {
  statisticDate?: Maybe<Scalars['Date']>
};


export type ChannelFollowersArgs = {
  filterBy?: Maybe<FollowerFilter>,
  pagination?: Maybe<Pagination>
};


export type ChannelFollowingsArgs = {
  filterBy?: Maybe<FollowingFilter>,
  pagination?: Maybe<Pagination>
};


export type ChannelMonthlyStatisticArgs = {
  statisticDate?: Maybe<Scalars['Date']>
};


export type ChannelNewsCommentsArgs = {
  pagination?: Maybe<Pagination>
};


export type ChannelNewsItemFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type ChannelNewsItemsArgs = {
  filterBy?: Maybe<NewsItemFilter>,
  orderBy?: Maybe<NewsItemOrder>,
  pagination?: Maybe<Pagination>
};


export type ChannelStatisticsArgs = {
  filterBy?: Maybe<ChannelStatisticFilters>,
  groupBy?: Maybe<ChannelStatisticGroups>,
  pagination?: Maybe<Pagination>
};

export enum ChannelActivityDeadlineStatus {
  ALL = 'ALL',
  CLOSE = 'CLOSE',
  EXPECTED = 'EXPECTED',
  OPEN = 'OPEN'
}

export type ChannelActivityFilter = {
  deadlineStatus?: Maybe<ChannelActivityDeadlineStatus>,
};

export type ChannelActivityOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<ChannelActivityOrderField>,
};

export enum ChannelActivityOrderField {
  CREATED_AT = 'CREATED_AT'
}

export type ChannelConnection = {
   __typename?: 'ChannelConnection',
  edges: Array<ChannelEdge>,
  nodes: Array<Channel>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ChannelEdge = {
   __typename?: 'ChannelEdge',
  cursor: Scalars['String'],
  node: Channel,
};

export type ChannelFilter = {
  q?: Maybe<Scalars['String']>,
};

export type ChannelOrder = {
  direction: OrderDirection,
  field: ChannelOrderField,
};

export enum ChannelOrderField {
  BOOKMARK = 'BOOKMARK',
  FOLLOWER = 'FOLLOWER',
  LAST_NEWS_ITEM_CREATED_AT = 'LAST_NEWS_ITEM_CREATED_AT',
  LIKE = 'LIKE',
  NEWS_COMMENT = 'NEWS_COMMENT',
  NEWS_ITEM = 'NEWS_ITEM'
}

export type ChannelStatistic = {
   __typename?: 'ChannelStatistic',
  bookmarkCount: Scalars['Int'],
  bookmarkCountPercentage?: Maybe<Scalars['Float']>,
  channel?: Maybe<Channel>,
  channelViewCount: Scalars['Int'],
  channelViewCountPercentage?: Maybe<Scalars['Float']>,
  date: Scalars['String'],
  followerCount: Scalars['Int'],
  followerCountPercentage?: Maybe<Scalars['Float']>,
  id: Scalars['ID'],
  likeCount: Scalars['Int'],
  likeCountPercentage?: Maybe<Scalars['Float']>,
  newsCommentCount: Scalars['Int'],
  newsCommentCountPercentage?: Maybe<Scalars['Float']>,
  newsItemCount: Scalars['Int'],
  newsItemCountPercentage?: Maybe<Scalars['Float']>,
  newsItemViewCount: Scalars['Int'],
  newsItemViewCountPercentage?: Maybe<Scalars['Float']>,
  shareCount: Scalars['Int'],
};


export type ChannelStatisticBookmarkCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


export type ChannelStatisticChannelViewCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


export type ChannelStatisticFollowerCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


export type ChannelStatisticLikeCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


export type ChannelStatisticNewsCommentCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


export type ChannelStatisticNewsItemCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


export type ChannelStatisticNewsItemViewCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};

export type ChannelStatisticConnection = {
   __typename?: 'ChannelStatisticConnection',
  edges: Array<ChannelStatisticEdge>,
  nodes: Array<ChannelStatistic>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ChannelStatisticEdge = {
   __typename?: 'ChannelStatisticEdge',
  cursor: Scalars['String'],
  node: ChannelStatistic,
};

export type ChannelStatisticFilters = {
  channelName?: Maybe<Scalars['String']>,
  dateRange?: Maybe<DateRange>,
  organizationName?: Maybe<Scalars['String']>,
};

export type ChannelStatisticGroups = {
  term?: Maybe<DateTerm>,
};

export type ChannelStatisticOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<ChannelStatisticOrderField>,
};

export enum ChannelStatisticOrderField {
  BOOKMARK = 'BOOKMARK',
  FOLLOWER = 'FOLLOWER',
  LIKE = 'LIKE',
  NEWS_COMMENT = 'NEWS_COMMENT',
  NEWS_ITEM = 'NEWS_ITEM'
}

export type ChannelsWithStatisticFilter = {
  channelName?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  statisticDate?: Maybe<DateRange>,
};

export type ChannelsWithStatisticOrder = {
  direction: OrderDirection,
  field: ChannelOrderField,
};

export enum CharSize {
  CHAR = 'CHAR',
  THREE_BYTE = 'THREE_BYTE',
  TWO_BYTE = 'TWO_BYTE'
}

export type ChatAttachment = {
   __typename?: 'ChatAttachment',
  id: Scalars['ID'],
  name: Scalars['String'],
  size: Scalars['Int'],
  type: Scalars['String'],
  path?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type ChatMessage = {
   __typename?: 'ChatMessage',
  senderNickname: Scalars['String'],
  createdAt: Scalars['Date'],
  likeCount: Scalars['Int'],
  deletedAt: Scalars['Date'],
  reportCount: Scalars['Int'],
  id: Scalars['ID'],
  parent?: Maybe<ChatMessage>,
  sender?: Maybe<User>,
  chatRoom?: Maybe<ChatRoom>,
  viewerIsSender: Scalars['Boolean'],
  viewerHasLiked: Scalars['Boolean'],
  isDeleted: Scalars['Boolean'],
  content: Scalars['String'],
  adminContent: Scalars['String'],
  chatMessageReportLogs: ChatMessageReportLogOffsetConnection,
  lastChatMessageReportLog?: Maybe<ChatMessageReportLog>,
  reportedReasonAndCounts: Array<ReportedReasonAndCount>,
  hasViewerBlockedSender: Scalars['Boolean'],
  hasAdminRead: Scalars['Boolean'],
};


export type ChatMessageChatMessageReportLogsArgs = {
  pagination: Pagination
};

export type ChatMessageConnection = {
   __typename?: 'ChatMessageConnection',
  nodes: Array<ChatMessage>,
  edges: Array<ChatMessageEdge>,
  pageInfo: PageInfo,
};

export type ChatMessageEdge = {
   __typename?: 'ChatMessageEdge',
  node: ChatMessage,
  cursor: Scalars['String'],
};

export type ChatMessageFilter = {
  maxChatMessageID?: Maybe<Scalars['ID']>,
  minChatMessageID?: Maybe<Scalars['ID']>,
  minIsOldestUnreadLocation?: Maybe<Scalars['Boolean']>,
  maxIsOldestUnreadLocation?: Maybe<Scalars['Boolean']>,
};

export type ChatMessageOffsetConnection = {
   __typename?: 'ChatMessageOffsetConnection',
  nodes: Array<ChatMessage>,
  totalCount: Scalars['Float'],
};

export type ChatMessageReplyNotification = SimpleNotification & {
   __typename?: 'ChatMessageReplyNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ChatMessageReportLog = {
   __typename?: 'ChatMessageReportLog',
  reportedBy?: Maybe<User>,
  reportedAt: Scalars['Date'],
  reportReason: Scalars['String'],
  reportedChatMessage?: Maybe<ChatMessage>,
};

export type ChatMessageReportLogOffsetConnection = {
   __typename?: 'ChatMessageReportLogOffsetConnection',
  nodes: Array<ChatMessageReportLog>,
  totalCount: Scalars['Int'],
};

export type ChatOrganization = {
   __typename?: 'ChatOrganization',
  name?: Maybe<Scalars['String']>,
};

export type ChatRoom = {
   __typename?: 'ChatRoom',
  id: Scalars['ID'],
  memberCount: Scalars['Int'],
  rank?: Maybe<Scalars['Int']>,
  createdAt: Scalars['Date'],
  name: Scalars['String'],
  chatMessageCount: Scalars['Int'],
  lastChattedAt: Scalars['Date'],
  totalSenderCount: Scalars['Int'],
  imageUrl?: Maybe<Scalars['String']>,
  imageName?: Maybe<Scalars['String']>,
  activityTypeId: Scalars['Int'],
  activityTypeRank?: Maybe<Scalars['Int']>,
  lastMentorChatMessageCreatedAt?: Maybe<Scalars['Date']>,
  lastManagerChatMessageCreatedAt?: Maybe<Scalars['Date']>,
  fixedOrder?: Maybe<Scalars['Int']>,
  companyName?: Maybe<Scalars['String']>,
  outlink?: Maybe<ChatRoomOutlink>,
  isMentorRecentChatMessageSentInPopularChatRoom: Scalars['Boolean'],
  isMentorRecentChatMessageSentInMyChatRoom: Scalars['Boolean'],
  isManagerRecentChatMessageSentInPopularChatRoom: Scalars['Boolean'],
  isManagerRecentChatMessageSentInMyChatRoom: Scalars['Boolean'],
  enableNotification: Scalars['Boolean'],
  viewerIsMember: Scalars['Boolean'],
  unreadChatMessageCount?: Maybe<Scalars['Int']>,
  lastChatMessage?: Maybe<ChatMessage>,
  isActive: Scalars['Boolean'],
  isFixed: Scalars['Boolean'],
  chatRoomType: ChatRoomType,
};

export type ChatRoomConnection = {
   __typename?: 'ChatRoomConnection',
  nodes: Array<ChatRoom>,
  edges: Array<ChatRoomEdge>,
  pageInfo: PageInfo,
  totalCount?: Maybe<Scalars['Int']>,
};

export type ChatRoomEdge = {
   __typename?: 'ChatRoomEdge',
  node: ChatRoom,
  cursor: Scalars['String'],
};

export type ChatRoomOffsetConnection = {
   __typename?: 'ChatRoomOffsetConnection',
  nodes: Array<ChatRoom>,
  totalCount: Scalars['Int'],
};

export enum ChatRoomOrderByField {
  CHAT_MESSAGE_COUNT = 'CHAT_MESSAGE_COUNT',
  MEMBER_COUNT = 'MEMBER_COUNT',
  RANK = 'RANK',
  SENDER_COUNT = 'SENDER_COUNT'
}

export type ChatRoomOutlink = {
   __typename?: 'ChatRoomOutlink',
  id: Scalars['ID'],
  chatRoomId: Scalars['ID'],
  url: Scalars['String'],
};

export enum ChatRoomType {
  RECRUIT = 'RECRUIT',
  CONTEST = 'CONTEST',
  EDUCATION = 'EDUCATION',
  INTERN = 'INTERN',
  CLUB = 'CLUB',
  ACTIVITY = 'ACTIVITY',
  JOB_CATEGORY = 'JOB_CATEGORY',
  TOPIC = 'TOPIC'
}

export type CheckCode = {
  code: Scalars['String'],
  isSignupSession?: Maybe<Scalars['Boolean']>,
  phoneNumber: Scalars['String'],
  token: Scalars['String'],
};

export type ClearResponse = {
   __typename?: 'ClearResponse',
  message: Scalars['String'],
};

export type ClubFilterCollection = {
   __typename?: 'ClubFilterCollection',
  categories: Array<Category>,
  interests: Array<Interest>,
  regions: Array<Region>,
  targets: Array<ActivityTarget>,
};

export type CommentToCommentNotification = SimpleNotification & {
   __typename?: 'CommentToCommentNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CommentToMentorPostNotification = SimpleNotification & {
   __typename?: 'CommentToMentorPostNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CommentToPostNotification = SimpleNotification & {
   __typename?: 'CommentToPostNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type Community_Menu = CommunityBookmarkable & {
   __typename?: 'Community_Menu',
  bookmarkable?: Maybe<Scalars['Boolean']>,
  hasNewPost?: Maybe<Scalars['Boolean']>,
  id: Scalars['ID'],
  isShortcut?: Maybe<Scalars['Boolean']>,
  order?: Maybe<Scalars['Int']>,
  slug?: Maybe<Scalars['String']>,
  subMenus: Array<Community_Menu>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  viewerHasBookmarked: Scalars['Boolean'],
};

export type CommunityBestPostListData = {
   __typename?: 'CommunityBestPostListData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityBestPostListInput = {
  endDate?: Maybe<Scalars['String']>,
  minimumCommentCount?: Maybe<Scalars['Int']>,
  minimumLikeCount?: Maybe<Scalars['Int']>,
  minimumViewCount?: Maybe<Scalars['Int']>,
  order?: Maybe<Scalars['String']>,
  pagination?: Maybe<Pagination>,
  startDate?: Maybe<Scalars['String']>,
  type?: Maybe<CommunityBestPostListType>,
};

export enum CommunityBestPostListType {
  LAST_MONTH = 'LAST_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  RECENT = 'RECENT',
  REPLY_COUNT = 'REPLY_COUNT',
  VOTE_COUNT = 'VOTE_COUNT'
}

export type CommunityBoardListData = {
   __typename?: 'communityBoardListData',
  menu?: Maybe<Community_Menu>,
};

export type CommunityBoardObject = {
   __typename?: 'CommunityBoardObject',
  id: Scalars['ID'],
  slug?: Maybe<Scalars['String']>,
};

export enum CommunityBoardSlug {
  HOTHOT = 'HOTHOT',
  INTERN_ACTIVITY = 'INTERN_ACTIVITY',
  JUNIOR_ACTIVITY = 'JUNIOR_ACTIVITY',
  STRATEGY = 'STRATEGY'
}

export type CommunityBoardUrl = {
   __typename?: 'CommunityBoardUrl',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  jobType?: Maybe<Scalars['String']>,
  slug?: Maybe<Array<Scalars['String']>>,
  title: Scalars['String'],
  url: Scalars['String'],
};

export type CommunityBoardUrlConnection = {
   __typename?: 'CommunityBoardUrlConnection',
  nodes: Array<Maybe<CommunityBoardUrl>>,
  totalCount: Scalars['Int'],
};

export type CommunityBookmarkable = {
  id: Scalars['ID'],
  viewerHasBookmarked: Scalars['Boolean'],
};

export type CommunityBookmarkableConnection = {
   __typename?: 'CommunityBookmarkableConnection',
  nodes: Array<CommunityBookmarkable>,
  totalCount: Scalars['Int'],
};

export type CommunityBookmarkOrder = {
  direction: OrderDirection,
  field: CommunityBookmarkOrderField,
};

export enum CommunityBookmarkOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type CommunityNotificationsData = {
   __typename?: 'CommunityNotificationsData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityNotificationsInput = {
  authUserID: Scalars['ID'],
};

export type CommunityPostCollectionData = {
   __typename?: 'CommunityPostCollectionData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityPostCollectionInput = {
  collectionID: Scalars['Int'],
  pruneLength: Scalars['Int'],
};

export type CommunityPostObject = {
   __typename?: 'CommunityPostObject',
  board?: Maybe<CommunityBoardObject>,
  commentCount?: Maybe<Scalars['Int']>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  imageSrc?: Maybe<Scalars['String']>,
  likes?: Maybe<Scalars['Int']>,
  nickname?: Maybe<Scalars['String']>,
  scrapCount?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  views?: Maybe<Scalars['Int']>,
  youtubeSrc?: Maybe<Scalars['String']>,
};

export type CommunityPostRelatedWithActivity = {
   __typename?: 'CommunityPostRelatedWithActivity',
  activity?: Maybe<Activity>,
  communityPost?: Maybe<CommunityPostObject>,
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
};

export type CommunityPostRelatedWithActivityConnection = {
   __typename?: 'CommunityPostRelatedWithActivityConnection',
  nodes?: Maybe<Array<CommunityPostObject>>,
  totalCount: Scalars['Int'],
};

export type CommunityPostRelatedWithActivityManualConnection = {
   __typename?: 'CommunityPostRelatedWithActivityManualConnection',
  nodes?: Maybe<Array<CommunityPostRelatedWithActivity>>,
  totalCount: Scalars['Int'],
};

export type CommunityPostRelatedWithActivitySearchData = {
   __typename?: 'CommunityPostRelatedWithActivitySearchData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityPostSearchData = {
   __typename?: 'CommunityPostSearchData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityPostSearchInput = {
  boardSlugs: Array<Maybe<Scalars['String']>>,
  dutyId?: Maybe<Scalars['ID']>,
  dutyName?: Maybe<Scalars['String']>,
  field?: Maybe<SearchPostFromBoardWordField>,
  isBestPost?: Maybe<Scalars['Boolean']>,
  minimumShouldMatch?: Maybe<Scalars['String']>,
  orderBy?: Maybe<CommunityPostSearchOrderInput>,
  organizationName?: Maybe<Scalars['String']>,
  pagination?: Maybe<Pagination>,
  pruneLength: Scalars['Int'],
  query?: Maybe<Scalars['String']>,
  schoolName?: Maybe<Scalars['String']>,
};

export enum CommunityPostSearchOrderField {
  CREATED_AT = 'CREATED_AT',
  RELEVANCE = 'RELEVANCE',
  VIEW_COUNT = 'VIEW_COUNT'
}

export type CommunityPostSearchOrderInput = {
  direction: OrderDirection,
  field: CommunityPostSearchOrderField,
};

export type CommunityPostsRelatedWithActivityInput = {
  activityId: Scalars['ID'],
};

export type CommunityPostsRelatedWithActivityManualInput = {
  activityId: Scalars['ID'],
};

export type CompleteQuestionSuggestionInput = {
  ids: Array<Scalars['ID']>,
};

export type CompleteQuestionSuggestionPayload = {
   __typename?: 'CompleteQuestionSuggestionPayload',
  questionSuggestion: Array<Maybe<QuestionSuggestion>>,
};

export type ContestFilterCollection = {
   __typename?: 'ContestFilterCollection',
  benefits: Array<Benefit>,
  categories: Array<Category>,
  organizationTypes: Array<OrganizationType>,
  rewards: Array<Reward>,
  targets: Array<ActivityTarget>,
};

export type CostType = {
   __typename?: 'CostType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type CoverLetter = {
   __typename?: 'CoverLetter',
  activity?: Maybe<Scalars['String']>,
  addViewCount: Scalars['Int'],
  career?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  createdAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  grades?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isScrapped?: Maybe<Scalars['Boolean']>,
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['Int']>,
  scrapCount?: Maybe<Scalars['Int']>,
  source?: Maybe<CoverLetterSource>,
  sourceKey?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  viewCount: Scalars['Int'],
  viewerParagraphScraps: Array<CoverLetterParagraphScrap>,
};


export type CoverLetterContentArgs = {
  length?: Maybe<Scalars['Int']>
};

export type CoverLetterBulkCreateInput = {
  activity?: Maybe<Scalars['String']>,
  career?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  grades?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['Int']>,
  source?: Maybe<CoverLetterSource>,
  sourceKey?: Maybe<Scalars['String']>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
};

export type CoverLetterCreateInput = {
  activity?: Maybe<Scalars['String']>,
  career?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  grades?: Maybe<Scalars['String']>,
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  source?: Maybe<CoverLetterSource>,
  sourceKey?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
};

export type CoverLetterCreatePayload = {
   __typename?: 'CoverLetterCreatePayload',
  coverLetter?: Maybe<CoverLetter>,
};

export type CoverLetterDeleteManyPayload = {
   __typename?: 'coverLetterDeleteManyPayload',
  coverLetters?: Maybe<Array<Maybe<CoverLetter>>>,
};

export type CoverLetterElasticConnection = {
   __typename?: 'CoverLetterElasticConnection',
  edges?: Maybe<Array<CoverLetterElasticEdge>>,
  totalCount: Scalars['Int'],
};

export type CoverLetterElasticEdge = {
   __typename?: 'CoverLetterElasticEdge',
  highlight?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<CoverLetter>,
};

export enum CoverLetterField {
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  ROLE = 'ROLE'
}

export type CoverLetterFilter = {
  fragmentSize?: Maybe<Scalars['Int']>,
  keyword?: Maybe<Scalars['String']>,
  numberOfFragments?: Maybe<Scalars['Int']>,
  organizationName?: Maybe<Scalars['String']>,
  role?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
};

export type CoverLetterManyAddTypeInput = {
  ids: Array<Scalars['ID']>,
  type: CoverLetterType,
};

export type CoverLetterManyAddTypePayload = {
   __typename?: 'CoverLetterManyAddTypePayload',
  updatedCoverLetters?: Maybe<Array<Maybe<CoverLetter>>>,
};

export type CoverLetterOrder = {
  direction: OrderDirection,
  field: CoverLetterOrderField,
};

export enum CoverLetterOrderField {
  CAREER = 'CAREER',
  GRADES = 'GRADES',
  ID = 'ID',
  LANGUAGE_SCORE = 'LANGUAGE_SCORE',
  MAJOR = 'MAJOR',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  PASSED_AT = 'PASSED_AT',
  RELEVANCE = 'RELEVANCE',
  ROLE = 'ROLE',
  SCORE = 'SCORE',
  SCRAP_COUNT = 'SCRAP_COUNT',
  STATUS = 'STATUS',
  UNIVERSITY = 'UNIVERSITY'
}

export type CoverLetterOrganizationListInput = {
  query?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
};

export type CoverLetterOrganizationListPayload = {
   __typename?: 'CoverLetterOrganizationListPayload',
  organizationName?: Maybe<Scalars['String']>,
};

export type CoverLetterParagraphScrap = {
   __typename?: 'CoverLetterParagraphScrap',
  coverLetter?: Maybe<CoverLetter>,
  createdAt: Scalars['Date'],
  endOffset: Scalars['Int'],
  id: Scalars['ID'],
  paragraph: Scalars['String'],
  startOffset: Scalars['Int'],
  user?: Maybe<User>,
};

export type CoverLetterParagraphScrapCreateInput = {
  coverLetterID: Scalars['ID'],
  endOffset: Scalars['Int'],
  paragraph: Scalars['String'],
  referer: Scalars['String'],
  startOffset: Scalars['Int'],
};

export type CoverLetterParagraphScrapCreatePayload = {
   __typename?: 'CoverLetterParagraphScrapCreatePayload',
  coverLetterParagraphScrap?: Maybe<CoverLetterParagraphScrap>,
  userScrap?: Maybe<UserScrap>,
};

export type CoverLetterParagraphScrapDeletePayload = {
   __typename?: 'CoverLetterParagraphScrapDeletePayload',
  coverLetterParagraphScrap?: Maybe<CoverLetterParagraphScrap>,
  userScrap?: Maybe<UserScrap>,
};

export type CoverLetterPlagiarism = {
   __typename?: 'CoverLetterPlagiarism',
  content: Scalars['String'],
  id: Scalars['ID'],
  score: Scalars['String'],
};

export type CoverLetterPlagiarismConnection = {
   __typename?: 'CoverLetterPlagiarismConnection',
  nodes: Array<CoverLetterPlagiarism>,
  totalCount: Scalars['Int'],
};

export type CoverLetterRelatedWithActivity = {
   __typename?: 'CoverLetterRelatedWithActivity',
  activity?: Maybe<Activity>,
  coverLetter?: Maybe<CoverLetter>,
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
};

export type CoverLetterRelatedWithActivityConnection = {
   __typename?: 'CoverLetterRelatedWithActivityConnection',
  nodes?: Maybe<Array<CoverLetter>>,
  totalCount: Scalars['Int'],
};

export type CoverLetterRelatedWithActivityManualConnection = {
   __typename?: 'CoverLetterRelatedWithActivityManualConnection',
  nodes?: Maybe<Array<CoverLetterRelatedWithActivity>>,
  totalCount: Scalars['Int'],
};

export type CoverLetterRoleListInput = {
  query?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
};

export type CoverLetterRoleListPayload = {
   __typename?: 'CoverLetterRoleListPayload',
  role?: Maybe<Scalars['String']>,
};

export type CoverLetterScrapUpdateInput = {
  coverLetterID: Scalars['ID'],
  referer: Scalars['String'],
};

export type CoverLetterScrapUpdatePayload = {
   __typename?: 'CoverLetterScrapUpdatePayload',
  coverLetter?: Maybe<CoverLetter>,
  scrapped?: Maybe<Scalars['Boolean']>,
  userScrap?: Maybe<UserScrap>,
};

export enum CoverLetterSource {
  MANUAL_BY_ADMIN = 'MANUAL_BY_ADMIN',
  NAVER_SPECUP = 'NAVER_SPECUP',
  OPEN_EVENT = 'OPEN_EVENT'
}

export type CoverLettersRelatedWithActivityInput = {
  activityId: Scalars['ID'],
};

export type CoverLettersRelatedWithActivityManualInput = {
  activityId: Scalars['ID'],
};

export enum CoverLetterStatus {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED'
}

export enum CoverLetterType {
  ALL = 'ALL',
  BANKS = 'BANKS',
  ETC = 'ETC',
  INTERN = 'INTERN',
  MAJOR_COMPANY = 'MAJOR_COMPANY',
  MEDIUM_COMPANY = 'MEDIUM_COMPANY',
  NEWCOMER = 'NEWCOMER',
  PUBLIC_INSTITUTIONS = 'PUBLIC_INSTITUTIONS',
  SMALL_COMPANY = 'SMALL_COMPANY'
}

export type CoverLetterTypeConnection = {
   __typename?: 'CoverLetterTypeConnection',
  coverLetterID: Scalars['ID'],
  id: Scalars['ID'],
  name?: Maybe<CoverLetterType>,
};

export type CoverLetterUpdateInput = {
  activity?: Maybe<Scalars['String']>,
  career?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  grades?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
  viewCount?: Maybe<Scalars['Int']>,
};

export type CoverLetterUpdateManyInput = {
  activity?: Maybe<Scalars['String']>,
  career?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  grades?: Maybe<Scalars['String']>,
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
};

export type CoverLetterUpdatePayload = {
   __typename?: 'CoverLetterUpdatePayload',
  coverLetter?: Maybe<CoverLetter>,
};

export type CoverLetterWithHighlight = {
   __typename?: 'CoverLetterWithHighlight',
  coverLetter?: Maybe<CoverLetter>,
  highlight?: Maybe<Array<Scalars['String']>>,
};

export type CreateAddOrganizationSuggestionInput = {
  deviceInfo?: Maybe<DeviceType>,
  organizationName: Scalars['String'],
};

export type CreateAddOrganizationSuggestionPayload = {
   __typename?: 'CreateAddOrganizationSuggestionPayload',
  suggestion: Suggestion,
};

export type CreateAdPlacementInput = {
  code: Scalars['String'],
  defaultImage?: Maybe<Scalars['Upload']>,
  defaultLandingUrl?: Maybe<Scalars['String']>,
  height: Scalars['Int'],
  isActive: Scalars['Boolean'],
  name: Scalars['String'],
  type: AdPlaceType,
  unifiedAdPlacementId: Scalars['ID'],
  width: Scalars['Int'],
};

export type CreateAdPlacementPayload = {
   __typename?: 'CreateAdPlacementPayload',
  adPlacement: AdPlacement,
};

export type CreateCampaignGroupInput = {
  name: Scalars['String'],
};

export type CreateCampaignGroupPayloadDto = {
   __typename?: 'CreateCampaignGroupPayloadDto',
  campaignGroup: CampaignGroupDto,
};

export type CreateCampaignInput = {
  activityId?: Maybe<Scalars['ID']>,
  advertiser?: Maybe<Scalars['String']>,
  campaignGroupId: Scalars['ID'],
  content?: Maybe<Scalars['String']>,
  endAt: Scalars['Date'],
  landingUrl?: Maybe<Scalars['String']>,
  mobileImage?: Maybe<Scalars['Upload']>,
  order: Scalars['Int'],
  pcImage?: Maybe<Scalars['Upload']>,
  startAt: Scalars['Date'],
  title?: Maybe<Scalars['String']>,
  unifiedAdPlacementId: Scalars['ID'],
};

export type CreateCampaignPayload = {
   __typename?: 'CreateCampaignPayload',
  campaign: Campaign,
};

export type CreateChannelInput = {
  briefDescription: Scalars['String'],
  channelUrl: Scalars['String'],
  coverAttachmentId?: Maybe<Scalars['ID']>,
  description?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  ownerId?: Maybe<Scalars['ID']>,
};

export type CreateChannelPayload = {
   __typename?: 'CreateChannelPayload',
  channel?: Maybe<Channel>,
};

export type CreateChatRoomInput = {
  name: Scalars['String'],
  activityTypeId?: Maybe<Scalars['Int']>,
  imageUrl?: Maybe<Scalars['String']>,
  imageName?: Maybe<Scalars['String']>,
};

export type CreateChatRoomPayload = {
   __typename?: 'CreateChatRoomPayload',
  chatRoom: ChatRoom,
};

export type CreatedAtTerm = {
  from?: Maybe<Scalars['Date']>,
  to?: Maybe<Scalars['Date']>,
};

export type CreateEmbedInput = {
  description?: Maybe<Scalars['String']>,
  domain: Scalars['String'],
  imageUrl?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type CreateExamGradingQuestionInput = {
  answers: Array<Scalars['Int']>,
  content: Scalars['String'],
  seq: Scalars['Int'],
  subject: ExamSubject,
};

export type CreateExamGradingUserResultInput = {
  submitAnswers: Array<SubmitExamGradingAnswer>,
  testId: Scalars['ID'],
};

export type CreateExamReserveInformationInput = {
  companyId: Scalars['ID'],
  departmentId: Scalars['ID'],
  hasAgreedToPrivacy: Scalars['Boolean'],
  jobId: Scalars['ID'],
  phoneNumber: Scalars['String'],
  subsidiaryId: Scalars['ID'],
  termId: Scalars['ID'],
  testId: Scalars['ID'],
};

export type CreateExamReserveInformationPayload = {
   __typename?: 'CreateExamReserveInformationPayload',
  examReserveInformation: ExamReserveInformation,
};

export type CreateExamSurveyUserAnswerInput = {
  answerId: Scalars['ID'],
  numericAnswer?: Maybe<Scalars['Int']>,
  questionId: Scalars['ID'],
};

export type CreateExamTestInput = {
  companyId: Scalars['ID'],
  date: Scalars['Date'],
  gradingQuestions: Array<CreateExamGradingQuestionInput>,
  name: Scalars['String'],
  termId: Scalars['ID'],
};

export type CreateExamTestPayload = {
   __typename?: 'CreateExamTestPayload',
  examTest: ExamTest,
};

export type CreateFolderInput = {
  name: Scalars['String'],
  parentId?: Maybe<Scalars['ID']>,
};

export type CreateFolderPayload = {
   __typename?: 'CreateFolderPayload',
  folder: Folder,
};

export type CreateJobCurationActivityPayload = {
   __typename?: 'CreateJobCurationActivityPayload',
  jobCurationActivity: JobCurationActivity,
};

export type CreateJobCurationActivitySettingInput = {
  activityId: Scalars['ID'],
  endDate?: Maybe<Scalars['Date']>,
  seq: Scalars['Int'],
  startDate: Scalars['Date'],
  type: JobCurationActivityType,
};

export type CreateJobCurationCenterBannerInput = {
  endDate?: Maybe<Scalars['Date']>,
  landingUrl: Scalars['String'],
  mobileImage: Scalars['Upload'],
  pcImage: Scalars['Upload'],
  seq: Scalars['Int'],
  startDate: Scalars['Date'],
  title: Scalars['String'],
};

export type CreateJobCurationCenterBannerPayload = {
   __typename?: 'CreateJobCurationCenterBannerPayload',
  jobCurationCenterBanner: JobCurationCenterBanner,
};

export type CreateLinkareerMagazineInput = {
  content?: Maybe<Scalars['String']>,
  pcImage?: Maybe<Scalars['String']>,
  pcImageId: Scalars['ID'],
  title: Scalars['String'],
  url: Scalars['String'],
};

export type CreateLinkareerMagazinePayload = {
   __typename?: 'CreateLinkareerMagazinePayload',
  linkareerMagazine?: Maybe<LinkareerMagazine>,
};

export type CreateNewsCommentInput = {
  content: Scalars['String'],
  newsItemId: Scalars['ID'],
  parentId?: Maybe<Scalars['ID']>,
};

export type CreateNewsCommentPayload = {
   __typename?: 'CreateNewsCommentPayload',
  newsComment?: Maybe<NewsComment>,
};

export type CreateNewsItemInput = {
  content: Scalars['String'],
  embedInput?: Maybe<CreateEmbedInput>,
  imageIds?: Maybe<Array<Scalars['ID']>>,
  tagNames?: Maybe<Array<Scalars['String']>>,
};

export type CreateNewsItemPayload = {
   __typename?: 'CreateNewsItemPayload',
  newsItem?: Maybe<NewsItem>,
};

export type CreateQnAInput = {
  answer?: Maybe<Scalars['String']>,
  blankType?: Maybe<BlankType>,
  charMaxSize?: Maybe<Scalars['Int']>,
  charSize?: Maybe<CharSize>,
  question?: Maybe<Scalars['String']>,
};

export type CreateQnAPayload = {
   __typename?: 'CreateQnAPayload',
  qna?: Maybe<QnA>,
};

export type CreateQuestionSuggestionInput = {
  activityId: Scalars['ID'],
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
  content: Scalars['String'],
  email: Scalars['String'],
};

export type CreateQuestionSuggestionPayload = {
   __typename?: 'CreateQuestionSuggestionPayload',
  questionSuggestion?: Maybe<QuestionSuggestion>,
};

export type CreateRecommendSearchKeywordInput = {
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  type?: Maybe<RecommendSearchKeywordType>,
};

export type CreateRecommendSearchKeywordPayload = {
   __typename?: 'CreateRecommendSearchKeywordPayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type CreateSelfIntroductionInput = {
  dutyId?: Maybe<Scalars['ID']>,
  qnas?: Maybe<Array<CreateQnAInput>>,
  title: Scalars['String'],
};

export type CreateSelfIntroductionPayload = {
   __typename?: 'CreateSelfIntroductionPayload',
  selfIntroduction?: Maybe<SelfIntroduction>,
};

export type CreateSuggestionInput = {
  attachmentIds?: Maybe<Array<Scalars['ID']>>,
  content: Scalars['String'],
  deviceInfo?: Maybe<DeviceType>,
  email: Scalars['String'],
};

export type CreateSuggestionPayload = {
   __typename?: 'createSuggestionPayload',
  suggestion: Suggestion,
};

export type CreateTagCollectionInput = {
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
};

export type CreateTagCollectionPayload = {
   __typename?: 'CreateTagCollectionPayload',
  tagCollection?: Maybe<TagCollection>,
};

export type CreateTestUserInput = {
  birthday?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  profileURL?: Maybe<Scalars['String']>,
  specupJoined?: Maybe<Scalars['Boolean']>,
  tsSignup?: Maybe<Scalars['Boolean']>,
  userType?: Maybe<UserType>,
  username?: Maybe<Scalars['String']>,
};

export type CreateTestUserPayload = {
   __typename?: 'CreateTestUserPayload',
  user?: Maybe<User>,
};

export type CreateUniqueNameOrganizationGroupInput = {
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type CreateUniqueNameOrganizationInput = {
  createdAt: Scalars['Date'],
  group?: Maybe<CreateUniqueNameOrganizationGroupInput>,
  id: Scalars['ID'],
  isGroup: Scalars['Boolean'],
  name: Scalars['String'],
  postCount: Scalars['Int'],
};

export type CreateUniqueNameOrganizationsInput = {
  inputs: Array<CreateUniqueNameOrganizationInput>,
};

export type CreateUniqueNameOrganizationsPayload = {
   __typename?: 'CreateUniqueNameOrganizationsPayload',
  createdCount: Scalars['Int'],
};

export type Curation = {
   __typename?: 'Curation',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
  url?: Maybe<Scalars['String']>,
};

export type CurationCreateInput = {
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  url?: Maybe<Scalars['String']>,
};

export type CurationCreatePayload = {
   __typename?: 'CurationCreatePayload',
  curation?: Maybe<Curation>,
};

export type CurationDeletePayload = {
   __typename?: 'CurationDeletePayload',
  curation?: Maybe<Curation>,
};

export type CurationListConnection = {
   __typename?: 'CurationListConnection',
  curations?: Maybe<Array<Maybe<Curation>>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type CurationUpdateInput = {
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type CurationUpdatePayload = {
   __typename?: 'CurationUpdatePayload',
  curation?: Maybe<Curation>,
};

export type CurrentUserScrapCommunityConnection = {
   __typename?: 'CurrentUserScrapCommunityConnection',
  nodes: Array<UserScrapCommunity>,
  totalCount: Scalars['Int'],
};

export type CurrentUserScrapCommunityFilter = {
  isDeletedAt?: Maybe<Scalars['Boolean']>,
  postID?: Maybe<Scalars['ID']>,
  q?: Maybe<Scalars['String']>,
};

export type CurrentUserScrapDeleteCommunityPayload = {
   __typename?: 'currentUserScrapDeleteCommunityPayload',
  userScrapCommunity?: Maybe<UserScrapCommunity>,
};

export type CurrentUserScrapsDeleteAllCommunityPayload = {
   __typename?: 'currentUserScrapsDeleteAllCommunityPayload',
  userScrapsCommunity?: Maybe<Array<UserScrapCommunity>>,
};

export type CursorPagination = {
  cursor?: Maybe<Scalars['String']>,
  isForward?: Maybe<Scalars['Boolean']>,
  take: Scalars['Int'],
};


export enum DatePeriod {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export type DateRange = {
  from: Scalars['Date'],
  to: Scalars['Date'],
};

export enum DateRepresentationType {
  DAY = 'DAY',
  MONTH = 'MONTH'
}

export enum DateTerm {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

export type DeactivateChannelPayload = {
   __typename?: 'DeactivateChannelPayload',
  channel?: Maybe<Channel>,
};

export type DeleteActivityManagerPresetPayload = {
   __typename?: 'DeleteActivityManagerPresetPayload',
  managerPreset?: Maybe<ActivityManagerPreset>,
};

export type DeleteActivityOrganizationPresetPayload = {
   __typename?: 'DeleteActivityOrganizationPresetPayload',
  organizationPreset?: Maybe<ActivityOrganizationPreset>,
};

export type DeleteAllNotificationPayload = {
   __typename?: 'DeleteAllNotificationPayload',
  count?: Maybe<Scalars['Int']>,
};

export type DeleteCampaignGroupsPayloadDto = {
   __typename?: 'DeleteCampaignGroupsPayloadDto',
  result: Scalars['Boolean'],
};

export type DeleteCampaignsPayload = {
   __typename?: 'DeleteCampaignsPayload',
  campaigns: Array<Campaign>,
};

export type DeleteChannelPayload = {
   __typename?: 'DeleteChannelPayload',
  channel: Channel,
};

export type DeleteChatMessagePayload = {
   __typename?: 'DeleteChatMessagePayload',
  chatMessage: ChatMessage,
};

export type DeleteChatRoomPayload = {
   __typename?: 'DeleteChatRoomPayload',
  chatRoom: ChatRoom,
};

export type DeleteCurrentUserInput = {
  reason: Scalars['String'],
  reasonType: UserDeleteReasonType,
  userID: Scalars['ID'],
  userType: Scalars['String'],
};

export type DeleteCurrentUserPayload = {
   __typename?: 'DeleteCurrentUserPayload',
  user?: Maybe<User>,
};

export enum DeletedChatMessageSearchField {
  CONTENT = 'CONTENT',
  CHAT_NICKNAME = 'CHAT_NICKNAME',
  CHAT_ROOM_NAME = 'CHAT_ROOM_NAME'
}

export type DeletedChatMessageSearchInput = {
  searchField: DeletedChatMessageSearchField,
  searchKeyword: Scalars['String'],
};

export type DeletedNewsCommentFilter = {
  deletedAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

export type DeletedNewsItemFilter = {
  deletedAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

export type DeletedUserScrap = {
   __typename?: 'DeletedUserScrap',
  scrapped?: Maybe<Scalars['Boolean']>,
  userScrap?: Maybe<UserScrap>,
};

export type DeleteFolderInput = {
  id: Scalars['ID'],
};

export type DeleteFolderPayload = {
   __typename?: 'DeleteFolderPayload',
  folder: Folder,
};

export type DeleteManyUserAccountsPayload = {
   __typename?: 'DeleteManyUserAccountsPayload',
  users: Array<Maybe<User>>,
};

export type DeleteNewsCommentsPayload = {
   __typename?: 'DeleteNewsCommentsPayload',
  newsComments?: Maybe<Array<Maybe<NewsComment>>>,
};

export type DeleteNewsItemsPayload = {
   __typename?: 'DeleteNewsItemsPayload',
  newsItems?: Maybe<Array<Maybe<NewsItem>>>,
};

export type DeleteNotificationPayload = {
   __typename?: 'DeleteNotificationPayload',
  notification?: Maybe<SimpleNotification>,
};

export type DeleteQnAInput = {
  id: Scalars['ID'],
};

export type DeleteQnAPayload = {
   __typename?: 'DeleteQnAPayload',
  qna?: Maybe<QnA>,
};

export type DeleteQnASnapshotPayload = {
   __typename?: 'DeleteQnASnapshotPayload',
  snapshot?: Maybe<QnASnapshot>,
};

export type DeleteQuestionSuggestionInput = {
  ids: Array<Scalars['ID']>,
};

export type DeleteQuestionSuggestionPayload = {
   __typename?: 'DeleteQuestionSuggestionPayload',
  questionSuggestion: Array<Maybe<QuestionSuggestion>>,
};

export type DeleteRecommendSearchKeywordPayload = {
   __typename?: 'DeleteRecommendSearchKeywordPayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type DeleteSelfIntroductionInput = {
  id: Scalars['ID'],
};

export type DeleteSelfIntroductionPayload = {
   __typename?: 'DeleteSelfIntroductionPayload',
  selfIntroduction?: Maybe<SelfIntroduction>,
};

export type DeleteTagCollectionPayload = {
   __typename?: 'DeleteTagCollectionPayload',
  tagCollection?: Maybe<TagCollection>,
};

export type DeleteUniqueNameOrganizationsPayload = {
   __typename?: 'DeleteUniqueNameOrganizationsPayload',
  deletedCount: Scalars['Int'],
};

export type DeleteUserAccountPayload = {
   __typename?: 'DeleteUserAccountPayload',
  user?: Maybe<User>,
};

export enum DeviceType {
  KAKAO_CHATBOT_ACTIVITY = 'KAKAO_CHATBOT_ACTIVITY',
  KAKAO_CHATBOT_CONTEST = 'KAKAO_CHATBOT_CONTEST',
  KAKAO_CHATBOT_RECRUIT = 'KAKAO_CHATBOT_RECRUIT',
  MOBILE = 'MOBILE',
  PC = 'PC'
}

export type Duty = {
   __typename?: 'Duty',
  activity?: Maybe<Activity>,
  categories: Array<Maybe<Category>>,
  id: Scalars['ID'],
  jobType: JobType,
  questionTemplates?: Maybe<Array<QuestionTemplate>>,
  selfIntroductionCount: Scalars['Int'],
  selfIntroductions: SelfIntroductionConnection,
  title: Scalars['String'],
  viewerSelfIntroductionFeed: SelfIntroductionConnection,
};


export type DutySelfIntroductionsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type DutyViewerSelfIntroductionFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type DutyConnection = {
   __typename?: 'DutyConnection',
  nodes: Array<Maybe<Duty>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type DutyFeedFilters = {
  hasQuestionTemplate?: Maybe<Scalars['Boolean']>,
};

export type DutyFilters = {
  categoryId?: Maybe<Scalars['ID']>,
  hasQuestionTemplate?: Maybe<Scalars['Boolean']>,
  ignoreEndedActivity?: Maybe<Scalars['Boolean']>,
  jobType?: Maybe<JobType>,
  query?: Maybe<Scalars['String']>,
};

export type DutyInput = {
  categoryIds: Array<Scalars['ID']>,
  jobType: JobType,
  questionTemplates?: Maybe<Array<QuestionTemplateInput>>,
  title: Scalars['String'],
};

export type DutyOrder = {
  direction: OrderDirection,
  field: DutyOrderField,
};

export enum DutyOrderField {
  CREATED_AT = 'CREATED_AT',
  END_AT = 'END_AT',
  JOB_TYPE_CREATED_AT = 'JOB_TYPE_CREATED_AT'
}

export enum EducationCost {
  FREE = 'FREE',
  FREE_SPONSORED = 'FREE_SPONSORED',
  PAID = 'PAID',
  PAID_SPONSORED = 'PAID_SPONSORED'
}

export enum EducationDetailType {
  FOR_EXPERIENCE = 'FOR_EXPERIENCE',
  TO_HIRE = 'TO_HIRE'
}

export type EducationFilterCollection = {
   __typename?: 'EducationFilterCollection',
  categories?: Maybe<Array<Category>>,
  costTypes?: Maybe<Array<CostType>>,
  educationTypes: Array<ActivityEducationDetailType>,
  onofflines?: Maybe<Array<Onoffline>>,
  periods?: Maybe<Array<PeriodForEducationFilterCollection>>,
  regions?: Maybe<Array<Region>>,
};

export enum EducationType {
  COLLEAGE = 'COLLEAGE',
  EXAM = 'EXAM',
  GRADUATED = 'GRADUATED',
  HIGHSCHOOL = 'HIGHSCHOOL',
  UNIVERSITY = 'UNIVERSITY'
}

export type Embed = {
   __typename?: 'Embed',
  description?: Maybe<Scalars['String']>,
  domain: Scalars['String'],
  id: Scalars['ID'],
  imageUrl?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type Environment = {
   __typename?: 'Environment',
  domain: Scalars['String'],
};

export enum ErrorCodes {
  ALREADY_EXISTING_EMAIL = 'ALREADY_EXISTING_EMAIL',
  ALREADY_EXISTING_NICKNAME = 'ALREADY_EXISTING_NICKNAME',
  ALREADY_EXISTING_ORGANIZATION = 'ALREADY_EXISTING_ORGANIZATION',
  ALREADY_EXISTING_USERNAME = 'ALREADY_EXISTING_USERNAME',
  ALREADY_RATING_COMMENT = 'ALREADY_RATING_COMMENT',
  ALREADY_RATING_POST = 'ALREADY_RATING_POST',
  API_GRAPHQL_REQUEST_ERROR = 'API_GRAPHQL_REQUEST_ERROR',
  BEFORE_IDENTIFY = 'BEFORE_IDENTIFY',
  CANNOT_FOLLOW_OWN_CHANNEL = 'CANNOT_FOLLOW_OWN_CHANNEL',
  CANNOT_RATING_OWN_COMMENT = 'CANNOT_RATING_OWN_COMMENT',
  CANNOT_RATING_OWN_POST = 'CANNOT_RATING_OWN_POST',
  DEACTIVATED_CHANNEL = 'DEACTIVATED_CHANNEL',
  FORBIDDEN = 'FORBIDDEN',
  INVALID_REQUEST = 'INVALID_REQUEST',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  NOT_MATCHED_CERTIFICATION_INFO = 'NOT_MATCHED_CERTIFICATION_INFO',
  PERMANENTLY_MOVE = 'PERMANENTLY_MOVE',
  REQUEST_TIMEOUT = 'REQUEST_TIMEOUT',
  REQUIRED_LOGGED_IN = 'REQUIRED_LOGGED_IN',
  TEMPORARY_MOVE = 'TEMPORARY_MOVE',
  TIMESPREAD_STATUS_LUCKYBOX_ALREADY_OPEN = 'TIMESPREAD_STATUS_LUCKYBOX_ALREADY_OPEN',
  TIMESPREAD_STATUS_LUCKYBOX_LIMIT_EXCEED = 'TIMESPREAD_STATUS_LUCKYBOX_LIMIT_EXCEED',
  TIMESPREAD_STATUS_LUCKYBOX_NEED_CASH = 'TIMESPREAD_STATUS_LUCKYBOX_NEED_CASH',
  TIMESPREAD_STATUS_OBJECT_NOT_EXIST = 'TIMESPREAD_STATUS_OBJECT_NOT_EXIST',
  TIMESPREAD_STATUS_PHONE_ALREADY_AUTHORIZE = 'TIMESPREAD_STATUS_PHONE_ALREADY_AUTHORIZE',
  TIMESPREAD_STATUS_PHONE_CODE_DATA_NOT_FOUND = 'TIMESPREAD_STATUS_PHONE_CODE_DATA_NOT_FOUND',
  TIMESPREAD_STATUS_PHONE_CODE_REQUEST_EXCEED = 'TIMESPREAD_STATUS_PHONE_CODE_REQUEST_EXCEED',
  TIMESPREAD_STATUS_PHONE_FAIL_AUTHORIZE = 'TIMESPREAD_STATUS_PHONE_FAIL_AUTHORIZE',
  TIMESPREAD_STATUS_PHONE_NOT_ACCEPTED_IP = 'TIMESPREAD_STATUS_PHONE_NOT_ACCEPTED_IP',
  TIMESPREAD_STATUS_PHONE_REQUEST_EXCEED = 'TIMESPREAD_STATUS_PHONE_REQUEST_EXCEED',
  TIMESPREAD_STATUS_PHONE_USER_DELETE = 'TIMESPREAD_STATUS_PHONE_USER_DELETE',
  TIMESPREAD_STATUS_REQUIRE_PARAMETER = 'TIMESPREAD_STATUS_REQUIRE_PARAMETER',
  TIMESPREAD_STATUS_SCREEN_ALREADY_FINISH = 'TIMESPREAD_STATUS_SCREEN_ALREADY_FINISH',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export type ErrorWord = {
   __typename?: 'ErrorWord',
  endIndex: Scalars['Int'],
  helpMessage?: Maybe<Scalars['String']>,
  originalWord: Scalars['String'],
  recommendedWords: Array<Scalars['String']>,
  startIndex: Scalars['Int'],
};

export type ExamCompany = {
   __typename?: 'ExamCompany',
  id: Scalars['ID'],
  name: Scalars['String'],
  subsidiaries: Array<ExamSubsidiary>,
};

export type ExamDepartment = {
   __typename?: 'ExamDepartment',
  id: Scalars['ID'],
  jobs: Array<ExamJob>,
  name: Scalars['String'],
};

export type ExamFilters = {
  companyId: Scalars['ID'],
  termId: Scalars['ID'],
};

export type ExamGradingAnswerStatistics = {
   __typename?: 'ExamGradingAnswerStatistics',
  answer: Scalars['Int'],
  rate: Scalars['Int'],
};

export type ExamGradingQuestion = {
   __typename?: 'ExamGradingQuestion',
  answers: Array<Scalars['Int']>,
  content: Scalars['String'],
  id: Scalars['ID'],
  seq: Scalars['Int'],
  statistics: Array<ExamGradingAnswerStatistics>,
  subject: ExamSubject,
  userAnswers: Array<Scalars['Int']>,
};

export type ExamGradingQuestionFilters = {
  subject: ExamSubject,
  testId: Scalars['ID'],
};

export type ExamGradingResult = {
   __typename?: 'ExamGradingResult',
  examGradingResultOfSameInfo: ExamGradingResultItem,
  overallExamGradingResult: ExamGradingResultItem,
  top30percentExamGradingResultOfSameInfo: ExamGradingResultItem,
  top30percentOfOverallExamGradingResult: ExamGradingResultItem,
  viewerRank: Scalars['Int'],
  viewerRankOfSameInfo: Scalars['Int'],
  viewerResult: ExamGradingResultItem,
};


export type ExamGradingResultExamGradingResultOfSameInfoArgs = {
  type: SameExamInfoType
};


export type ExamGradingResultTop30percentExamGradingResultOfSameInfoArgs = {
  type: SameExamInfoType
};


export type ExamGradingResultViewerRankOfSameInfoArgs = {
  type: SameExamInfoType
};

export type ExamGradingResultDistribution = {
   __typename?: 'ExamGradingResultDistribution',
  range: Scalars['String'],
  rate: Scalars['Int'],
};

export type ExamGradingResultDistributionInput = {
  scoreRanges: Array<ScoreRangeInput>,
  testId: Scalars['ID'],
};

export type ExamGradingResultItem = {
   __typename?: 'ExamGradingResultItem',
  correctCount: Scalars['Int'],
  correctRate: Scalars['Int'],
  detail: Array<ExamGradingResultItemWithSubject>,
  totalCount: Scalars['Int'],
};

export type ExamGradingResultItemWithSubject = {
   __typename?: 'ExamGradingResultItemWithSubject',
  correctCount: Scalars['Int'],
  correctRate: Scalars['Int'],
  subject: ExamSubject,
  totalCount: Scalars['Int'],
};

export type ExamJob = {
   __typename?: 'ExamJob',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ExamQuestionKeyword = {
   __typename?: 'ExamQuestionKeyword',
  id: Scalars['ID'],
  keyword: Scalars['String'],
};

export type ExamQuestionKeywordConnection = {
   __typename?: 'ExamQuestionKeywordConnection',
  edges: Array<ExamQuestionKeywordEdge>,
  nodes: Array<ExamQuestionKeyword>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ExamQuestionKeywordEdge = {
   __typename?: 'ExamQuestionKeywordEdge',
  cursor: Scalars['String'],
  node: ExamQuestionKeyword,
};

export type ExamReserveInformation = {
   __typename?: 'ExamReserveInformation',
  examDepartment: ExamDepartment,
  examJob: ExamJob,
  examSubsidiary: ExamSubsidiary,
  examTest: ExamTest,
  hasAgreedToPrivacy: Scalars['Boolean'],
  updatedAt: Scalars['Date'],
  user: User,
};

export type ExamReserveInformationConnection = {
   __typename?: 'ExamReserveInformationConnection',
  nodes: Array<ExamReserveInformation>,
  totalCount: Scalars['Int'],
};

export type ExamReserveInformationFilters = {
  companyId?: Maybe<Scalars['ID']>,
  dateRange?: Maybe<DateRange>,
};

export enum ExamReserveInformationOrderField {
  TEST_DATE = 'TEST_DATE',
  UPDATED_AT = 'UPDATED_AT'
}

export type ExamReserveInformationOrders = {
  direction: OrderDirection,
  field: ExamReserveInformationOrderField,
};

export type ExamRestoredAnswer = {
   __typename?: 'ExamRestoredAnswer',
  answer: Scalars['Int'],
  question: ExamRestoredQuestion,
};

export type ExamRestoredQuestion = {
   __typename?: 'ExamRestoredQuestion',
  id: Scalars['ID'],
  keywordCount: Scalars['Int'],
  seq: Scalars['Int'],
  subject: ExamSubject,
  test: ExamTest,
  viewerAnswer?: Maybe<ExamRestoredAnswer>,
};

export type ExamRestoredQuestionConnection = {
   __typename?: 'ExamRestoredQuestionConnection',
  nodes: Array<ExamRestoredQuestion>,
  totalCount: Scalars['Int'],
};

export type ExamRestoredQuestionsFilters = {
  keyword?: Maybe<Scalars['String']>,
  subject: ExamSubject,
  testId: Scalars['ID'],
};

export enum ExamRestoredQuestionsOrderField {
  SEQ = 'SEQ'
}

export type ExamRestoredQuestionsOrders = {
  direction: OrderDirection,
  field: ExamRestoredQuestionsOrderField,
};

export enum ExamSubject {
  MATH = 'MATH',
  REASONING = 'REASONING'
}

export type ExamSubsidiary = {
   __typename?: 'ExamSubsidiary',
  departments: Array<ExamDepartment>,
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ExamSurvey = {
   __typename?: 'ExamSurvey',
  id: Scalars['ID'],
  questions: ExamSurveyQuestionConnection,
  test: ExamTest,
};

export type ExamSurveyAnswer = {
   __typename?: 'ExamSurveyAnswer',
  content: Scalars['String'],
  id: Scalars['ID'],
  question: ExamSurveyQuestion,
  seq: Scalars['Int'],
  viewerHasSelect: Scalars['Boolean'],
  viewerNumericAnswer?: Maybe<Scalars['Int']>,
};

export type ExamSurveyAnswerAverageNumeric = {
   __typename?: 'ExamSurveyAnswerAverageNumeric',
  averageNumeric: Scalars['Float'],
  content: Scalars['String'],
  id: Scalars['ID'],
  question: ExamSurveyQuestion,
  seq: Scalars['Int'],
  viewerHasSelect: Scalars['Boolean'],
  viewerNumericAnswer?: Maybe<Scalars['Int']>,
};

export type ExamSurveyAnswerAverageNumericConnection = {
   __typename?: 'ExamSurveyAnswerAverageNumericConnection',
  nodes: Array<ExamSurveyAnswerAverageNumeric>,
  totalCount: Scalars['Int'],
};

export type ExamSurveyAnswerAverageNumericStatistic = ExamSurveyAnswerStatistic & {
   __typename?: 'ExamSurveyAnswerAverageNumericStatistic',
  answerAverageNumerics: ExamSurveyAnswerAverageNumericConnection,
  type: ExamSurveyAnswerStatisticType,
};

export type ExamSurveyAnswerAverageSeqStatistic = ExamSurveyAnswerStatistic & {
   __typename?: 'ExamSurveyAnswerAverageSeqStatistic',
  score: Scalars['Float'],
  type: ExamSurveyAnswerStatisticType,
};

export type ExamSurveyAnswerAverageWithoutLastSeqStatistic = ExamSurveyAnswerStatistic & {
   __typename?: 'ExamSurveyAnswerAverageWithoutLastSeqStatistic',
  score: Scalars['Float'],
  type: ExamSurveyAnswerStatisticType,
};

export type ExamSurveyAnswerConnection = {
   __typename?: 'ExamSurveyAnswerConnection',
  nodes: Array<ExamSurveyAnswer>,
  totalCount: Scalars['Int'],
};

export type ExamSurveyAnswerStatistic = {
  type: ExamSurveyAnswerStatisticType,
};

export enum ExamSurveyAnswerStatisticType {
  AVERAGE_NUMERIC = 'AVERAGE_NUMERIC',
  AVERAGE_SEQ = 'AVERAGE_SEQ',
  AVERAGE_WITHOUT_LAST_SEQ = 'AVERAGE_WITHOUT_LAST_SEQ',
  TURNOUT = 'TURNOUT'
}

export type ExamSurveyAnswerTurnout = {
   __typename?: 'ExamSurveyAnswerTurnout',
  content: Scalars['String'],
  id: Scalars['ID'],
  isMaxTurnout: Scalars['Boolean'],
  question: ExamSurveyQuestion,
  seq: Scalars['Int'],
  turnout: Scalars['Float'],
  viewerHasSelect: Scalars['Boolean'],
  viewerNumericAnswer?: Maybe<Scalars['Int']>,
};

export type ExamSurveyAnswerTurnoutConnection = {
   __typename?: 'ExamSurveyAnswerTurnoutConnection',
  nodes: Array<ExamSurveyAnswerTurnout>,
  totalCount: Scalars['Int'],
};

export type ExamSurveyAnswerTurnoutStatistic = ExamSurveyAnswerStatistic & {
   __typename?: 'ExamSurveyAnswerTurnoutStatistic',
  answerTurnouts: ExamSurveyAnswerTurnoutConnection,
  type: ExamSurveyAnswerStatisticType,
};

export type ExamSurveyQuestion = {
   __typename?: 'ExamSurveyQuestion',
  answer: ExamSurveyAnswerConnection,
  answerStatistic: ExamSurveyAnswerStatistic,
  content: Scalars['String'],
  id: Scalars['ID'],
  isMultiSelectAvailable: Scalars['Boolean'],
  isNumericAnswer: Scalars['Boolean'],
  maxSelectCount?: Maybe<Scalars['Int']>,
  seq: Scalars['Int'],
  survey: ExamSurvey,
};

export type ExamSurveyQuestionConnection = {
   __typename?: 'ExamSurveyQuestionConnection',
  nodes: Array<ExamSurveyQuestion>,
  totalCount: Scalars['Int'],
};

export type ExamTest = {
   __typename?: 'ExamTest',
  company: ExamCompany,
  createdAt: Scalars['Date'],
  date: Scalars['Date'],
  gradingQuestions: Array<ExamGradingQuestion>,
  id: Scalars['ID'],
  name: Scalars['String'],
};


export type ExamTestGradingQuestionsArgs = {
  subject: ExamSubject
};

export type ExamTestConnection = {
   __typename?: 'ExamTestConnection',
  nodes: Array<ExamTest>,
  totalCount: Scalars['Int'],
};

export type ExamTestSettingFilters = {
  companyId?: Maybe<Scalars['ID']>,
  dateRange?: Maybe<DateRange>,
  name?: Maybe<Scalars['String']>,
};

export enum ExamTestSettingOrderField {
  CREATED_AT = 'CREATED_AT',
  DATE = 'DATE'
}

export type ExamTestSettingOrders = {
  direction: OrderDirection,
  field: ExamTestSettingOrderField,
};

export type ExternalCareerExperienceInfo = {
   __typename?: 'ExternalCareerExperienceInfo',
  endYearMonth?: Maybe<Scalars['Int']>,
  organizationName: Scalars['String'],
  startYearMonth: Scalars['Int'],
};

export type FacebookAccount = {
   __typename?: 'FacebookAccount',
  id: Scalars['ID'],
};

export type FacebookDeleteUserInput = {
  facebookID: Scalars['ID'],
  sessionToken: Scalars['String'],
};

export type FacebookDeleteUserPayload = {
   __typename?: 'FacebookDeleteUserPayload',
  isFacebookUserDelete?: Maybe<Scalars['Boolean']>,
  isTimespreadUserDelete?: Maybe<Scalars['Boolean']>,
};

export type FacebookFakeLoginInput = {
  email?: Maybe<Scalars['String']>,
  nickname?: Maybe<Scalars['String']>,
};

export type FacebookFakeLoginPayload = {
   __typename?: 'FacebookFakeLoginPayload',
  signupSession?: Maybe<SignupSession>,
  user?: Maybe<User>,
};

export type FacebookFakeSignupPayload = {
   __typename?: 'FacebookFakeSignupPayload',
  email?: Maybe<Scalars['String']>,
  facebookID?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  sessionToken?: Maybe<Scalars['String']>,
};

export type FacebookUpdateUserInput = {
  facebookID: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
};

export type FamousActivityListInput = {
  activityID?: Maybe<Scalars['ID']>,
  activityTypeID?: Maybe<Scalars['Int']>,
  activityTypeIDs?: Maybe<Array<Scalars['Int']>>,
  categoryIDs: Array<Maybe<Scalars['Int']>>,
  interestIDs: Array<Maybe<Scalars['Int']>>,
  limit: Scalars['Int'],
  withInWeeks?: Maybe<Scalars['Int']>,
};

export type FamousActivityListPayload = {
   __typename?: 'FamousActivityListPayload',
  activities?: Maybe<Array<Activity>>,
};

export type FetchNhis2WayIdentifyInput = {
  birthDate: Scalars['String'],
  id: Scalars['String'],
  jobIndex: Scalars['Int'],
  loginTypeLevel: NhisLoginTypeLevel,
  phoneNumber: Scalars['String'],
  telecom: NhisTelecom,
  threadIndex: Scalars['Int'],
  transactionId: Scalars['String'],
  twoWayTimestamp: Scalars['String'],
  userName: Scalars['String'],
};

export type FetchNhis2WayIdentifyPayload = {
   __typename?: 'FetchNhis2WayIdentifyPayload',
  externalCareerExperienceInfos: Array<ExternalCareerExperienceInfo>,
  issueDate: Scalars['Date'],
  totalCareerExperienceMonth: Scalars['Int'],
};

export type FetchNhisIdentifyInput = {
  birthDate: Scalars['String'],
  loginTypeLevel: NhisLoginTypeLevel,
  phoneNumber: Scalars['String'],
  telecom: NhisTelecom,
  userName: Scalars['String'],
};

export type FetchNhisIdentifyPayload = {
   __typename?: 'FetchNhisIdentifyPayload',
  id: Scalars['String'],
  jobIndex: Scalars['Int'],
  threadIndex: Scalars['Int'],
  transactionId: Scalars['String'],
  twoWayTimestamp: Scalars['String'],
};

export type FindWithPeriodAndCategoryInput = {
  page?: Maybe<Scalars['Int']>,
  pageSize: Scalars['Int'],
  from: Scalars['Date'],
  to: Scalars['Date'],
  datePeriod?: Maybe<DatePeriod>,
  chatRoomType?: Maybe<ChatRoomType>,
};

export type FindWithPeriodInput = {
  page?: Maybe<Scalars['Int']>,
  pageSize: Scalars['Int'],
  from: Scalars['Date'],
  to: Scalars['Date'],
  datePeriod?: Maybe<DatePeriod>,
};

export type FindWithRangeInput = {
  page?: Maybe<Scalars['Int']>,
  pageSize: Scalars['Int'],
  from: Scalars['Date'],
  to: Scalars['Date'],
};

export type FixChatRoomOnTopInput = {
  chatRoomId: Scalars['ID'],
  fixOnTop: Scalars['Boolean'],
};

export type FixChatRoomPayload = {
   __typename?: 'FixChatRoomPayload',
  chatRoom: ChatRoom,
};

export type Folder = {
   __typename?: 'Folder',
  createdAt?: Maybe<Scalars['Date']>,
  files: SelfIntroductionConnection,
  folders: FolderConnection,
  id: Scalars['ID'],
  name: Scalars['String'],
};


export type FolderFilesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type FolderFoldersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<FolderOrder>
};

export type FolderConnection = {
   __typename?: 'FolderConnection',
  nodes: Array<Maybe<Folder>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type FolderOrder = {
  direction: OrderDirection,
  field: FolderOrderField,
};

export enum FolderOrderField {
  CREATED_AT = 'CREATED_AT'
}

export type FollowerConnection = {
   __typename?: 'FollowerConnection',
  edges: Array<FollowerEdge>,
  nodes: Array<Maybe<Actor>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type FollowerEdge = {
   __typename?: 'FollowerEdge',
  cursor: Scalars['String'],
  followedAt?: Maybe<Scalars['Date']>,
  node?: Maybe<Actor>,
};

export type FollowerFilter = {
  followedAt?: Maybe<DateRange>,
};

export type FollowingConnection = {
   __typename?: 'FollowingConnection',
  edges: Array<FollowingEdge>,
  nodes: Array<Maybe<Channel>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type FollowingEdge = {
   __typename?: 'FollowingEdge',
  cursor: Scalars['String'],
  followedAt?: Maybe<Scalars['Date']>,
  node?: Maybe<Channel>,
};

export type FollowingFilter = {
  followedAt?: Maybe<DateRange>,
};

export type FollowPayload = {
   __typename?: 'FollowPayload',
  channel?: Maybe<Channel>,
};

export type FollowToChannelNotification = SimpleNotification & {
   __typename?: 'FollowToChannelNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  follower?: Maybe<Actor>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export type GenerateCode = {
  phoneNumber: Scalars['String'],
  token?: Maybe<Scalars['String']>,
};

export type GenerateCodePayload = {
   __typename?: 'GenerateCodePayload',
  token: Scalars['String'],
};

export type Highschool = {
   __typename?: 'Highschool',
  count: Scalars['Int'],
  district?: Maybe<RegionDistrict>,
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  name: Scalars['String'],
  region?: Maybe<Region>,
};

export enum HighschoolCategory {
  ART_AND_PHYSICS = 'ART_AND_PHYSICS',
  LIBERAL_ARTS = 'LIBERAL_ARTS',
  SCIENCE = 'SCIENCE',
  VOCATIONAL = 'VOCATIONAL'
}

export type HighschoolProfile = {
   __typename?: 'HighschoolProfile',
  category?: Maybe<HighschoolCategory>,
  graduatedAt: Scalars['Date'],
  highschool: Highschool,
  id: Scalars['ID'],
  startedAt?: Maybe<Scalars['Date']>,
  status: SchoolStatus,
};

export type IncrementShareCountPayload = {
   __typename?: 'IncrementShareCountPayload',
  newsItem?: Maybe<NewsItem>,
};

export type Industry = {
   __typename?: 'Industry',
  children: Array<Industry>,
  id: Scalars['ID'],
  name: Scalars['String'],
  parent?: Maybe<Industry>,
  seq: Scalars['Int'],
};

export type Interest = {
   __typename?: 'Interest',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
  users?: Maybe<Array<Maybe<User>>>,
};

export type InternFilterCollection = {
   __typename?: 'InternFilterCollection',
  categories: Array<Category>,
  internTypes: Array<ActivityInternType>,
  organizationTypes: Array<OrganizationType>,
  regions: Array<Region>,
};

export type IntroductionLetter = {
   __typename?: 'IntroductionLetter',
  content: Scalars['String'],
  id: Scalars['ID'],
  reference?: Maybe<IntroductionLetterReference>,
  type: IntroductionLetterType,
};

export type IntroductionLetterReference = {
   __typename?: 'IntroductionLetterReference',
  id: Scalars['ID'],
  introductionLetter?: Maybe<IntroductionLetter>,
  isFreeform: Scalars['Boolean'],
  resume: Resume,
};

export type IntroductionLetterType = {
   __typename?: 'IntroductionLetterType',
  id: Scalars['ID'],
  isFreeform: Scalars['Boolean'],
  type: Scalars['String'],
};

export type JobCategory = {
   __typename?: 'JobCategory',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type JobcategoryCount = {
   __typename?: 'JobcategoryCount',
  jobCategoryId: Scalars['ID'],
  jobCategoryName: Scalars['String'],
  userCount: Scalars['Int'],
};

export type JobCurationActivity = {
   __typename?: 'JobCurationActivity',
  activity?: Maybe<Activity>,
  activityId: Scalars['ID'],
  endDate?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  seq: Scalars['Int'],
  startDate: Scalars['Date'],
  status: ProcessStatus,
  type: JobCurationActivityType,
  updatedAt: Scalars['Date'],
};

export type JobCurationActivityConnection = {
   __typename?: 'JobCurationActivityConnection',
  nodes: Array<JobCurationActivity>,
  totalCount: Scalars['Int'],
};

export type JobCurationActivityFilters = {
  dateRange?: Maybe<DateRange>,
  status: ProcessStatus,
  title?: Maybe<Scalars['String']>,
  type: JobCurationActivityType,
};

export enum JobCurationActivityRecruitType {
  CONTRACT = 'CONTRACT',
  EXPERIENCED = 'EXPERIENCED',
  INTERN = 'INTERN',
  NEW = 'NEW'
}

export enum JobCurationActivityType {
  INTERN = 'INTERN',
  MAJOR_COMPANY = 'MAJOR_COMPANY',
  NEW = 'NEW',
  POPULAR = 'POPULAR',
  PUBLIC_INSTITUTIONS = 'PUBLIC_INSTITUTIONS',
  RECOMMENDED = 'RECOMMENDED'
}

export type JobCurationCenterBanner = {
   __typename?: 'JobCurationCenterBanner',
  endDate?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  landingUrl: Scalars['String'],
  mobileImage: Attachment,
  pcImage: Attachment,
  seq: Scalars['Int'],
  startDate: Scalars['Date'],
  status: ProcessStatus,
  title: Scalars['String'],
};

export type JobCurationCenterBannerConnection = {
   __typename?: 'JobCurationCenterBannerConnection',
  nodes: Array<JobCurationCenterBanner>,
  totalCount: Scalars['Int'],
};

export type JobCurationCenterBannerSettingsFilters = {
  dateRange?: Maybe<DateRange>,
  status: ProcessStatus,
  title?: Maybe<Scalars['String']>,
};

export enum JobCurationCenterBannerSettingsOrderField {
  END_DATE = 'END_DATE',
  START_DATE = 'START_DATE'
}

export type JobCurationCenterBannerSettingsOrders = {
  direction: OrderDirection,
  field: JobCurationCenterBannerSettingsOrderField,
};

export type JobCurationMentorBanner = {
   __typename?: 'JobCurationMentorBanner',
  id: Scalars['ID'],
  landingUrl: Scalars['String'],
  pcImage?: Maybe<Attachment>,
  title: Scalars['String'],
  updatedAt: Scalars['Date'],
};

export type JobCurationOrganizationContent = {
   __typename?: 'JobCurationOrganizationContent',
  id: Scalars['ID'],
  image?: Maybe<Attachment>,
  landingUrl: Scalars['String'],
  seq: Scalars['Int'],
  title: Scalars['String'],
  updatedAt: Scalars['Date'],
};

export type JobCurationOrganizationContentConnection = {
   __typename?: 'JobCurationOrganizationContentConnection',
  nodes: Array<JobCurationOrganizationContent>,
  totalCount: Scalars['Int'],
};

export type JobCurationPost = {
   __typename?: 'JobCurationPost',
  commentCount: Scalars['Int'],
  id: Scalars['ID'],
  landingUrl?: Maybe<Scalars['String']>,
  postId: Scalars['ID'],
  seq: Scalars['Int'],
  title: Scalars['String'],
  updatedAt: Scalars['Date'],
};

export type JobCurationPostConnection = {
   __typename?: 'JobCurationPostConnection',
  nodes: Array<JobCurationPost>,
  totalCount: Scalars['Int'],
};

export type JobPosition = {
   __typename?: 'JobPosition',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type JobRank = {
   __typename?: 'JobRank',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export enum JobType {
  CONTRACT = 'CONTRACT',
  EXPERIENCED = 'EXPERIENCED',
  INTERN = 'INTERN',
  NEW = 'NEW'
}

export type JoinChatRoomPayload = {
   __typename?: 'JoinChatRoomPayload',
  chatRoom: ChatRoom,
  user: User,
};

export type KakaoAccount = {
   __typename?: 'KakaoAccount',
  id: Scalars['ID'],
};

export type KpiChat = {
   __typename?: 'KpiChat',
  id: Scalars['ID'],
  date: Scalars['String'],
  activeChatMessageSenderCount: Scalars['Int'],
  chatMessageCount: Scalars['Int'],
  activeChatRoomCount: Scalars['Int'],
};

export type KpiChatOffsetConnection = {
   __typename?: 'KpiChatOffsetConnection',
  nodes: Array<KpiChat>,
  totalCount: Scalars['Int'],
};

export type KpiChatRoom = {
   __typename?: 'KpiChatRoom',
  id: Scalars['ID'],
  date: Scalars['String'],
  senderCount: Scalars['Int'],
  memberCount: Scalars['Int'],
  joinedMemberCount: Scalars['Int'],
  leftUserCount: Scalars['Int'],
  shareCount: Scalars['Int'],
  newChatMessageCount: Scalars['Int'],
  chatRoom?: Maybe<ChatRoom>,
};

export type KpiChatRoomOffsetConnection = {
   __typename?: 'KpiChatRoomOffsetConnection',
  nodes: Array<KpiChatRoom>,
  totalCount: Scalars['Int'],
};

export type Language = {
   __typename?: 'Language',
  id: Scalars['ID'],
  language: Scalars['String'],
};

export type LeaveChatRoomPayload = {
   __typename?: 'LeaveChatRoomPayload',
  user: User,
};

export type LikeChatMessagePayload = {
   __typename?: 'LikeChatMessagePayload',
  chatMessage: ChatMessage,
};

export type LikedConnection = {
   __typename?: 'LikedConnection',
  edges: Array<LikedEdge>,
  nodes: Array<Maybe<NewsItem>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type LikedEdge = {
   __typename?: 'LikedEdge',
  cursor: Scalars['String'],
  likedAt: Scalars['Date'],
  node?: Maybe<NewsItem>,
};

export type LikerConnection = {
   __typename?: 'LikerConnection',
  edges: Array<LikerEdge>,
  nodes: Array<Maybe<Actor>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type LikerEdge = {
   __typename?: 'LikerEdge',
  cursor: Scalars['String'],
  likedAt?: Maybe<Scalars['Date']>,
  node?: Maybe<Actor>,
};

export type LikeToNewsItemNotification = SimpleNotification & {
   __typename?: 'LikeToNewsItemNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  likeCount?: Maybe<Scalars['Int']>,
  liker?: Maybe<Actor>,
  linkUrl: Scalars['String'],
  newsItem?: Maybe<NewsItem>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type LinkareerMagazine = {
   __typename?: 'LinkareerMagazine',
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  pcImage?: Maybe<Scalars['String']>,
  pcImageFile?: Maybe<Attachment>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
  url: Scalars['String'],
};

export type LinkareerMagazineListConnection = {
   __typename?: 'LinkareerMagazineListConnection',
  linkareerMagazines: Array<LinkareerMagazine>,
  totalCount: Scalars['Int'],
};

export type LinkareerMagazineUpdateInput = {
  content?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  pcImage?: Maybe<Scalars['String']>,
  pcImageId?: Maybe<Scalars['ID']>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type LinkareerMagazineUpdatePayload = {
   __typename?: 'LinkareerMagazineUpdatePayload',
  linkareerMagazine?: Maybe<LinkareerMagazine>,
};

export type LinkareerTestUserDeletePayload = {
   __typename?: 'LinkareerTestUserDeletePayload',
  count?: Maybe<Scalars['Int']>,
};

export enum ListOrderByType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum ListOrderType {
  DEAD_LINE = 'DEAD_LINE',
  RECENT = 'RECENT',
  REPLY_COUNT = 'REPLY_COUNT',
  SCRAP_COUNT = 'SCRAP_COUNT',
  VIEW_COUNT = 'VIEW_COUNT'
}

export type LoginPayload = {
   __typename?: 'LoginPayload',
  accessToken?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  refreshToken?: Maybe<Scalars['String']>,
  signupToken?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export type Major = {
   __typename?: 'Major',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type MajorCount = {
   __typename?: 'MajorCount',
  majorId: Scalars['ID'],
  majorName: Scalars['String'],
  userCount: Scalars['Int'],
};

export type MatchActivityToElasticPayload = {
   __typename?: 'MatchActivityToElasticPayload',
  activities?: Maybe<Array<Maybe<Activity>>>,
};

export type MatchedMentorCommentRewardNotification = SimpleNotification & {
   __typename?: 'MatchedMentorCommentRewardNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type MentorApprovedNotification = SimpleNotification & {
   __typename?: 'MentorApprovedNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum MilitaryRank {
  BRIGADIER_GENERAL = 'BRIGADIER_GENERAL',
  CAPTAIN = 'CAPTAIN',
  CHIEF_WARRANT_OFFICER = 'CHIEF_WARRANT_OFFICER',
  COLONEL = 'COLONEL',
  CORPORAL = 'CORPORAL',
  ETC = 'ETC',
  FIRST_LIEUTENANT = 'FIRST_LIEUTENANT',
  GENERAL = 'GENERAL',
  LIEUTENANT_COLONEL = 'LIEUTENANT_COLONEL',
  LIEUTENANT_GENERAL = 'LIEUTENANT_GENERAL',
  MAJOR = 'MAJOR',
  MAJOR_GENERAL = 'MAJOR_GENERAL',
  MASTER_SERGEANT = 'MASTER_SERGEANT',
  PRIVATE_FIRST_CLASS = 'PRIVATE_FIRST_CLASS',
  PRIVATE_SECOND_CLASS = 'PRIVATE_SECOND_CLASS',
  SECOND_LIEUTENANT = 'SECOND_LIEUTENANT',
  SERGEANT = 'SERGEANT',
  SERGEANT_FIRST_CLASS = 'SERGEANT_FIRST_CLASS',
  SERGEANT_MAJOR = 'SERGEANT_MAJOR',
  STAFF_SERGEANT = 'STAFF_SERGEANT'
}

export enum MilitaryStatus {
  CALL_OFF = 'CALL_OFF',
  DISCHARGED_DISHONORABLY = 'DISCHARGED_DISHONORABLY',
  DISCHARGED_EXPIRATION = 'DISCHARGED_EXPIRATION',
  DISCHARGED_FOR_DAMAGE_REASON = 'DISCHARGED_FOR_DAMAGE_REASON',
  DISCHARGED_FOR_FAMILY_REASON = 'DISCHARGED_FOR_FAMILY_REASON',
  DISCHARGED_FOR_MEDICAL_REASON = 'DISCHARGED_FOR_MEDICAL_REASON',
  ETC = 'ETC'
}

export enum MilitaryTarget {
  COMPLETED = 'COMPLETED',
  EXEMPTED = 'EXEMPTED',
  NOT_YET = 'NOT_YET',
  SERVING = 'SERVING'
}

export enum MilitaryType {
  AIR_FORCE = 'AIR_FORCE',
  ARMY = 'ARMY',
  AUXILIARY_POLICE = 'AUXILIARY_POLICE',
  ETC = 'ETC',
  MARINE = 'MARINE',
  NAVY = 'NAVY',
  RIOT_POLICE = 'RIOT_POLICE',
  SOCIAL_SERVICE_PERSONNEL = 'SOCIAL_SERVICE_PERSONNEL'
}

export type Mutation = {
   __typename?: 'Mutation',
  changeChatNickname: ChangeChatNicknamePayload,
  blockUserInChat: BlockUserInChatPayload,
  updateChatNotificationConfig: UserSubscription,
  changeChatProfileImage: ChangeChatProfileImagePayload,
  updateChatRoomNotificationConfig: UpdateChatRoomNotificationPayload,
  createChatRoom: CreateChatRoomPayload,
  joinChatRoom: JoinChatRoomPayload,
  leaveChatRoom: LeaveChatRoomPayload,
  shareChatRoom: ShareChatRoomPayload,
  deleteChatRoom: DeleteChatRoomPayload,
  fixChatRoomOnTop: FixChatRoomPayload,
  reorderFixedChatRoom: ReorderChatRoomsOnTopPayload,
  addChatRoomOutlink: ChatRoomOutlink,
  likeChatMessage: LikeChatMessagePayload,
  reportChatMessage: ReportChatMessagePayload,
  deleteChatMessage: DeleteChatMessagePayload,
  banUserInChat: BanUserInChatPayload,
  readReportedChatMessage: ReadReportedChatMessagePayload,
  unbanUsersInChat: UnbanUsersInChatPayload,
  activateChannel: ActivateChannelPayload,
  activitiesDelete?: Maybe<ActivitiesDeletePayload>,
  activitiesStatusUpdate?: Maybe<ActivitiesStatusUpdatePayload>,
  activityDelete?: Maybe<ActivityDeletePayload>,
  activityFileCreate?: Maybe<ActivityFile>,
  activityFileDelete?: Maybe<Scalars['Boolean']>,
  activityReferenceUpdate?: Maybe<ActivityReferenceUpdatePayload>,
  activityScrapUpdate?: Maybe<ActivityScrapUpdatePayload>,
  activityUpdate?: Maybe<ActivityUpdatePayload>,
  activityViewCountUpdate?: Maybe<ActivityViewCountUpdatePayload>,
  adPlacementCreate?: Maybe<AdPlacementCreatePayload>,
  adPlacementUpdate?: Maybe<AdPlacementUpdatePayload>,
  addBookmark: AddBookmarkPayload,
  addCommunityBookmark?: Maybe<AddCommunityBookmarkPayLoad>,
  addCommunityMenuBookmark?: Maybe<AddCommunityMenuBookmarkPayLoad>,
  addLike: AddLikePayload,
  addTypeByOrgNameRegex?: Maybe<AddTypeByOrgNameRegexPayload>,
  advertisementBulkDelete?: Maybe<AdvertisementBulkDeletePayload>,
  advertisementCreate?: Maybe<AdvertisementCreatePayload>,
  advertisementDelete?: Maybe<AdvertisementDeletePayload>,
  advertisementUpdate?: Maybe<AdvertisementUpdatePayload>,
  aggregateCareerProfileKpi: AggregateResponse,
  attachmentCompleteUpload?: Maybe<AttachmentCompleteUploadPayload>,
  attachmentDelete?: Maybe<AttachmentDeletePayload>,
  attachmentPrepareUpload?: Maybe<AttachmentPrepareUploadPayload>,
  authenticateEmail?: Maybe<AuthenticateEmailPayload>,
  authenticateEmailCallback?: Maybe<AuthenticateEmailCallbackPayload>,
  bizCheckOrganizationAliasIsDuplicated: Scalars['Boolean'],
  bizCreateActivity?: Maybe<BizCreateActivityPayload>,
  bizDeleteActivity?: Maybe<ActivityDeletePayload>,
  bizFindLogin: Array<BizFindLoginPayload>,
  bizHideActivity?: Maybe<Activity>,
  bizLogin?: Maybe<LoginPayload>,
  bizResetPassword: BizResetPasswordPayload,
  bizShowActivity?: Maybe<Activity>,
  bizSignup?: Maybe<UserSignupPayload>,
  bizUpdateActivity?: Maybe<BizUpdateActivityPayload>,
  bizUpdateRecruit?: Maybe<BizUpdateRecruitPayload>,
  bizUpdateRecruitCloseAt?: Maybe<BizUpdateRecruitCloseAtPayload>,
  bizUpdateUser: BizUpdateUserPayload,
  bizVerifyLogin: BizVerifyLoginPayload,
  campaignCreate?: Maybe<CampaignCreatePayload>,
  campaignDelete?: Maybe<CampaignDeletePayload>,
  campaignUpdate?: Maybe<CampaignUpdatePayload>,
  checkSmsAuthCode: GenerateCodePayload,
  clearCareerProfileKpi: ClearResponse,
  completeChannel?: Maybe<Channel>,
  completeQuestionSuggestion: CompleteQuestionSuggestionPayload,
  completeViewerChannel?: Maybe<Channel>,
  coverLetterBulkCreate?: Maybe<Array<Maybe<CoverLetter>>>,
  coverLetterCreate?: Maybe<CoverLetterCreatePayload>,
  coverLetterDeleteMany?: Maybe<CoverLetterDeleteManyPayload>,
  coverLetterManyAddType?: Maybe<CoverLetterManyAddTypePayload>,
  coverLetterParagraphScrapCreate?: Maybe<CoverLetterParagraphScrapCreatePayload>,
  coverLetterParagraphScrapDelete?: Maybe<CoverLetterParagraphScrapDeletePayload>,
  coverLetterScrapUpdate?: Maybe<CoverLetterScrapUpdatePayload>,
  coverLetterUpdate?: Maybe<CoverLetterUpdatePayload>,
  createAdPlacement: CreateAdPlacementPayload,
  createAddOrganizationSuggestion: CreateAddOrganizationSuggestionPayload,
  createCampaign: CreateCampaignPayload,
  createCampaignGroup: CreateCampaignGroupPayloadDto,
  createChannel: CreateChannelPayload,
  createChannelTagCollection?: Maybe<CreateTagCollectionPayload>,
  createExamGradingResult: Scalars['Boolean'],
  createExamQuestionKeyword: ExamQuestionKeyword,
  createExamReserveInformation: CreateExamReserveInformationPayload,
  createExamTest: CreateExamTestPayload,
  createFolder: CreateFolderPayload,
  createJobCurationActivity: CreateJobCurationActivityPayload,
  createJobCurationCenterBanner: CreateJobCurationCenterBannerPayload,
  createLinkareerMagazine?: Maybe<CreateLinkareerMagazinePayload>,
  createNewsComment: CreateNewsCommentPayload,
  createNewsItem: CreateNewsItemPayload,
  createNewsItemTagCollection?: Maybe<CreateTagCollectionPayload>,
  createQnA: CreateQnAPayload,
  createQuestionSuggestion: CreateQuestionSuggestionPayload,
  createRecommendSearchKeyword?: Maybe<CreateRecommendSearchKeywordPayload>,
  createSelfIntroduction: CreateSelfIntroductionPayload,
  createSuggestion: CreateSuggestionPayload,
  createTestUser?: Maybe<CreateTestUserPayload>,
  createUniqueNameOrganizations: CreateUniqueNameOrganizationsPayload,
  curationCreate?: Maybe<CurationCreatePayload>,
  curationDelete?: Maybe<CurationDeletePayload>,
  curationUpdate?: Maybe<CurationUpdatePayload>,
  currentUserScrapDeleteCommunity?: Maybe<CurrentUserScrapDeleteCommunityPayload>,
  currentUserScrapsDeleteAllCommunity: CurrentUserScrapsDeleteAllCommunityPayload,
  deactivateChannel: DeactivateChannelPayload,
  deleteActivityManagerPreset: DeleteActivityManagerPresetPayload,
  deleteActivityOrganizationPreset: DeleteActivityOrganizationPresetPayload,
  deleteAllNotification: DeleteAllNotificationPayload,
  deleteCampaignGroups: DeleteCampaignGroupsPayloadDto,
  deleteCampaigns: DeleteCampaignsPayload,
  deleteChannel: DeleteChannelPayload,
  deleteCurrentUser: DeleteCurrentUserPayload,
  deleteExamQuestionKeyword: Scalars['Boolean'],
  deleteExamTests: Scalars['Boolean'],
  deleteFolder: DeleteFolderPayload,
  deleteJobCurationActivities: Scalars['Boolean'],
  deleteJobCurationCenterBanners: Scalars['Boolean'],
  deleteManyUserAccounts: DeleteManyUserAccountsPayload,
  deleteNewsComment: NewsComment,
  deleteNewsComments: DeleteNewsCommentsPayload,
  deleteNewsItem: NewsItem,
  deleteNewsItems: DeleteNewsItemsPayload,
  deleteNotification: DeleteNotificationPayload,
  deleteQnA: DeleteQnAPayload,
  deleteQuestionSuggestion: DeleteQuestionSuggestionPayload,
  deleteRecommendSearchKeyword?: Maybe<DeleteRecommendSearchKeywordPayload>,
  deleteSelfIntroduction: DeleteSelfIntroductionPayload,
  deleteSnapshot: DeleteQnASnapshotPayload,
  deleteTagCollection?: Maybe<DeleteTagCollectionPayload>,
  deleteUniqueNameOrganizations: DeleteUniqueNameOrganizationsPayload,
  deleteUserAccount: DeleteUserAccountPayload,
  facebookDeleteUser: FacebookDeleteUserPayload,
  facebookFakeLogin: FacebookFakeLoginPayload,
  facebookFakeSignup: FacebookFakeSignupPayload,
  facebookUpdateUser: Scalars['Boolean'],
  fetchNhis2WayIdentify: FetchNhis2WayIdentifyPayload,
  fetchNhisIdentify: FetchNhisIdentifyPayload,
  follow: FollowPayload,
  generateSmsAuthCode: GenerateCodePayload,
  generateUserStatistic?: Maybe<Scalars['Boolean']>,
  incrementShareCount: IncrementShareCountPayload,
  invalidateActivityCache?: Maybe<Activity>,
  issueSignedURL?: Maybe<Scalars['String']>,
  linkareerMagazineUpdate?: Maybe<LinkareerMagazineUpdatePayload>,
  linkareerTestUserDelete: LinkareerTestUserDeletePayload,
  login?: Maybe<LoginPayload>,
  loginByAdmin?: Maybe<LoginPayload>,
  loginWithApple?: Maybe<LoginPayload>,
  loginWithFaceBook?: Maybe<LoginPayload>,
  loginWithKakao?: Maybe<LoginPayload>,
  loginWithNaver?: Maybe<LoginPayload>,
  loginWithTimespread?: Maybe<LoginPayload>,
  logout?: Maybe<Scalars['Boolean']>,
  matchActivityToElastic?: Maybe<MatchActivityToElasticPayload>,
  muteUser?: Maybe<MuteUserPayload>,
  notificationDelete?: Maybe<NotificationDeletePayload>,
  notificationDeleteAll?: Maybe<NotificationDeleteAllPayload>,
  notificationUpdate?: Maybe<NotificationUpdatePayload>,
  organizationCreate?: Maybe<OrganizationCreatePayload>,
  organizationDelete: OrganizationDeletePayload,
  organizationUpdate: OrganizationUpdatePayload,
  organizationsDelete: OrganizationsDeletePayload,
  readNewsItem: UpdateNewsItemViewCountPayload,
  readNotification: ReadNotificationPayload,
  recommendSearchKeywordCreate?: Maybe<RecommendSearchKeywordCreatePayload>,
  recommendSearchKeywordDelete?: Maybe<RecommendSearchKeywordDeletePayload>,
  recommendSearchKeywordUpdate?: Maybe<RecommendSearchKeywordUpdatePayload>,
  recoverNewsComments: RecoverNewsCommentsPayload,
  recoverNewsItems: RecoverNewsItemsPayload,
  refreshAccessToken?: Maybe<RefreshAccessTokenPayload>,
  removeAllBookmark: RemoveAllBookmarkPayload,
  removeBookmark: RemoveBookmarkPayload,
  removeBookmarks: RemoveBookmarksPayload,
  removeCommunityBookmark?: Maybe<RemoveCommunityBookmarkPayLoad>,
  removeCommunityMenuBookmark?: Maybe<RemoveCommunityMenuBookmarkPayLoad>,
  removeLike: RemoveLikePayload,
  replyCreate?: Maybe<ReplyCreatePayload>,
  replyDelete?: Maybe<ReplyDeletePayload>,
  replyDeleteByAdministrator?: Maybe<ReplyDeletePayload>,
  replyLike?: Maybe<ReplyLikePayload>,
  reportNewsComment: ReportPayload,
  reportNewsItem: ReportPayload,
  rollbackSnapshot: RollbackQnASnapshotPayload,
  saveFcmToken?: Maybe<SaveFcmTokenPayload>,
  selectExamRestoredAnswer: SelectExamRestoredAnswerPayload,
  sendJobPostingDataToNaver?: Maybe<SendJobPostingDataToNaverPayload>,
  sendMailSelfIntroduction: SendMailSelfIntroductionPayload,
  setNewsItemAsNotice: SetNewsItemAsNoticePayload,
  setNoticeAsNewsItem: SetNoticeAsNewsItemPayload,
  signupWithOAuth?: Maybe<UserSignupPayload>,
  skipSignupSession: SkipSignupSessionPayload,
  spellCheck: SpellCheckPayload,
  spellCheckWithAutoSplit: SpellCheckWithAutoSplitPayload,
  submitExamSurvey: SubmitExamSurveyPayload,
  syncCoverLetterWithElastic: Scalars['Int'],
  unFollow: UnFollowPayload,
  unmuteUser?: Maybe<UnmuteUserPayload>,
  updateAdPlacement: UpdateAdPlacementPayload,
  updateCampaign: UpdateCampaignPayload,
  updateCampaignGroup: UpdateCampaignGroupPayloadDto,
  updateCareerAdvantageCondition: UpdateCareerAdvantageConditionPayload,
  updateCareerAward: UpdateCareerAwardPayload,
  updateCareerExperience: UpdateCareerExperiencePayload,
  updateCareerExternship: UpdateCareerExternshipPayload,
  updateCareerIntroduction: UpdateCareerIntroductionPayload,
  updateCareerJobPreference: UpdateCareerJobPreferencePayload,
  updateCareerLanguage: UpdateCareerLanguagePayload,
  updateCareerLicense: UpdateCareerLicensePayload,
  updateCareerPortfolio: UpdateCareerPortfolioPayload,
  updateCareerProfile: UpdateCareerProfilePayload,
  updateCareerProfileOrder: UpdateCareerProfileOrderPayload,
  updateCareerSchool: UpdateCareerSchoolPayload,
  updateCareerSkill: UpdateCareerSkillPayload,
  updateCareerUserInformation: UpdateCareerUserInformationPayload,
  updateChannelAndOrganization: UpdateChannelAndOrganizationPayload,
  updateChannelTagCollections?: Maybe<UpdateTagCollectionsPayload>,
  updateCommunityMentorPost: Scalars['Boolean'],
  updateCoverLetterFieldByQuery?: Maybe<UpdateCoverLetterFieldByQueryPayload>,
  updateCurrentUser: UpdateCurrentUserPayload,
  updateDetailedActivity: UpdateDetailedActivityPayload,
  updateDetailedRecruit: UpdateDetailedRecruitPayload,
  updateExamReserveInformation: UpdateExamReserveInformationPayload,
  updateExamTest: UpdateExamTestPayload,
  updateFolder: UpdateFolderPayload,
  updateJobCurationActivity: UpdateJobCurationActivityPayload,
  updateJobCurationCenterBanner: UpdateJobCurationCenterBannerPayload,
  updateJobCurationMentorBanner: UpdateJobCurationMentorBannerPayload,
  updateJobCurationOrganizationContents: UpdateJobCurationOrganizationContentsPayload,
  updateJobCurationPosts: UpdateJobCurationPostsPayload,
  updateManyCommunityBoardUrls: UpdateManyCommunityBoardUrlPayload,
  updateManyCommunityPostRelatedWithActivity: UpdateManyCommunityPostRelatedWithActivityPayload,
  updateManyCoverLetterRelatedWithActivity: UpdateManyCoverLetterRelatedWithActivityPayload,
  updateNewsComment: UpdateNewsCommentPayload,
  updateNewsItem: UpdateNewsItemPayload,
  updateNewsItemTagCollections?: Maybe<UpdateTagCollectionsPayload>,
  updateQnA: UpdateQnAPayload,
  updateRecommendSearchKeyword?: Maybe<UpdateRecommendSearchKeywordPayload>,
  updateRecommendSearchKeywords?: Maybe<UpdateRecommendSearchKeywordsPayload>,
  updateRecruit?: Maybe<UpdateRecruitPayload>,
  updateSelfIntroduction: UpdateSelfIntroductionPayload,
  updateSubscription?: Maybe<UpdateSubscriptionPayload>,
  updateTagCollection?: Maybe<UpdateTagCollectionPayload>,
  updateTestUser?: Maybe<UpdateTestUserPayload>,
  updateUserInitialInfo?: Maybe<UpdateUserInitialInformationPayload>,
  updateUserPhoneNumberByAuthUserId?: Maybe<UpdateUserPhoneNumberByAuthUserIdPayload>,
  upsertCareerProfileKpi: AggregateResponse,
  upsertManyLinkareerMagazine: UpsertManyLinkareerMagazinePayload,
  userAuthorizePhoneNumber?: Maybe<UserAuthorizePhoneNumberPayload>,
  userCheckPhoneNumberAuthorization?: Maybe<UserCheckPhoneNumberAuthorizationPayload>,
  userDeactivate: UserDeactivatePayload,
  userDelete: UserDeletePayload,
  userDeleteMany: UserDeleteManyPayload,
  userIsDuplicateLogin?: Maybe<Scalars['Boolean']>,
  userIsDuplicateNickname?: Maybe<Scalars['Boolean']>,
  userIsDuplicateNicknameWithoutToken?: Maybe<Scalars['Boolean']>,
  userProfileImageDelete?: Maybe<ApplyFile>,
  userScrapDeleteAll?: Maybe<UserScrapDeleteAllPayload>,
  userSignupWithEmail: UserSignupPayload,
  userSignupWithOAuth: UserSignupPayload,
  userUnlinkTimespread: UserUnlinkTimespreadPayload,
  userUpdate: UserUpdatePayload,
  userUpdateBirth?: Maybe<Scalars['Boolean']>,
  userUpdatePhoneNumberAuthorization?: Maybe<TimeSpreadApiResponseType>,
  verifyChannelName: Scalars['Boolean'],
  verifyChannelUrl: Scalars['Boolean'],
  viewerCheckedParagraphScrapGuide: ViewerCheckedParagraphScrapGuidePayload,
  visitChannel: UpdateChannelViewCountPayload,
};


export type MutationChangeChatNicknameArgs = {
  input: ChangeChatNicknameInput
};


export type MutationBlockUserInChatArgs = {
  userId: Scalars['ID']
};


export type MutationUpdateChatNotificationConfigArgs = {
  input: UpdateChatSubscribeInput
};


export type MutationChangeChatProfileImageArgs = {
  input: ChangeChatProfileImageInput
};


export type MutationUpdateChatRoomNotificationConfigArgs = {
  input: UpdateChatRoomNotificationInput
};


export type MutationCreateChatRoomArgs = {
  input: CreateChatRoomInput
};


export type MutationJoinChatRoomArgs = {
  chatRoomId: Scalars['ID']
};


export type MutationLeaveChatRoomArgs = {
  chatRoomId: Scalars['ID']
};


export type MutationShareChatRoomArgs = {
  chatRoomId: Scalars['ID'],
  input: ShareChatRoomInput
};


export type MutationDeleteChatRoomArgs = {
  chatRoomId: Scalars['ID']
};


export type MutationFixChatRoomOnTopArgs = {
  fixChatRoomInput: FixChatRoomOnTopInput
};


export type MutationReorderFixedChatRoomArgs = {
  fixChatRoomInput: ReorderChatRoomsOnTopInput
};


export type MutationAddChatRoomOutlinkArgs = {
  chatRoomId: Scalars['Float'],
  url: Scalars['String']
};


export type MutationLikeChatMessageArgs = {
  chatMessageId: Scalars['ID']
};


export type MutationReportChatMessageArgs = {
  chatMessageId: Scalars['ID'],
  input: ReportChatMessageInput
};


export type MutationDeleteChatMessageArgs = {
  chatMessageId: Scalars['ID']
};


export type MutationBanUserInChatArgs = {
  userId: Scalars['ID'],
  input: BanUserInChatInput
};


export type MutationReadReportedChatMessageArgs = {
  chatMessageId: Scalars['ID']
};


export type MutationUnbanUsersInChatArgs = {
  banIds: Array<Scalars['ID']>
};


export type MutationActivateChannelArgs = {
  channelId: Scalars['ID']
};


export type MutationActivitiesDeleteArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationActivitiesStatusUpdateArgs = {
  input: ActivitiesStatusUpdateInput
};


export type MutationActivityDeleteArgs = {
  id: Scalars['ID']
};


export type MutationActivityFileCreateArgs = {
  input: ActivityFileCreateInput
};


export type MutationActivityFileDeleteArgs = {
  id: Scalars['ID']
};


export type MutationActivityReferenceUpdateArgs = {
  input: ActivityReferenceUpdateInput
};


export type MutationActivityScrapUpdateArgs = {
  input: ActivityScrapUpdateInput
};


export type MutationActivityUpdateArgs = {
  input: ActivityUpdateInput
};


export type MutationActivityViewCountUpdateArgs = {
  id: Scalars['ID']
};


export type MutationAdPlacementCreateArgs = {
  input: AdPlacementCreateInput
};


export type MutationAdPlacementUpdateArgs = {
  id: Scalars['ID'],
  input: AdPlacementUpdateInput
};


export type MutationAddBookmarkArgs = {
  newsItemId: Scalars['ID']
};


export type MutationAddCommunityBookmarkArgs = {
  id: Scalars['ID']
};


export type MutationAddCommunityMenuBookmarkArgs = {
  id: Scalars['ID']
};


export type MutationAddLikeArgs = {
  newsItemId: Scalars['ID']
};


export type MutationAddTypeByOrgNameRegexArgs = {
  input: AddTypeByOrgNameRegexInput
};


export type MutationAdvertisementBulkDeleteArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationAdvertisementCreateArgs = {
  input: AdvertisementCreateInput
};


export type MutationAdvertisementDeleteArgs = {
  id: Scalars['ID']
};


export type MutationAdvertisementUpdateArgs = {
  input: AdvertisementUpdateInput
};


export type MutationAttachmentCompleteUploadArgs = {
  attachmentID: Scalars['ID']
};


export type MutationAttachmentDeleteArgs = {
  attachmentID: Scalars['ID']
};


export type MutationAttachmentPrepareUploadArgs = {
  name: Scalars['String'],
  path?: Maybe<Scalars['String']>,
  size: Scalars['Int'],
  type: Scalars['String'],
  useFileName?: Maybe<Scalars['Boolean']>
};


export type MutationAuthenticateEmailArgs = {
  input: AuthenticateEmailInput
};


export type MutationAuthenticateEmailCallbackArgs = {
  input: AuthenticateEmailCallbackInput
};


export type MutationBizCheckOrganizationAliasIsDuplicatedArgs = {
  alias: Scalars['String']
};


export type MutationBizCreateActivityArgs = {
  input: BizCreateActivityInput
};


export type MutationBizDeleteActivityArgs = {
  id: Scalars['ID']
};


export type MutationBizFindLoginArgs = {
  input: BizFindLoginInput
};


export type MutationBizHideActivityArgs = {
  id: Scalars['ID']
};


export type MutationBizLoginArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationBizResetPasswordArgs = {
  input: BizResetPasswordInput
};


export type MutationBizShowActivityArgs = {
  id: Scalars['ID']
};


export type MutationBizSignupArgs = {
  input: BizSignupInput
};


export type MutationBizUpdateActivityArgs = {
  input: BizUpdateActivityInput
};


export type MutationBizUpdateRecruitArgs = {
  input: BizUpdateRecruitInput
};


export type MutationBizUpdateRecruitCloseAtArgs = {
  input: BizUpdateRecruitCloseAtInput
};


export type MutationBizUpdateUserArgs = {
  input: BizUpdateUserInput
};


export type MutationBizVerifyLoginArgs = {
  input: BizVerifyLoginInput
};


export type MutationCampaignCreateArgs = {
  input: CampaignCreateInput
};


export type MutationCampaignDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCampaignUpdateArgs = {
  id: Scalars['ID'],
  input: CampaignUpdateInput
};


export type MutationCheckSmsAuthCodeArgs = {
  input: CheckCode
};


export type MutationCompleteQuestionSuggestionArgs = {
  input: CompleteQuestionSuggestionInput
};


export type MutationCoverLetterBulkCreateArgs = {
  input: Array<CoverLetterBulkCreateInput>
};


export type MutationCoverLetterCreateArgs = {
  input: CoverLetterCreateInput
};


export type MutationCoverLetterDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationCoverLetterManyAddTypeArgs = {
  input: CoverLetterManyAddTypeInput
};


export type MutationCoverLetterParagraphScrapCreateArgs = {
  input: CoverLetterParagraphScrapCreateInput
};


export type MutationCoverLetterParagraphScrapDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCoverLetterScrapUpdateArgs = {
  input: CoverLetterScrapUpdateInput
};


export type MutationCoverLetterUpdateArgs = {
  input: CoverLetterUpdateInput
};


export type MutationCreateAdPlacementArgs = {
  input: CreateAdPlacementInput
};


export type MutationCreateAddOrganizationSuggestionArgs = {
  input: CreateAddOrganizationSuggestionInput
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput
};


export type MutationCreateCampaignGroupArgs = {
  input: CreateCampaignGroupInput
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput
};


export type MutationCreateChannelTagCollectionArgs = {
  input: CreateTagCollectionInput
};


export type MutationCreateExamGradingResultArgs = {
  input: CreateExamGradingUserResultInput
};


export type MutationCreateExamQuestionKeywordArgs = {
  keyword: Scalars['String'],
  questionId: Scalars['ID']
};


export type MutationCreateExamReserveInformationArgs = {
  input: CreateExamReserveInformationInput
};


export type MutationCreateExamTestArgs = {
  input: CreateExamTestInput
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput
};


export type MutationCreateJobCurationActivityArgs = {
  input: CreateJobCurationActivitySettingInput
};


export type MutationCreateJobCurationCenterBannerArgs = {
  input: CreateJobCurationCenterBannerInput
};


export type MutationCreateLinkareerMagazineArgs = {
  input: CreateLinkareerMagazineInput
};


export type MutationCreateNewsCommentArgs = {
  input: CreateNewsCommentInput
};


export type MutationCreateNewsItemArgs = {
  input: CreateNewsItemInput
};


export type MutationCreateNewsItemTagCollectionArgs = {
  input: CreateTagCollectionInput
};


export type MutationCreateQnAArgs = {
  input: CreateQnAInput,
  selfIntroductionId: Scalars['ID']
};


export type MutationCreateQuestionSuggestionArgs = {
  input: CreateQuestionSuggestionInput
};


export type MutationCreateRecommendSearchKeywordArgs = {
  input?: Maybe<CreateRecommendSearchKeywordInput>
};


export type MutationCreateSelfIntroductionArgs = {
  input: CreateSelfIntroductionInput
};


export type MutationCreateSuggestionArgs = {
  input: CreateSuggestionInput
};


export type MutationCreateTestUserArgs = {
  input?: Maybe<CreateTestUserInput>
};


export type MutationCreateUniqueNameOrganizationsArgs = {
  input: CreateUniqueNameOrganizationsInput
};


export type MutationCurationCreateArgs = {
  input: CurationCreateInput
};


export type MutationCurationDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCurationUpdateArgs = {
  id: Scalars['ID'],
  input: CurationUpdateInput
};


export type MutationCurrentUserScrapDeleteCommunityArgs = {
  postID: Scalars['ID']
};


export type MutationDeactivateChannelArgs = {
  channelId: Scalars['ID']
};


export type MutationDeleteActivityManagerPresetArgs = {
  presetId: Scalars['Float']
};


export type MutationDeleteActivityOrganizationPresetArgs = {
  presetId: Scalars['Float']
};


export type MutationDeleteAllNotificationArgs = {
  scope: NotificationScope
};


export type MutationDeleteCampaignGroupsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteCampaignsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteChannelArgs = {
  id: Scalars['ID']
};


export type MutationDeleteCurrentUserArgs = {
  input: DeleteCurrentUserInput
};


export type MutationDeleteExamQuestionKeywordArgs = {
  id: Scalars['ID']
};


export type MutationDeleteExamTestsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteFolderArgs = {
  input: DeleteFolderInput
};


export type MutationDeleteJobCurationActivitiesArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteJobCurationCenterBannersArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteManyUserAccountsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteNewsCommentArgs = {
  newsCommentId: Scalars['ID']
};


export type MutationDeleteNewsCommentsArgs = {
  newsCommentIds: Array<Scalars['ID']>
};


export type MutationDeleteNewsItemArgs = {
  id: Scalars['ID']
};


export type MutationDeleteNewsItemsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID']
};


export type MutationDeleteQnAArgs = {
  input: DeleteQnAInput
};


export type MutationDeleteQuestionSuggestionArgs = {
  input: DeleteQuestionSuggestionInput
};


export type MutationDeleteRecommendSearchKeywordArgs = {
  id: Scalars['ID']
};


export type MutationDeleteSelfIntroductionArgs = {
  input: DeleteSelfIntroductionInput
};


export type MutationDeleteSnapshotArgs = {
  snapshotId: Scalars['ID']
};


export type MutationDeleteTagCollectionArgs = {
  id: Scalars['ID']
};


export type MutationDeleteUniqueNameOrganizationsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteUserAccountArgs = {
  id: Scalars['ID']
};


export type MutationFacebookDeleteUserArgs = {
  input: FacebookDeleteUserInput
};


export type MutationFacebookFakeLoginArgs = {
  input?: Maybe<FacebookFakeLoginInput>
};


export type MutationFacebookUpdateUserArgs = {
  input: FacebookUpdateUserInput
};


export type MutationFetchNhis2WayIdentifyArgs = {
  input: FetchNhis2WayIdentifyInput
};


export type MutationFetchNhisIdentifyArgs = {
  input: FetchNhisIdentifyInput
};


export type MutationFollowArgs = {
  channelId: Scalars['ID']
};


export type MutationGenerateSmsAuthCodeArgs = {
  input: GenerateCode
};


export type MutationGenerateUserStatisticArgs = {
  date: Scalars['Date']
};


export type MutationIncrementShareCountArgs = {
  newsItemId: Scalars['ID'],
  platform?: Maybe<SharePlatFormType>
};


export type MutationInvalidateActivityCacheArgs = {
  id: Scalars['ID']
};


export type MutationIssueSignedUrlArgs = {
  contentType?: Maybe<Scalars['String']>
};


export type MutationLinkareerMagazineUpdateArgs = {
  input: LinkareerMagazineUpdateInput
};


export type MutationLinkareerTestUserDeleteArgs = {
  id: Scalars['ID']
};


export type MutationLoginArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationLoginByAdminArgs = {
  id: Scalars['ID']
};


export type MutationLoginWithAppleArgs = {
  accessToken: Scalars['String'],
  appleIdToken: Scalars['String'],
  displayName: Scalars['String'],
  email: Scalars['String'],
  profileURL?: Maybe<Scalars['String']>,
  snsID: Scalars['String']
};


export type MutationLoginWithFaceBookArgs = {
  accessToken: Scalars['String'],
  displayName: Scalars['String'],
  email: Scalars['String'],
  profileURL: Scalars['String'],
  snsID: Scalars['String']
};


export type MutationLoginWithKakaoArgs = {
  accessToken: Scalars['String'],
  displayName: Scalars['String'],
  email: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  profileURL: Scalars['String'],
  snsID: Scalars['String']
};


export type MutationLoginWithNaverArgs = {
  accessToken: Scalars['String'],
  displayName: Scalars['String'],
  email: Scalars['String'],
  profileURL: Scalars['String'],
  snsID: Scalars['String']
};


export type MutationLoginWithTimespreadArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationMuteUserArgs = {
  input?: Maybe<MuteUserInput>
};


export type MutationNotificationDeleteArgs = {
  input: NotificationDeleteInput
};


export type MutationNotificationUpdateArgs = {
  input: NotificationUpdateInput
};


export type MutationOrganizationCreateArgs = {
  input: OrganizationCreateInput
};


export type MutationOrganizationDeleteArgs = {
  id: Scalars['ID']
};


export type MutationOrganizationUpdateArgs = {
  input: UpdateOrganizationInput
};


export type MutationOrganizationsDeleteArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationReadNewsItemArgs = {
  newsItemId: Scalars['ID']
};


export type MutationReadNotificationArgs = {
  id: Scalars['ID']
};


export type MutationRecommendSearchKeywordCreateArgs = {
  input: RecommendSearchKeywordCreateInput
};


export type MutationRecommendSearchKeywordDeleteArgs = {
  id: Scalars['ID']
};


export type MutationRecommendSearchKeywordUpdateArgs = {
  id: Scalars['ID'],
  input: RecommendSearchKeywordUpdateInput
};


export type MutationRecoverNewsCommentsArgs = {
  newsCommentIds: Array<Scalars['ID']>
};


export type MutationRecoverNewsItemsArgs = {
  newsItemIds: Array<Scalars['ID']>
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']
};


export type MutationRemoveBookmarkArgs = {
  newsItemId: Scalars['ID']
};


export type MutationRemoveBookmarksArgs = {
  newsItemIds: Array<Scalars['ID']>
};


export type MutationRemoveCommunityBookmarkArgs = {
  id: Scalars['ID']
};


export type MutationRemoveCommunityMenuBookmarkArgs = {
  id: Scalars['ID']
};


export type MutationRemoveLikeArgs = {
  newsItemId: Scalars['ID']
};


export type MutationReplyCreateArgs = {
  input: ReplyCreateInput
};


export type MutationReplyDeleteArgs = {
  input: ReplyDeleteInput
};


export type MutationReplyDeleteByAdministratorArgs = {
  id: Scalars['ID']
};


export type MutationReplyLikeArgs = {
  input: ReplyLikeInput
};


export type MutationReportNewsCommentArgs = {
  input: ReportInput
};


export type MutationReportNewsItemArgs = {
  input: ReportInput
};


export type MutationRollbackSnapshotArgs = {
  snapshotId: Scalars['ID']
};


export type MutationSaveFcmTokenArgs = {
  input: SaveFcmTokenInput
};


export type MutationSelectExamRestoredAnswerArgs = {
  input: SelectExamRestoredAnswerInput
};


export type MutationSendMailSelfIntroductionArgs = {
  input: SendMailSelfIntroductionInput
};


export type MutationSetNewsItemAsNoticeArgs = {
  newsItemId: Scalars['ID']
};


export type MutationSetNoticeAsNewsItemArgs = {
  newsItemId: Scalars['ID']
};


export type MutationSignupWithOAuthArgs = {
  input: SignupInput
};


export type MutationSkipSignupSessionArgs = {
  input: SkipSignupSessionInput
};


export type MutationSpellCheckArgs = {
  input: SpellCheckInput
};


export type MutationSpellCheckWithAutoSplitArgs = {
  input: SpellCheckWithAutoSplitInput
};


export type MutationSubmitExamSurveyArgs = {
  input: SubmitExamSurveyInput
};


export type MutationUnFollowArgs = {
  channelId: Scalars['ID']
};


export type MutationUnmuteUserArgs = {
  input?: Maybe<UnmuteUserInput>
};


export type MutationUpdateAdPlacementArgs = {
  input: UpdateAdPlacementInput
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput
};


export type MutationUpdateCampaignGroupArgs = {
  input: UpdateCampaignGroupInputDto
};


export type MutationUpdateCareerAdvantageConditionArgs = {
  input: UpdateCareerAdvantageConditionInput
};


export type MutationUpdateCareerAwardArgs = {
  input: UpdateCareerAwardInput
};


export type MutationUpdateCareerExperienceArgs = {
  input: UpdateCareerExperienceInput
};


export type MutationUpdateCareerExternshipArgs = {
  input: UpdateCareerExternshipInput
};


export type MutationUpdateCareerIntroductionArgs = {
  input: UpdateCareerIntroductionInput
};


export type MutationUpdateCareerJobPreferenceArgs = {
  input: UpdateCareerJobPreferenceInput
};


export type MutationUpdateCareerLanguageArgs = {
  input: UpdateCareerLanguageInput
};


export type MutationUpdateCareerLicenseArgs = {
  input: UpdateCareerLicenseInput
};


export type MutationUpdateCareerPortfolioArgs = {
  input: UpdateCareerPortfolioInput
};


export type MutationUpdateCareerProfileArgs = {
  input: UpdateCareerProfileInput
};


export type MutationUpdateCareerProfileOrderArgs = {
  input: UpdateCareerProfileOrderInput
};


export type MutationUpdateCareerSchoolArgs = {
  input: UpdateCareerSchoolInput
};


export type MutationUpdateCareerSkillArgs = {
  input: UpdateCareerSkillInput
};


export type MutationUpdateCareerUserInformationArgs = {
  input: UpdateCareerUserInformationInput
};


export type MutationUpdateChannelAndOrganizationArgs = {
  channelInput: UpdateChannelInput,
  organizationInput: UpdateOrganizationInput
};


export type MutationUpdateChannelTagCollectionsArgs = {
  input: Array<UpdateTagCollectionsInput>
};


export type MutationUpdateCommunityMentorPostArgs = {
  input: UpdateCommunityMentorPostInput
};


export type MutationUpdateCoverLetterFieldByQueryArgs = {
  input: UpdateCoverLetterFieldByQueryInput
};


export type MutationUpdateCurrentUserArgs = {
  input: UpdateCurrentUserInput
};


export type MutationUpdateDetailedActivityArgs = {
  input: UpdateDetailedActivityInput
};


export type MutationUpdateDetailedRecruitArgs = {
  input: UpdateDetailedRecruitInput
};


export type MutationUpdateExamReserveInformationArgs = {
  input: UpdateExamReserveInformationInput
};


export type MutationUpdateExamTestArgs = {
  input: UpdateExamTestInput
};


export type MutationUpdateFolderArgs = {
  input: UpdateFolderInput
};


export type MutationUpdateJobCurationActivityArgs = {
  input: UpdateJobCurationActivityInput
};


export type MutationUpdateJobCurationCenterBannerArgs = {
  input: UpdateJobCurationCenterBannerInput
};


export type MutationUpdateJobCurationMentorBannerArgs = {
  input: UpdateJobCurationMentorBannerInput
};


export type MutationUpdateJobCurationOrganizationContentsArgs = {
  input: UpdateJobCurationOrganizationContentsInput
};


export type MutationUpdateJobCurationPostsArgs = {
  input: UpdateJobCurationPostsInput
};


export type MutationUpdateManyCommunityBoardUrlsArgs = {
  input: UpdateManyCommunityBoardUrlsInput
};


export type MutationUpdateManyCommunityPostRelatedWithActivityArgs = {
  input: UpdateManyCommunityPostRelatedWithActivityInput
};


export type MutationUpdateManyCoverLetterRelatedWithActivityArgs = {
  input: UpdateManyCoverLetterRelatedWithActivityInput
};


export type MutationUpdateNewsCommentArgs = {
  input: UpdateNewsCommentInput
};


export type MutationUpdateNewsItemArgs = {
  input: UpdateNewsItemInput
};


export type MutationUpdateNewsItemTagCollectionsArgs = {
  input: Array<UpdateTagCollectionsInput>
};


export type MutationUpdateQnAArgs = {
  input: UpdateQnAInput
};


export type MutationUpdateRecommendSearchKeywordArgs = {
  input: UpdateRecommendSearchKeywordInput
};


export type MutationUpdateRecommendSearchKeywordsArgs = {
  input: UpdateRecommendSearchKeywordsInput
};


export type MutationUpdateRecruitArgs = {
  input: UpdateRecruitInput
};


export type MutationUpdateSelfIntroductionArgs = {
  input: UpdateSelfIntroductionInput
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput
};


export type MutationUpdateTagCollectionArgs = {
  input: UpdateTagCollectionInput
};


export type MutationUpdateTestUserArgs = {
  id: Scalars['ID'],
  input: UpdateTestUserInput
};


export type MutationUpdateUserInitialInfoArgs = {
  input: UpdateUserInitialInformationInput
};


export type MutationUpdateUserPhoneNumberByAuthUserIdArgs = {
  input: UpdateUserPhoneNumberByAuthUserIdInput
};


export type MutationUpsertManyLinkareerMagazineArgs = {
  input: UpsertManyLinkareerMagazineInput
};


export type MutationUserAuthorizePhoneNumberArgs = {
  input: UserAuthorizePhoneNumberInput
};


export type MutationUserCheckPhoneNumberAuthorizationArgs = {
  phoneNumber: Scalars['String'],
  signupToken?: Maybe<Scalars['String']>
};


export type MutationUserDeactivateArgs = {
  userID: Scalars['ID']
};


export type MutationUserDeleteArgs = {
  id: Scalars['ID']
};


export type MutationUserDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationUserIsDuplicateLoginArgs = {
  input: UserIsDuplicateLoginInput
};


export type MutationUserIsDuplicateNicknameArgs = {
  input: UserIsDuplicateNicknameInput
};


export type MutationUserIsDuplicateNicknameWithoutTokenArgs = {
  input: UserIsDuplicateNicknameWithoutTokenInput
};


export type MutationUserProfileImageDeleteArgs = {
  id: Scalars['ID']
};


export type MutationUserScrapDeleteAllArgs = {
  userScrapType?: Maybe<UserScrapType>
};


export type MutationUserSignupWithEmailArgs = {
  input: UserSignupWithEmailInput
};


export type MutationUserSignupWithOAuthArgs = {
  input: UserSignupWithOAuthInput
};


export type MutationUserUnlinkTimespreadArgs = {
  userID: Scalars['ID']
};


export type MutationUserUpdateArgs = {
  id: Scalars['ID'],
  input: UserInput
};


export type MutationUserUpdateBirthArgs = {
  input: UserUpdateBirthInput
};


export type MutationUserUpdatePhoneNumberAuthorizationArgs = {
  input: UserUpdatePhoneNumberAuthorizationInput
};


export type MutationVerifyChannelNameArgs = {
  name: Scalars['String']
};


export type MutationVerifyChannelUrlArgs = {
  url: Scalars['String']
};


export type MutationVisitChannelArgs = {
  channelId: Scalars['ID']
};

export type MuteUserInput = {
  mutedUserId: Scalars['ID'],
  newsCommentId?: Maybe<Scalars['ID']>,
};

export type MuteUserPayload = {
   __typename?: 'MuteUserPayload',
  newsComment?: Maybe<NewsComment>,
  user?: Maybe<User>,
};

export type MyActivitiesCalendarFilter = {
  q?: Maybe<Scalars['String']>,
};

export type NaverAccount = {
   __typename?: 'NaverAccount',
  id: Scalars['ID'],
};

export type NewActivityNotification = SimpleNotification & {
   __typename?: 'NewActivityNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  following?: Maybe<Channel>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type NewNewsItemNotification = SimpleNotification & {
   __typename?: 'NewNewsItemNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  following?: Maybe<Channel>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  newsItem?: Maybe<NewsItem>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type NewsComment = Reportable & {
   __typename?: 'NewsComment',
  author?: Maybe<Actor>,
  authorName: Scalars['String'],
  channelName?: Maybe<Scalars['String']>,
  children: NewsCommentConnection,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  lastReportReason?: Maybe<Scalars['String']>,
  lastReportedAt?: Maybe<Scalars['Date']>,
  newsItem?: Maybe<NewsItem>,
  originContent?: Maybe<Scalars['String']>,
  parent?: Maybe<NewsComment>,
  reportCount: Scalars['Int'],
  updatedAt?: Maybe<Scalars['Date']>,
  viewerCanDelete: Scalars['Boolean'],
  viewerCanReply: Scalars['Boolean'],
  viewerHasMuteAuthor: Scalars['Boolean'],
  viewerHasReported: Scalars['Boolean'],
  viewerIsAuthor: Scalars['Boolean'],
};


export type NewsCommentChildrenArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type NewsCommentConnection = {
   __typename?: 'NewsCommentConnection',
  edges: Array<NewsCommentEdge>,
  nodes: Array<NewsComment>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type NewsCommentEdge = {
   __typename?: 'NewsCommentEdge',
  cursor: Scalars['String'],
  node: NewsComment,
};

export type NewsCommentOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<NewsCommentOrderField>,
};

export enum NewsCommentOrderField {
  DELETED_AT = 'DELETED_AT',
  REPORTED_AT = 'REPORTED_AT',
  REPORT_COUNT = 'REPORT_COUNT'
}

export type NewsCommentToNewsCommentNotification = SimpleNotification & {
   __typename?: 'NewsCommentToNewsCommentNotification',
  author?: Maybe<Actor>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  newsComment?: Maybe<NewsComment>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type NewsCommentToNewsItemNotification = SimpleNotification & {
   __typename?: 'NewsCommentToNewsItemNotification',
  author?: Maybe<Actor>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  newsComment?: Maybe<NewsComment>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type NewsItem = Reportable & {
   __typename?: 'NewsItem',
  author?: Maybe<User>,
  bookmarkCount: Scalars['Int'],
  bookmarkers: BookmarkerConnection,
  channel?: Maybe<Channel>,
  comments: NewsCommentConnection,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  embed?: Maybe<Embed>,
  id: Scalars['ID'],
  images: AttachmentConnection,
  isNotice: Scalars['Boolean'],
  lastReportReason?: Maybe<Scalars['String']>,
  lastReportedAt?: Maybe<Scalars['Date']>,
  likeCount: Scalars['Int'],
  likers: LikerConnection,
  newsCommentCount: Scalars['Int'],
  originContent?: Maybe<Scalars['String']>,
  plainText?: Maybe<Scalars['String']>,
  reportCount: Scalars['Int'],
  shareCount: Scalars['Int'],
  tags?: Maybe<Array<Maybe<Tag>>>,
  updatedAt?: Maybe<Scalars['Date']>,
  viewCount: Scalars['Int'],
  viewerCanDelete: Scalars['Boolean'],
  viewerCanReply: Scalars['Boolean'],
  viewerHasBookmarked: Scalars['Boolean'],
  viewerHasLiked: Scalars['Boolean'],
  viewerHasReported: Scalars['Boolean'],
  viewerIsAuthor: Scalars['Boolean'],
};


export type NewsItemBookmarkersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type NewsItemCommentsArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type NewsItemImagesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type NewsItemLikersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type NewsItemConnection = {
   __typename?: 'NewsItemConnection',
  edges: Array<NewsItemEdge>,
  nodes: Array<Maybe<NewsItem>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type NewsItemEdge = {
   __typename?: 'NewsItemEdge',
  cursor: Scalars['String'],
  node?: Maybe<NewsItem>,
};

export type NewsItemFeedFilter = {
  q?: Maybe<Scalars['String']>,
};

export type NewsItemFilter = {
  createdAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

export type NewsItemOrder = {
  direction: OrderDirection,
  field: NewsItemOrderField,
};

export enum NewsItemOrderField {
  CREATED_AT = 'CREATED_AT',
  DELETED_AT = 'DELETED_AT',
  LIKE_COUNT = 'LIKE_COUNT',
  NEWS_COMMENT_COUNT = 'NEWS_COMMENT_COUNT',
  POPULAR = 'POPULAR',
  REPORTED_AT = 'REPORTED_AT',
  REPORT_COUNT = 'REPORT_COUNT',
  SCRAP_COUNT = 'SCRAP_COUNT',
  SHARE_COUNT = 'SHARE_COUNT',
  VIEW_COUNT = 'VIEW_COUNT'
}

export enum NhisLoginTypeLevel {
  KAKAO_TALK = 'KAKAO_TALK',
  KB_MOBILE = 'KB_MOBILE',
  NAVER = 'NAVER',
  PAYCO = 'PAYCO',
  SAMSUNG_PASS = 'SAMSUNG_PASS',
  SHINHAN_CERTIFICATE = 'SHINHAN_CERTIFICATE',
  TELECOM_PASS = 'TELECOM_PASS',
  TOSS = 'TOSS'
}

export enum NhisTelecom {
  KT = 'KT',
  LG_U_PLUS = 'LG_U_PLUS',
  SKT = 'SKT'
}

export type Notification = {
   __typename?: 'Notification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  hasRead: Scalars['Boolean'],
  id: Scalars['ID'],
  linkURL: Scalars['String'],
  senderNickname?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type NotificationConnection = {
   __typename?: 'NotificationConnection',
  edges: Array<NotificationEdge>,
  nodes: Array<Maybe<SimpleNotification>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type NotificationDeleteAllPayload = {
   __typename?: 'NotificationDeleteAllPayload',
  count?: Maybe<Scalars['Int']>,
};

export type NotificationDeleteInput = {
  id: Scalars['ID'],
  type: NotificationType,
};

export type NotificationDeletePayload = {
   __typename?: 'NotificationDeletePayload',
  notification?: Maybe<Notification>,
};

export type NotificationEdge = {
   __typename?: 'NotificationEdge',
  cursor: Scalars['String'],
  node: SimpleNotification,
};

export enum NotificationScope {
  ACTIVITY_NOTIFICATION = 'ACTIVITY_NOTIFICATION',
  BIZ_ACTIVITY_NOTIFICATION = 'BIZ_ACTIVITY_NOTIFICATION',
  BIZ_BOOKMARK_NOTIFICATION = 'BIZ_BOOKMARK_NOTIFICATION',
  BIZ_CHANNEL_NOTIFICATION = 'BIZ_CHANNEL_NOTIFICATION',
  BIZ_FOLLOWER_NOTIFICATION = 'BIZ_FOLLOWER_NOTIFICATION',
  BIZ_LIKE_NOTIFICATION = 'BIZ_LIKE_NOTIFICATION',
  BIZ_NEWS_COMMENT_NOTIFICATION = 'BIZ_NEWS_COMMENT_NOTIFICATION',
  BIZ_NOTIFICATION = 'BIZ_NOTIFICATION',
  CHANNEL_NOTIFICATION = 'CHANNEL_NOTIFICATION',
  CHAT_NOTIFICATION = 'CHAT_NOTIFICATION',
  COMMUNITY_NOTIFICATION = 'COMMUNITY_NOTIFICATION',
  NOTIFICATION = 'NOTIFICATION'
}

export type NotificationsFilter = {
  scope?: Maybe<NotificationScope>,
};

export type NotificationsPayload = {
   __typename?: 'NotificationsPayload',
  notifications?: Maybe<Array<Maybe<Notification>>>,
};

export enum NotificationType {
  COMMENT_TO_COMMENT = 'COMMENT_TO_COMMENT',
  COMMENT_TO_POST = 'COMMENT_TO_POST',
  MESSAGE = 'MESSAGE',
  SCRAP_ACTIVITY_CLOSE_SOON = 'SCRAP_ACTIVITY_CLOSE_SOON',
  SCRAP_ACTIVITY_CLOSE_SOONER = 'SCRAP_ACTIVITY_CLOSE_SOONER'
}

export type NotificationUpdateInput = {
  hasRead: Scalars['Boolean'],
  id: Scalars['ID'],
  type: NotificationType,
};

export type NotificationUpdatePayload = {
   __typename?: 'NotificationUpdatePayload',
  notification?: Maybe<Notification>,
};


export type Onoffline = {
   __typename?: 'Onoffline',
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Organization = {
   __typename?: 'Organization',
  address?: Maybe<Scalars['String']>,
  alias?: Maybe<Scalars['String']>,
  channel?: Maybe<Channel>,
  confirmationRequestedAt?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  establishedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  isConfirmed?: Maybe<Scalars['Boolean']>,
  jobCategory?: Maybe<Category>,
  licenseImages: Array<Maybe<Attachment>>,
  logoImage?: Maybe<Attachment>,
  name: Scalars['String'],
  organizationType?: Maybe<OrganizationType>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  phoneNumber?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  scale?: Maybe<OrganizationScale>,
  websiteURL?: Maybe<Scalars['String']>,
};

export type OrganizationApprovedNotification = SimpleNotification & {
   __typename?: 'OrganizationApprovedNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type OrganizationConnection = {
   __typename?: 'OrganizationConnection',
  nodes?: Maybe<Array<Maybe<Organization>>>,
  totalCount: Scalars['Int'],
};

export type OrganizationCreateInput = {
  address?: Maybe<Scalars['String']>,
  isConfirmed?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  organizationTypeID?: Maybe<Scalars['ID']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  websiteURL?: Maybe<Scalars['String']>,
};

export type OrganizationCreateOrUpdatePayload = {
   __typename?: 'OrganizationCreateOrUpdatePayload',
  organization?: Maybe<Organization>,
};

export type OrganizationCreatePayload = {
   __typename?: 'OrganizationCreatePayload',
  organization?: Maybe<Organization>,
};

export type OrganizationDeletePayload = {
   __typename?: 'OrganizationDeletePayload',
  organizationID?: Maybe<Scalars['ID']>,
};

export type OrganizationFilterInput = {
  name?: Maybe<Scalars['String']>,
};

export type OrganizationFromDart = {
   __typename?: 'OrganizationFromDart',
  organizationName: Scalars['String'],
  registrationNumber: Scalars['String'],
  representative: Scalars['String'],
};

export type OrganizationListFromDart = {
   __typename?: 'OrganizationListFromDart',
  corpCode?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type OrganizationListFromDartConnection = {
   __typename?: 'OrganizationListFromDartConnection',
  nodes?: Maybe<Array<Maybe<OrganizationListFromDart>>>,
  totalCount: Scalars['Int'],
};

export type OrganizationListFromDartFilterInput = {
  organizationName: Scalars['String'],
};

export enum OrganizationOrderField {
  ID = 'ID',
  NAME = 'NAME'
}

export type OrganizationOrderInput = {
  direction: OrderDirection,
  field: OrganizationOrderField,
};

export type OrganizationPendingNotification = SimpleNotification & {
   __typename?: 'OrganizationPendingNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum OrganizationScale {
  FIFTY_TO_TWOHUNDRED = 'FIFTY_TO_TWOHUNDRED',
  FIVEHUNDRED_TO_THOUSAND = 'FIVEHUNDRED_TO_THOUSAND',
  FIVETHOUSAND_TO_TENTHOUSAND = 'FIVETHOUSAND_TO_TENTHOUSAND',
  ONE_TO_TEN = 'ONE_TO_TEN',
  OVER_TENTHOUSAND = 'OVER_TENTHOUSAND',
  TEN_TO_FIFTY = 'TEN_TO_FIFTY',
  THOUSAND_TO_FIVETHOUSAND = 'THOUSAND_TO_FIVETHOUSAND',
  TWOHUNDRED_TO_FIVEHUNDRED = 'TWOHUNDRED_TO_FIVEHUNDRED',
  ZERO_TO_ONE = 'ZERO_TO_ONE'
}

export type OrganizationsDeletePayload = {
   __typename?: 'OrganizationsDeletePayload',
  organizationIDs?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type OrganizationType = {
   __typename?: 'OrganizationType',
  id: Scalars['ID'],
  name: Scalars['String'],
  seq: Scalars['Int'],
};

export type OrganizationUpdatePayload = {
   __typename?: 'OrganizationUpdatePayload',
  organization?: Maybe<Organization>,
};

export type PageInfo = {
   __typename?: 'PageInfo',
  endCursor?: Maybe<Scalars['String']>,
  hasNextPage: Scalars['Boolean'],
  hasPreviousPage: Scalars['Boolean'],
  startCursor?: Maybe<Scalars['String']>,
};

export type Pagination = {
  page: Scalars['Int'],
  pageSize: Scalars['Int'],
};

export enum Period {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

export type PeriodForEducationFilterCollection = {
   __typename?: 'PeriodForEducationFilterCollection',
  from?: Maybe<Scalars['Int']>,
  id: Scalars['Int'],
  name: Scalars['String'],
  to?: Maybe<Scalars['Int']>,
};

export type Persona = {
   __typename?: 'Persona',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export type PopularChatRoomsFilterBy = {
  activityTypeID?: Maybe<Scalars['Int']>,
  chatRoomType?: Maybe<ChatRoomType>,
};

export type Post = {
   __typename?: 'Post',
  board?: Maybe<Board>,
  commentCount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  nickname?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  username?: Maybe<Scalars['String']>,
  views?: Maybe<Scalars['Int']>,
};

export type PostDuty = {
  dutyId?: Maybe<Scalars['String']>,
  parentDutyId?: Maybe<Scalars['String']>,
  rootDutyId?: Maybe<Scalars['String']>,
};

export type PostDutyV2 = {
  id?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['String']>,
  rootId?: Maybe<Scalars['String']>,
};

export type PostOrganization = {
  organizationGroupName?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
};

export type PostOrganizationV2 = {
  groupName?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type PreferredOrganization = {
   __typename?: 'PreferredOrganization',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export enum ProcessStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  RESERVED = 'RESERVED'
}

export type Profile = {
   __typename?: 'Profile',
  address?: Maybe<Scalars['String']>,
  birthday?: Maybe<Scalars['Date']>,
  certifications?: Maybe<Array<Maybe<Certification>>>,
  education: EducationType,
  email?: Maybe<Scalars['String']>,
  examedAt?: Maybe<Scalars['Date']>,
  gender?: Maybe<Gender>,
  highschools?: Maybe<Array<Maybe<HighschoolProfile>>>,
  id: Scalars['ID'],
  identificationImage?: Maybe<ApplyFile>,
  isFirst: Scalars['Boolean'],
  languages?: Maybe<Array<Maybe<Language>>>,
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  speciality?: Maybe<Scalars['String']>,
  techniques?: Maybe<Array<Maybe<Technique>>>,
  updatedAt: Scalars['Date'],
  user: User,
};

export type ProfileBackgroundColor = {
   __typename?: 'ProfileBackgroundColor',
  id: Scalars['ID'],
  name: Scalars['String'],
  code: Scalars['String'],
};

export type ProfileImageTemplate = {
   __typename?: 'ProfileImageTemplate',
  id: Scalars['ID'],
  image: ChatAttachment,
  bgColor: ProfileBackgroundColor,
};

export type QnA = {
   __typename?: 'QnA',
  answer?: Maybe<Scalars['String']>,
  author?: Maybe<User>,
  blankType: BlankType,
  charMaxSize: Scalars['Int'],
  charSize: CharSize,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  question?: Maybe<Scalars['String']>,
  selfIntroduction?: Maybe<SelfIntroduction>,
  seq: Scalars['Int'],
  snapshots: QnASnapshotConnection,
};


export type QnASnapshotsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type QnASnapshot = {
   __typename?: 'QnASnapshot',
  answer?: Maybe<Scalars['String']>,
  blankType: BlankType,
  charMaxSize: Scalars['Int'],
  charSize: CharSize,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  qnaId: Scalars['Int'],
  question?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
};

export type QnASnapshotConnection = {
   __typename?: 'QnASnapshotConnection',
  nodes: Array<Maybe<QnASnapshot>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type Query = {
   __typename?: 'Query',
  viewerSubscribeAnyChatRoomNotification: Scalars['Boolean'],
  getRandomProfileImageTemplate: ProfileImageTemplate,
  chatRoom?: Maybe<ChatRoom>,
  popularChatRooms: Array<ChatRoom>,
  searchChatRoomsByName: ChatRoomConnection,
  searchChatRoomsByNameWithOffset: ChatRoomOffsetConnection,
  searchChatMessagesFromChatRoom: ChatMessageConnection,
  chatMessagesByChatRoomId: ChatMessageConnection,
  chatMessage: ChatMessage,
  reportedChatMessages: ChatMessageOffsetConnection,
  deletedChatMessages: ChatMessageOffsetConnection,
  chatMessageUnreadCount?: Maybe<Scalars['Int']>,
  userBans: UserBanOffsetConnection,
  kpiChatRooms: KpiChatRoomOffsetConnection,
  kpiChats: KpiChatOffsetConnection,
  academyExamCount: Scalars['Int'],
  activities?: Maybe<ActivityConnection>,
  activitiesByID: Array<Activity>,
  activitiesByJobCategory: ActivityConnection,
  activitiesByOrganizationType: ActivityConnection,
  activitiesByRecruitType: ActivityConnection,
  activitiesCalendar: ActivityCalendarConnection,
  activitiesCount?: Maybe<Scalars['Int']>,
  activitiesForRelatedContent?: Maybe<ActivityConnection>,
  activitiesWithJobCurationActivities: ActivityConnection,
  activity?: Maybe<Activity>,
  activityCalendarEntries: ActivityCalendarEntryConnection,
  activityCalendarEntry?: Maybe<ActivityCalendarEntry>,
  activityCardNewsList?: Maybe<ActivityCardNewsListPayload>,
  activityCounts?: Maybe<ActivityCountsPayload>,
  activityFile?: Maybe<ActivityFile>,
  activityFileType?: Maybe<ActivityFileType>,
  activityFilterCollection: ActivityFilterCollection,
  activityFilterList?: Maybe<Array<ActivityFilterListItem>>,
  activityInteger?: Maybe<ActivityInteger>,
  activityIntegerType?: Maybe<ActivityIntegerType>,
  activityListFromAdsByPlacementCode: Array<ActivityWithAd>,
  activityOutLink?: Maybe<ActivityOutLink>,
  activityReference?: Maybe<ActivityReference>,
  activityScrapUserAgeRangeStatistics: ActivityScrapUserAgeRangeStatistics,
  activityScrapUserJobCategoryStatistics: ActivityScrapUserJobcategoryStatistics,
  activityScrapUserList: ActivityScrapUserListConnection,
  activityScrapUserMajorStatistics: ActivityScrapUserMajorStatistics,
  activityScrapUserSchoolStatistics: ActivityScrapUserSchoolStatistics,
  activitySearch?: Maybe<ActivitySearchConnection>,
  activitySelfIntroductionStatistics: ActivitySelfIntroductionStatisticConnection,
  activityTarget?: Maybe<ActivityTarget>,
  activityTargets?: Maybe<Array<ActivityTarget>>,
  activityText?: Maybe<ActivityText>,
  activityTextType?: Maybe<ActivityTextType>,
  activityType?: Maybe<ActivityType>,
  adPlacement?: Maybe<AdPlacement>,
  adPlacementByCode?: Maybe<AdPlacement>,
  adPlacementClickLogsByCampaignID: Array<AdPlacementClickLog>,
  adPlacementImpressionLogsByCampaignID: Array<AdPlacementImpressionLog>,
  adPlacementLandingURLWithCampaignID?: Maybe<AdPlacementLandingUrl>,
  adPlacements: AdPlacementConnection,
  adPlacementsByCampaignID: AdPlacementsByCampaignIdPayload,
  adminCampaignsByCampaignGroupId: AdminCampaignConnection,
  adminCampaignsByUnifiedAdPlacementId: AdminCampaignConnection,
  adsByPlacementCode: AdConnection,
  advertisement?: Maybe<Advertisement>,
  advertisementList?: Maybe<AdvertisementListPayload>,
  applyFile?: Maybe<ApplyFile>,
  applyType?: Maybe<ApplyType>,
  benefit?: Maybe<Benefit>,
  benefitsByTypeID?: Maybe<Array<Benefit>>,
  bestNewsItems?: Maybe<NewsItemConnection>,
  bizActivity?: Maybe<Activity>,
  campaign: Campaign,
  campaignGroup: CampaignGroupDto,
  campaignGroups: CampaignGroupConnection,
  campaignStatistics?: Maybe<CampaignStatisticConnection>,
  campaignStatisticsByCampaignGroupId: CampaignStatisticsReport,
  campaignStatisticsByCampaignId: CampaignStatisticsConnection,
  campaigns: CampaignConnection,
  careerEmploymentSupportTargets: Array<CareerEmploymentSupportTarget>,
  careerProfileKpi: CareerProfileKpiConnection,
  categories?: Maybe<Array<Category>>,
  categoriesByTypeID?: Maybe<Array<Category>>,
  category?: Maybe<Category>,
  certification?: Maybe<Certification>,
  channel?: Maybe<Channel>,
  channelByUrl?: Maybe<Channel>,
  channelFeed?: Maybe<ChannelConnection>,
  channels?: Maybe<ChannelConnection>,
  channelsWithStatistic?: Maybe<ChannelConnection>,
  clubFilterCollection: ClubFilterCollection,
  comments: NewsCommentConnection,
  communityBestPostList?: Maybe<CommunityBestPostListData>,
  communityBoardList?: Maybe<CommunityBoardListData>,
  communityBoardUrl?: Maybe<CommunityBoardUrl>,
  communityBoardUrlByActivity?: Maybe<CommunityBoardUrl>,
  communityBoardUrls: CommunityBoardUrlConnection,
  communityBookmarks: CommunityBookmarkableConnection,
  communityNotifications?: Maybe<CommunityNotificationsData>,
  communityPost?: Maybe<CommunityPostObject>,
  communityPostCollection?: Maybe<CommunityPostCollectionData>,
  communityPostSearch?: Maybe<CommunityPostSearchData>,
  communityPostsRelatedWithActivity: CommunityPostRelatedWithActivityConnection,
  communityPostsRelatedWithActivityManual: CommunityPostRelatedWithActivityManualConnection,
  companyActivityList?: Maybe<Array<Activity>>,
  contestFilterCollection: ContestFilterCollection,
  costTypes: Array<CostType>,
  coverLetter?: Maybe<CoverLetter>,
  coverLetterOrganizationList?: Maybe<Array<Maybe<CoverLetterOrganizationListPayload>>>,
  coverLetterPlagiarismList?: Maybe<CoverLetterPlagiarismConnection>,
  coverLetterRoleList?: Maybe<Array<Maybe<CoverLetterRoleListPayload>>>,
  coverLetterTotalCount?: Maybe<Scalars['Int']>,
  coverLetterWithHighlight?: Maybe<CoverLetterWithHighlight>,
  coverLetters?: Maybe<CoverLetterElasticConnection>,
  coverLettersRelatedWithActivity: CoverLetterRelatedWithActivityConnection,
  coverLettersRelatedWithActivityManual: CoverLetterRelatedWithActivityManualConnection,
  curation?: Maybe<Curation>,
  curationList?: Maybe<CurationListConnection>,
  currentAdByPlacementCodeAndCurrentTime?: Maybe<Ad>,
  currentUser?: Maybe<User>,
  currentUserScrapsCommunity: CurrentUserScrapCommunityConnection,
  currentUserScrapsCountCommunity: Scalars['Int'],
  deletedNewsComments: NewsCommentConnection,
  deletedNewsItems: NewsItemConnection,
  duties: DutyConnection,
  educationFilterCollection: EducationFilterCollection,
  environment?: Maybe<Environment>,
  examCompanies: Array<ExamCompany>,
  examCompany: ExamCompany,
  examGradingQuestions: Array<ExamGradingQuestion>,
  examGradingResult: ExamGradingResult,
  examGradingResultDistribution: Array<ExamGradingResultDistribution>,
  examGradingResultDistributionSameInfo: Array<ExamGradingResultDistribution>,
  examReserveInformations: ExamReserveInformationConnection,
  examRestoredQuestionKeywords: ExamQuestionKeywordConnection,
  examRestoredQuestions: ExamRestoredQuestionConnection,
  examSurveyByTestId?: Maybe<ExamSurvey>,
  examTestSetting?: Maybe<ExamTest>,
  examTestSettings: ExamTestConnection,
  examTests: Array<ExamTest>,
  famousActivityList?: Maybe<FamousActivityListPayload>,
  getActivityByChatRoomName?: Maybe<Activity>,
  highschool?: Maybe<Highschool>,
  highschoolProfile?: Maybe<HighschoolProfile>,
  interest?: Maybe<Interest>,
  interests?: Maybe<Array<Interest>>,
  internFilterCollection: InternFilterCollection,
  introductionLetter?: Maybe<IntroductionLetter>,
  introductionLetterReference?: Maybe<IntroductionLetterReference>,
  introductionLetterType?: Maybe<IntroductionLetterType>,
  jobCategories: Array<JobCategory>,
  jobCurationActivity?: Maybe<JobCurationActivity>,
  jobCurationActivitySettings: JobCurationActivityConnection,
  jobCurationCenterBanner: JobCurationCenterBanner,
  jobCurationCenterBannerSettings: JobCurationCenterBannerConnection,
  jobCurationCenterBanners: JobCurationCenterBannerConnection,
  jobCurationMentorBanner?: Maybe<JobCurationMentorBanner>,
  jobCurationOrganizationContentSettings: JobCurationOrganizationContentConnection,
  jobCurationOrganizationContents: JobCurationOrganizationContentConnection,
  jobCurationPostSettings: JobCurationPostConnection,
  jobCurationPosts: JobCurationPostConnection,
  jobPositions: Array<JobPosition>,
  jobRanks: Array<JobRank>,
  language?: Maybe<Language>,
  linkareerMagazine?: Maybe<LinkareerMagazine>,
  linkareerMagazineList: LinkareerMagazineListConnection,
  majorByName: Major,
  myActivitiesCalendar: ActivityCalendarConnection,
  newsComment?: Maybe<NewsComment>,
  newsItem?: Maybe<NewsItem>,
  newsItemFeed: NewsItemConnection,
  newsItemFeedByTagIDs: NewsItemConnection,
  newsItems: NewsItemConnection,
  noticeNewsItem?: Maybe<NewsItem>,
  notification?: Maybe<Notification>,
  notifications?: Maybe<NotificationsPayload>,
  notificationsUnreadCount?: Maybe<Scalars['Int']>,
  notificationsUnreadCountByAuthUserID?: Maybe<Scalars['Int']>,
  onofflines: Array<Onoffline>,
  organization?: Maybe<Organization>,
  organizationFromDart?: Maybe<OrganizationFromDart>,
  organizationListFromDart?: Maybe<OrganizationListFromDartConnection>,
  organizationType?: Maybe<OrganizationType>,
  organizationTypes?: Maybe<Array<Maybe<OrganizationType>>>,
  organizations?: Maybe<OrganizationConnection>,
  organizationsByID?: Maybe<Array<Maybe<Organization>>>,
  periods: Array<PeriodForEducationFilterCollection>,
  persona?: Maybe<Persona>,
  preferredOrganizations: Array<PreferredOrganization>,
  profile?: Maybe<Profile>,
  profileImageByAuthUserID?: Maybe<Scalars['String']>,
  qnaSnapshotsByQnaId: QnASnapshotConnection,
  questionSuggestion: QuestionSuggestion,
  questionSuggestions: QuestionSuggestionConnection,
  recommendChannelFeed: ChannelConnection,
  recommendChannelTagCollection?: Maybe<Array<TagCollection>>,
  recommendChannels?: Maybe<ChannelConnection>,
  recommendNewsItemTagCollection?: Maybe<Array<TagCollection>>,
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
  recommendSearchKeywordList?: Maybe<RecommendSearchKeywordListConnection>,
  recruitFilterCollection: RecruitFilterCollection,
  region?: Maybe<Region>,
  regionDistrict?: Maybe<RegionDistrict>,
  regions: Array<Region>,
  replies: ReplyConnection,
  reply?: Maybe<Reply>,
  replyList?: Maybe<ReplyListPayload>,
  replyQna?: Maybe<Array<Reply>>,
  reportedNewsComments: NewsCommentConnection,
  reportedNewsItems?: Maybe<NewsItemConnection>,
  resume?: Maybe<Resume>,
  rootIndustries: Array<Industry>,
  rootWelfareBenefits: Array<WelfareBenefit>,
  satisfyActivityScrapUserTotalCount: Scalars['Boolean'],
  schoolByName: School,
  searchExactCoverLetter?: Maybe<Array<Maybe<SearchExactCoverLetterPayload>>>,
  searchMajors: Array<Major>,
  searchSchools: Array<School>,
  searchTags?: Maybe<TagConnection>,
  searchUniqueNameOrganizations: UniqueNameOrganizationConnection,
  searchViewerSelfIntroductionFeed: SelfIntroductionConnection,
  selfIntroductionStatistics: SelfIntroductionStatisticConnection,
  sharedCareerProfile?: Maybe<CareerProfile>,
  signupSession?: Maybe<SignupSession>,
  signupSessions: SignupSessionConnection,
  signupSessionsByToken: Array<Maybe<SignupSession>>,
  skill?: Maybe<Skill>,
  skillTagByName: SkillTag,
  skills?: Maybe<Array<Skill>>,
  technique?: Maybe<Technique>,
  tendency?: Maybe<Tendency>,
  top4Recruit: Array<Activity>,
  topFollowingChannels: TopFollowingChannelConnection,
  unifiedAdPlacementGroups: Array<UnifiedAdPlacementGroupDto>,
  university?: Maybe<University>,
  user?: Maybe<User>,
  userAuth?: Maybe<Scalars['Object']>,
  userScrap?: Maybe<UserScrap>,
  userScrapCounts?: Maybe<UserScrapCountsPayload>,
  userScraps?: Maybe<UserScrapsConnection>,
  userStatistics: UserStatisticConnection,
  users: UserConnection,
  usersByEmail: Array<User>,
  usersByID: Array<Maybe<User>>,
  viewerActivityManagerPresets: ActivityManagerPresetOffsetConnection,
  viewerActivityOrganizationPresets: ActivityOrganizationPresetOffsetConnection,
  viewerBookmarks?: Maybe<BookmarkedConnection>,
  viewerCareerProfile: CareerProfile,
  viewerComments: NewsCommentConnection,
  viewerEducation?: Maybe<UserEducationDto>,
  viewerExamReserveInformation?: Maybe<ExamReserveInformation>,
  viewerFiles: SelfIntroductionConnection,
  viewerFolder: Folder,
  viewerFolders: FolderConnection,
  viewerFollowers: FollowerConnection,
  viewerFollowingFeed?: Maybe<FollowingConnection>,
  viewerFollowings: FollowingConnection,
  viewerHasAdditionalInfoFilled: Scalars['Boolean'],
  viewerHasSubmittedExamSurvey: Scalars['Boolean'],
  viewerJobCategories?: Maybe<Array<JobCategory>>,
  viewerNewsItemFeed: NewsItemConnection,
  viewerNotifications?: Maybe<NotificationConnection>,
  viewerPreferActivityTypes?: Maybe<Array<ActivityTypeDto>>,
  viewerPreferOrganizationIds?: Maybe<Array<PreferredOrganization>>,
  viewerSelfIntroduction: SelfIntroduction,
  viewerSelfIntroductionFeedByDutyId: SelfIntroductionConnection,
  viewerSelfIntroductionFeedByQuery: SelfIntroductionConnection,
  viewerSelfIntroductions: SelfIntroductionConnection,
  workfield?: Maybe<Workfield>,
};


export type QueryChatRoomArgs = {
  id: Scalars['ID']
};


export type QueryPopularChatRoomsArgs = {
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  filterBy?: Maybe<PopularChatRoomsFilterBy>
};


export type QuerySearchChatRoomsByNameArgs = {
  name: Scalars['String'],
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type QuerySearchChatRoomsByNameWithOffsetArgs = {
  pagination: Pagination,
  orderBy?: Maybe<ChatRoomOrderByField>,
  roomName?: Maybe<Scalars['String']>,
  filterBy?: Maybe<SearchChatRoomsByNameWithOffsetFilterBy>
};


export type QuerySearchChatMessagesFromChatRoomArgs = {
  keyword: Scalars['String'],
  chatRoomId: Scalars['ID'],
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type QueryChatMessagesByChatRoomIdArgs = {
  chatRoomId: Scalars['ID'],
  isUpdateLastReadChat?: Maybe<Scalars['Boolean']>,
  first?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  filterBy?: Maybe<ChatMessageFilter>
};


export type QueryChatMessageArgs = {
  chatMessageId: Scalars['ID']
};


export type QueryReportedChatMessagesArgs = {
  findWithRangeInput: FindWithRangeInput,
  hasAdminRead?: Maybe<Scalars['Boolean']>,
  reportedChatMessageSearchInput?: Maybe<ReportedChatMessageSearchInput>
};


export type QueryDeletedChatMessagesArgs = {
  findWithRangeInput: FindWithRangeInput,
  deletedChatMessageSearchInput?: Maybe<DeletedChatMessageSearchInput>
};


export type QueryUserBansArgs = {
  pagination: Pagination,
  bannedUserSearchInput?: Maybe<BannedUserSearchInput>
};


export type QueryKpiChatRoomsArgs = {
  chatRoomId: Scalars['ID'],
  findWithPeriodInput: FindWithPeriodInput
};


export type QueryKpiChatsArgs = {
  findWithPeriodAndCategoryInput: FindWithPeriodAndCategoryInput
};


export type QueryActivitiesArgs = {
  filterBy?: Maybe<ActivityFilter>,
  orderBy?: Maybe<ActivityOrder>,
  pagination?: Maybe<Pagination>,
  rangeBy?: Maybe<ActivityRange>
};


export type QueryActivitiesByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryActivitiesByJobCategoryArgs = {
  categoryId: Scalars['ID'],
  pagination?: Maybe<Pagination>
};


export type QueryActivitiesByOrganizationTypeArgs = {
  organizationTypeId: Scalars['ID'],
  pagination?: Maybe<Pagination>
};


export type QueryActivitiesByRecruitTypeArgs = {
  pagination?: Maybe<Pagination>,
  recruitType: JobCurationActivityRecruitType
};


export type QueryActivitiesCalendarArgs = {
  field: ActivityCalendarGroupByField,
  filterBy?: Maybe<ActivityFilter>,
  from?: Maybe<Scalars['Date']>,
  orderBy?: Maybe<ActivityOrder>,
  pagination?: Maybe<Pagination>,
  to?: Maybe<Scalars['Date']>
};


export type QueryActivitiesCountArgs = {
  filterBy?: Maybe<ActivityFilter>
};


export type QueryActivitiesForRelatedContentArgs = {
  filterBy?: Maybe<ActivityForRelatedContentFilter>,
  pagination?: Maybe<Pagination>
};


export type QueryActivitiesWithJobCurationActivitiesArgs = {
  filterBy: ActivityWithJobCurationActivityFilters,
  pagination?: Maybe<Pagination>
};


export type QueryActivityArgs = {
  id: Scalars['ID']
};


export type QueryActivityCalendarEntriesArgs = {
  from: Scalars['Date'],
  pagination?: Maybe<Pagination>,
  to: Scalars['Date']
};


export type QueryActivityCalendarEntryArgs = {
  date: Scalars['Date']
};


export type QueryActivityCardNewsListArgs = {
  input: ActivityCardNewsListInput,
  pagination?: Maybe<Pagination>
};


export type QueryActivityFileArgs = {
  id: Scalars['ID']
};


export type QueryActivityFileTypeArgs = {
  id: Scalars['ID']
};


export type QueryActivityFilterListArgs = {
  input: ActivityFilterListInput
};


export type QueryActivityIntegerArgs = {
  id: Scalars['ID']
};


export type QueryActivityIntegerTypeArgs = {
  id: Scalars['ID']
};


export type QueryActivityListFromAdsByPlacementCodeArgs = {
  adPlacementCode: Scalars['String']
};


export type QueryActivityOutLinkArgs = {
  id: Scalars['ID']
};


export type QueryActivityReferenceArgs = {
  id: Scalars['ID']
};


export type QueryActivityScrapUserAgeRangeStatisticsArgs = {
  activityId: Scalars['ID']
};


export type QueryActivityScrapUserJobCategoryStatisticsArgs = {
  activityId: Scalars['ID']
};


export type QueryActivityScrapUserListArgs = {
  input: ActivityScrapUserListInput,
  pagination?: Maybe<Pagination>
};


export type QueryActivityScrapUserMajorStatisticsArgs = {
  activityId: Scalars['ID']
};


export type QueryActivityScrapUserSchoolStatisticsArgs = {
  activityId: Scalars['ID']
};


export type QueryActivitySearchArgs = {
  filterBy: ActivitySearchFilter,
  orderBy?: Maybe<ActivitySearchOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryActivitySelfIntroductionStatisticsArgs = {
  filterBy?: Maybe<ActivitySelfIntroductionStatisticFilters>,
  orderBy?: Maybe<ActivitySelfIntroductionStatisticOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryActivityTargetArgs = {
  id: Scalars['ID']
};


export type QueryActivityTextArgs = {
  id: Scalars['ID']
};


export type QueryActivityTextTypeArgs = {
  id: Scalars['ID']
};


export type QueryActivityTypeArgs = {
  id: Scalars['ID']
};


export type QueryAdPlacementArgs = {
  id: Scalars['ID']
};


export type QueryAdPlacementByCodeArgs = {
  code: Scalars['String']
};


export type QueryAdPlacementClickLogsByCampaignIdArgs = {
  campaignID: Scalars['ID']
};


export type QueryAdPlacementImpressionLogsByCampaignIdArgs = {
  campaignID: Scalars['ID']
};


export type QueryAdPlacementLandingUrlWithCampaignIdArgs = {
  campaignID?: Maybe<Scalars['ID']>,
  id: Scalars['ID']
};


export type QueryAdPlacementsArgs = {
  filterBy?: Maybe<AdPlacementFilters>,
  orderBy?: Maybe<AdPlacementOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryAdPlacementsByCampaignIdArgs = {
  campaignID: Scalars['ID']
};


export type QueryAdminCampaignsByCampaignGroupIdArgs = {
  filterBy: AdminCampaignByCampaignGroupIdFilters,
  orderBy?: Maybe<AdminCampaignOrders>,
  pagination?: Maybe<Pagination>
};


export type QueryAdminCampaignsByUnifiedAdPlacementIdArgs = {
  filterBy: AdminCampaignByUnifiedAdPlacementIdFilters,
  orderBy?: Maybe<AdminCampaignOrders>,
  pagination?: Maybe<Pagination>
};


export type QueryAdsByPlacementCodeArgs = {
  code: Scalars['String']
};


export type QueryAdvertisementArgs = {
  id?: Maybe<Scalars['ID']>
};


export type QueryAdvertisementListArgs = {
  filterBy: AdvertisementFilter,
  orderBy?: Maybe<AdvertisementOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryApplyFileArgs = {
  id: Scalars['ID']
};


export type QueryApplyTypeArgs = {
  id: Scalars['ID']
};


export type QueryBenefitArgs = {
  id: Scalars['ID']
};


export type QueryBenefitsByTypeIdArgs = {
  activityTypeID: Scalars['ID']
};


export type QueryBestNewsItemsArgs = {
  pagination?: Maybe<Pagination>,
  type?: Maybe<BestNewsItemType>
};


export type QueryBizActivityArgs = {
  id: Scalars['ID']
};


export type QueryCampaignArgs = {
  id: Scalars['ID']
};


export type QueryCampaignGroupArgs = {
  id: Scalars['ID']
};


export type QueryCampaignGroupsArgs = {
  filterBy: CampaignGroupFilters,
  orderBy?: Maybe<CampaignGroupOrders>,
  pagination?: Maybe<Pagination>
};


export type QueryCampaignStatisticsArgs = {
  filterBy?: Maybe<CampaignStatisticFilters>
};


export type QueryCampaignStatisticsByCampaignGroupIdArgs = {
  campaignGroupId: Scalars['ID']
};


export type QueryCampaignStatisticsByCampaignIdArgs = {
  campaignId: Scalars['ID'],
  filterBy: CampaignStatisticFilters,
  groupBy: CampaignStatisticGroups,
  pagination?: Maybe<Pagination>
};


export type QueryCampaignsArgs = {
  filterBy?: Maybe<CampaignFilters>,
  orderBy?: Maybe<CampaignOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCareerProfileKpiArgs = {
  filterBy?: Maybe<CareerProfileKpiFilters>,
  groupBy: CareerProfileKpiGroups,
  pagination?: Maybe<Pagination>
};


export type QueryCategoriesArgs = {
  filterBy?: Maybe<CategoryFilter>
};


export type QueryCategoriesByTypeIdArgs = {
  activityTypeID: Scalars['ID']
};


export type QueryCategoryArgs = {
  id: Scalars['ID']
};


export type QueryCertificationArgs = {
  id: Scalars['ID']
};


export type QueryChannelArgs = {
  id: Scalars['ID']
};


export type QueryChannelByUrlArgs = {
  url: Scalars['String']
};


export type QueryChannelFeedArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<ChannelFilter>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ChannelOrder>
};


export type QueryChannelsArgs = {
  filterBy?: Maybe<ChannelFilter>,
  orderBy?: Maybe<ChannelOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryChannelsWithStatisticArgs = {
  filterBy?: Maybe<ChannelsWithStatisticFilter>,
  orderBy?: Maybe<ChannelsWithStatisticOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCommentsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  newsItemId: Scalars['ID'],
  parentId?: Maybe<Scalars['ID']>
};


export type QueryCommunityBestPostListArgs = {
  input: CommunityBestPostListInput
};


export type QueryCommunityBoardUrlArgs = {
  id: Scalars['ID']
};


export type QueryCommunityBoardUrlByActivityArgs = {
  activityId: Scalars['ID']
};


export type QueryCommunityBookmarksArgs = {
  orderBy?: Maybe<CommunityBookmarkOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCommunityNotificationsArgs = {
  input: CommunityNotificationsInput
};


export type QueryCommunityPostArgs = {
  id: Scalars['ID']
};


export type QueryCommunityPostCollectionArgs = {
  input: CommunityPostCollectionInput
};


export type QueryCommunityPostSearchArgs = {
  input: CommunityPostSearchInput
};


export type QueryCommunityPostsRelatedWithActivityArgs = {
  input: CommunityPostsRelatedWithActivityInput
};


export type QueryCommunityPostsRelatedWithActivityManualArgs = {
  input: CommunityPostsRelatedWithActivityManualInput
};


export type QueryCompanyActivityListArgs = {
  companyName: Scalars['String']
};


export type QueryCoverLetterArgs = {
  id: Scalars['ID']
};


export type QueryCoverLetterOrganizationListArgs = {
  input: CoverLetterOrganizationListInput
};


export type QueryCoverLetterPlagiarismListArgs = {
  id: Scalars['ID']
};


export type QueryCoverLetterRoleListArgs = {
  input: CoverLetterRoleListInput
};


export type QueryCoverLetterWithHighlightArgs = {
  id: Scalars['ID'],
  keyword?: Maybe<Scalars['String']>
};


export type QueryCoverLettersArgs = {
  filterBy: CoverLetterFilter,
  orderBy?: Maybe<CoverLetterOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCoverLettersRelatedWithActivityArgs = {
  input: CoverLettersRelatedWithActivityInput
};


export type QueryCoverLettersRelatedWithActivityManualArgs = {
  input: CoverLettersRelatedWithActivityManualInput
};


export type QueryCurationArgs = {
  id: Scalars['ID']
};


export type QueryCurrentAdByPlacementCodeAndCurrentTimeArgs = {
  code: Scalars['String'],
  currentTime: Scalars['String']
};


export type QueryCurrentUserScrapsCommunityArgs = {
  filterBy: CurrentUserScrapCommunityFilter,
  orderBy: UserScrapCommunityOrder,
  pagination?: Maybe<Pagination>
};


export type QueryDeletedNewsCommentsArgs = {
  filterBy?: Maybe<DeletedNewsCommentFilter>,
  orderBy?: Maybe<NewsCommentOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryDeletedNewsItemsArgs = {
  filterBy?: Maybe<DeletedNewsItemFilter>,
  orderBy?: Maybe<NewsItemOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryDutiesArgs = {
  filterBy?: Maybe<DutyFilters>,
  orderBy?: Maybe<DutyOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryExamCompanyArgs = {
  id: Scalars['Float']
};


export type QueryExamGradingQuestionsArgs = {
  input: ExamGradingQuestionFilters
};


export type QueryExamGradingResultArgs = {
  testId: Scalars['ID']
};


export type QueryExamGradingResultDistributionArgs = {
  input: ExamGradingResultDistributionInput
};


export type QueryExamGradingResultDistributionSameInfoArgs = {
  input: ExamGradingResultDistributionInput
};


export type QueryExamReserveInformationsArgs = {
  filterBy: ExamReserveInformationFilters,
  orderBy: ExamReserveInformationOrders,
  pagination?: Maybe<Pagination>
};


export type QueryExamRestoredQuestionKeywordsArgs = {
  cursorPagination: CursorPagination,
  questionId: Scalars['ID']
};


export type QueryExamRestoredQuestionsArgs = {
  filterBy: ExamRestoredQuestionsFilters,
  orderBy: ExamRestoredQuestionsOrders,
  pagination?: Maybe<Pagination>
};


export type QueryExamSurveyByTestIdArgs = {
  id: Scalars['ID']
};


export type QueryExamTestSettingArgs = {
  id: Scalars['ID']
};


export type QueryExamTestSettingsArgs = {
  filterBy: ExamTestSettingFilters,
  orderBy: ExamTestSettingOrders,
  pagination?: Maybe<Pagination>
};


export type QueryExamTestsArgs = {
  input: ExamFilters
};


export type QueryFamousActivityListArgs = {
  input: FamousActivityListInput
};


export type QueryGetActivityByChatRoomNameArgs = {
  activityTypeID: Scalars['Float'],
  chatRoomName: Scalars['String']
};


export type QueryHighschoolArgs = {
  id: Scalars['ID']
};


export type QueryHighschoolProfileArgs = {
  id: Scalars['ID']
};


export type QueryInterestArgs = {
  id: Scalars['ID']
};


export type QueryIntroductionLetterArgs = {
  id: Scalars['ID']
};


export type QueryIntroductionLetterReferenceArgs = {
  id: Scalars['ID']
};


export type QueryIntroductionLetterTypeArgs = {
  id: Scalars['ID']
};


export type QueryJobCurationActivityArgs = {
  id: Scalars['ID']
};


export type QueryJobCurationActivitySettingsArgs = {
  filterBy: JobCurationActivityFilters,
  pagination?: Maybe<Pagination>
};


export type QueryJobCurationCenterBannerArgs = {
  id: Scalars['ID']
};


export type QueryJobCurationCenterBannerSettingsArgs = {
  filterBy: JobCurationCenterBannerSettingsFilters,
  orderBy?: Maybe<JobCurationCenterBannerSettingsOrders>,
  pagination?: Maybe<Pagination>
};


export type QueryJobCurationOrganizationContentSettingsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryJobCurationOrganizationContentsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryJobCurationPostSettingsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryJobCurationPostsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryLanguageArgs = {
  id: Scalars['ID']
};


export type QueryLinkareerMagazineArgs = {
  id: Scalars['ID']
};


export type QueryMajorByNameArgs = {
  name: Scalars['String']
};


export type QueryMyActivitiesCalendarArgs = {
  field: ActivityCalendarGroupByField,
  filterBy?: Maybe<MyActivitiesCalendarFilter>,
  from?: Maybe<Scalars['Date']>,
  pagination?: Maybe<Pagination>,
  to?: Maybe<Scalars['Date']>
};


export type QueryNewsCommentArgs = {
  id: Scalars['ID']
};


export type QueryNewsItemArgs = {
  id: Scalars['ID']
};


export type QueryNewsItemFeedArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<NewsItemFeedFilter>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<NewsItemOrder>
};


export type QueryNewsItemFeedByTagIDsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  tagIds: Array<Scalars['ID']>
};


export type QueryNewsItemsArgs = {
  filterBy?: Maybe<NewsItemFilter>,
  orderBy?: Maybe<NewsItemOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryNoticeNewsItemArgs = {
  channelId: Scalars['ID']
};


export type QueryNotificationArgs = {
  id: Scalars['ID']
};


export type QueryNotificationsUnreadCountByAuthUserIdArgs = {
  authUserID: Scalars['ID']
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']
};


export type QueryOrganizationFromDartArgs = {
  corpCode: Scalars['String']
};


export type QueryOrganizationListFromDartArgs = {
  filterBy: OrganizationListFromDartFilterInput,
  pagination?: Maybe<Pagination>
};


export type QueryOrganizationTypeArgs = {
  id: Scalars['ID']
};


export type QueryOrganizationsArgs = {
  filterBy?: Maybe<OrganizationFilterInput>,
  orderBy?: Maybe<OrganizationOrderInput>,
  pagination?: Maybe<Pagination>
};


export type QueryOrganizationsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryPersonaArgs = {
  id: Scalars['ID']
};


export type QueryProfileArgs = {
  id: Scalars['ID']
};


export type QueryProfileImageByAuthUserIdArgs = {
  id: Scalars['ID']
};


export type QueryQnaSnapshotsByQnaIdArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  qnaId: Scalars['ID']
};


export type QueryQuestionSuggestionArgs = {
  id: Scalars['ID']
};


export type QueryQuestionSuggestionsArgs = {
  filterBy?: Maybe<QuestionSuggestionFilters>,
  pagination?: Maybe<Pagination>
};


export type QueryRecommendChannelFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type QueryRecommendChannelsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryRecommendSearchKeywordArgs = {
  id: Scalars['ID']
};


export type QueryRecommendSearchKeywordListArgs = {
  type?: Maybe<RecommendSearchKeywordType>
};


export type QueryRegionArgs = {
  id: Scalars['ID']
};


export type QueryRegionDistrictArgs = {
  id: Scalars['ID']
};


export type QueryRegionsArgs = {
  filterBy?: Maybe<RegionsFilters>
};


export type QueryRepliesArgs = {
  filterBy?: Maybe<ReplyFilters>,
  orderBy?: Maybe<ReplyOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryReplyArgs = {
  id: Scalars['ID']
};


export type QueryReplyListArgs = {
  input: ReplyListInput
};


export type QueryReplyQnaArgs = {
  input: ReplyQnaInput
};


export type QueryReportedNewsCommentsArgs = {
  filterBy?: Maybe<ReportedNewsCommentFilter>,
  orderBy?: Maybe<NewsCommentOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryReportedNewsItemsArgs = {
  filterBy?: Maybe<ReportedNewsItemFilter>,
  orderBy?: Maybe<NewsItemOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryResumeArgs = {
  id: Scalars['ID']
};


export type QuerySatisfyActivityScrapUserTotalCountArgs = {
  activityId: Scalars['ID']
};


export type QuerySchoolByNameArgs = {
  name: Scalars['String'],
  type: SchoolType
};


export type QuerySearchExactCoverLetterArgs = {
  field: CoverLetterField,
  query: Scalars['String']
};


export type QuerySearchMajorsArgs = {
  filterBy: SearchMajorFilters
};


export type QuerySearchSchoolsArgs = {
  filterBy: SearchSchoolFilters
};


export type QuerySearchTagsArgs = {
  tagName: Scalars['String']
};


export type QuerySearchUniqueNameOrganizationsArgs = {
  filterBy: SearchUniqueNameOrganizationFilter,
  orderBy?: Maybe<SearchUniqueNameOrganizationOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySearchViewerSelfIntroductionFeedArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<SearchSelfIntroductionFilters>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type QuerySelfIntroductionStatisticsArgs = {
  filterBy?: Maybe<SelfIntroductionStatisticFilters>,
  pagination?: Maybe<Pagination>
};


export type QuerySharedCareerProfileArgs = {
  id: Scalars['ID']
};


export type QuerySignupSessionArgs = {
  token: Scalars['ID']
};


export type QuerySignupSessionsArgs = {
  filterBy?: Maybe<SignupSessionFilters>,
  orderBy?: Maybe<SignupSessionOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySignupSessionsByTokenArgs = {
  tokens: Array<Scalars['ID']>
};


export type QuerySkillArgs = {
  id: Scalars['ID']
};


export type QuerySkillTagByNameArgs = {
  name: Scalars['String']
};


export type QueryTechniqueArgs = {
  id: Scalars['ID']
};


export type QueryTendencyArgs = {
  id: Scalars['ID']
};


export type QueryTopFollowingChannelsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryUniversityArgs = {
  id: Scalars['ID']
};


export type QueryUserArgs = {
  id: Scalars['ID']
};


export type QueryUserAuthArgs = {
  id: Scalars['ID']
};


export type QueryUserScrapArgs = {
  id: Scalars['ID']
};


export type QueryUserScrapsArgs = {
  filterBy: UserScrapsFilter,
  orderBy?: Maybe<UserScrapsOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUserStatisticsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryUsersArgs = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUsersByEmailArgs = {
  email: Scalars['String']
};


export type QueryUsersByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryViewerActivityManagerPresetsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerActivityOrganizationPresetsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerBookmarksArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerCommentsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerExamReserveInformationArgs = {
  input: ExamFilters
};


export type QueryViewerFilesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  folderId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type QueryViewerFolderArgs = {
  id: Scalars['ID']
};


export type QueryViewerFoldersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<FolderOrder>
};


export type QueryViewerFollowersArgs = {
  filterBy?: Maybe<FollowerFilter>,
  pagination?: Maybe<Pagination>
};


export type QueryViewerFollowingFeedArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<FollowingFilter>,
  first?: Maybe<Scalars['Int']>
};


export type QueryViewerFollowingsArgs = {
  filterBy?: Maybe<FollowingFilter>,
  pagination?: Maybe<Pagination>
};


export type QueryViewerHasSubmittedExamSurveyArgs = {
  testId: Scalars['ID']
};


export type QueryViewerNewsItemFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ViewerNewsItemFeedOrder>
};


export type QueryViewerNotificationsArgs = {
  filterBy?: Maybe<NotificationsFilter>,
  pagination?: Maybe<Pagination>
};


export type QueryViewerSelfIntroductionArgs = {
  id: Scalars['ID']
};


export type QueryViewerSelfIntroductionFeedByDutyIdArgs = {
  after?: Maybe<Scalars['String']>,
  dutyId: Scalars['ID'],
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type QueryViewerSelfIntroductionFeedByQueryArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  query?: Maybe<Scalars['String']>
};


export type QueryViewerSelfIntroductionsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type QueryWorkfieldArgs = {
  id: Scalars['ID']
};

export type QuestionSuggestion = {
   __typename?: 'QuestionSuggestion',
  activity?: Maybe<Activity>,
  completedAt?: Maybe<Scalars['Date']>,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  email: Scalars['String'],
  id: Scalars['ID'],
  images: AttachmentConnection,
};


export type QuestionSuggestionImagesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type QuestionSuggestionConnection = {
   __typename?: 'QuestionSuggestionConnection',
  nodes: Array<Maybe<QuestionSuggestion>>,
  totalCount: Scalars['Int'],
};

export type QuestionSuggestionFilters = {
  activityTitle?: Maybe<Scalars['String']>,
  contentQuery?: Maybe<Scalars['String']>,
  dateRange?: Maybe<DateRange>,
  isCompleted?: Maybe<Scalars['Boolean']>,
};

export type QuestionTemplate = {
   __typename?: 'QuestionTemplate',
  blankType: BlankType,
  charMaxSize?: Maybe<Scalars['Int']>,
  charSize: CharSize,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  duty: Duty,
  id: Scalars['ID'],
};

export type QuestionTemplateInput = {
  blankType?: Maybe<BlankType>,
  charMaxSize?: Maybe<Scalars['Int']>,
  charSize?: Maybe<CharSize>,
  content?: Maybe<Scalars['String']>,
};

export enum QuestionType {
  FREE = 'FREE',
  STRICT = 'STRICT'
}

export type ReadNotificationPayload = {
   __typename?: 'ReadNotificationPayload',
  notification?: Maybe<SimpleNotification>,
};

export type ReadReportedChatMessagePayload = {
   __typename?: 'ReadReportedChatMessagePayload',
  chatMessage: ChatMessage,
};

export type RecommendedMentorPostNotification = SimpleNotification & {
   __typename?: 'RecommendedMentorPostNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type RecommendSearchKeyword = {
   __typename?: 'RecommendSearchKeyword',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  type?: Maybe<RecommendSearchKeywordType>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type RecommendSearchKeywordCreateInput = {
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  type?: Maybe<RecommendSearchKeywordType>,
};

export type RecommendSearchKeywordCreatePayload = {
   __typename?: 'RecommendSearchKeywordCreatePayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type RecommendSearchKeywordDeletePayload = {
   __typename?: 'RecommendSearchKeywordDeletePayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type RecommendSearchKeywordListConnection = {
   __typename?: 'RecommendSearchKeywordListConnection',
  recommendSearchKeywords?: Maybe<Array<Maybe<RecommendSearchKeyword>>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export enum RecommendSearchKeywordType {
  ACTIVITY = 'ACTIVITY',
  COVER_LETTER = 'COVER_LETTER',
  POPULAR_CHANNEL = 'POPULAR_CHANNEL',
  RECOMMEND_CHANNEL = 'RECOMMEND_CHANNEL'
}

export type RecommendSearchKeywordUpdateInput = {
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<RecommendSearchKeywordType>,
};

export type RecommendSearchKeywordUpdatePayload = {
   __typename?: 'RecommendSearchKeywordUpdatePayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type RecoverNewsCommentsPayload = {
   __typename?: 'RecoverNewsCommentsPayload',
  newsComments?: Maybe<Array<Maybe<NewsComment>>>,
};

export type RecoverNewsItemsPayload = {
   __typename?: 'RecoverNewsItemsPayload',
  newsItems?: Maybe<Array<Maybe<NewsItem>>>,
};

export type RecruitFilterCollection = {
   __typename?: 'RecruitFilterCollection',
  categories: Array<Category>,
  jobTypes: Array<RecuritJogType>,
  organizationTypes: Array<OrganizationType>,
  regions: Array<Region>,
};

export type RecruitInformation = {
   __typename?: 'RecruitInformation',
  beneficialCondition?: Maybe<Scalars['String']>,
  contractDuration?: Maybe<Scalars['Int']>,
  createdAt: Scalars['Date'],
  duty: Duty,
  id: Scalars['ID'],
  internTypes: Array<ActivityInternType>,
  jobPositions?: Maybe<Array<JobPosition>>,
  jobRanks?: Maybe<Array<JobRank>>,
  jobResponsibility?: Maybe<Scalars['String']>,
  jobType?: Maybe<JobType>,
  recruitScale: Scalars['String'],
};

export type RecruitInformationInput = {
  beneficialCondition?: Maybe<Scalars['String']>,
  contractDuration?: Maybe<Scalars['Int']>,
  duty: DutyInput,
  internTypes?: Maybe<Array<Scalars['ID']>>,
  jobPositionIds?: Maybe<Array<Scalars['ID']>>,
  jobRankIds?: Maybe<Array<Scalars['ID']>>,
  jobResponsibility?: Maybe<Scalars['String']>,
  recruitScale: Scalars['String'],
};

export type RecruitManagerInput = {
  department: Scalars['String'],
  email: Scalars['String'],
  isManagerEmailVisible: Scalars['Boolean'],
  isManagerPhoneNumberVisible: Scalars['Boolean'],
  name: Scalars['String'],
  phoneNumber: Scalars['String'],
  saveManagerInformation: Scalars['Boolean'],
};

export type RecruitOrganizationInput = {
  homepageUrl?: Maybe<Scalars['String']>,
  industries: Array<Scalars['ID']>,
  logo: ActivityOrganizationLogoInput,
  name: Scalars['String'],
  organizationBusinessRegistrationNumber?: Maybe<Scalars['String']>,
  organizationTypeID: Scalars['ID'],
  saveOrganizationInformation: Scalars['Boolean'],
  welfareBenefits?: Maybe<Array<Scalars['ID']>>,
};

export enum RecruitOrganizationType {
  BANK = 'BANK',
  CLUB = 'CLUB',
  ETC = 'ETC',
  FOREIGN_COMPANIES = 'FOREIGN_COMPANIES',
  HOSPITAL = 'HOSPITAL',
  MAJOR_COMPANY = 'MAJOR_COMPANY',
  MIDSIZE_BUSINESS = 'MIDSIZE_BUSINESS',
  NON_PROFIT_ORGANIZATION = 'NON_PROFIT_ORGANIZATION',
  PUBLIC_INSTITUTIONS = 'PUBLIC_INSTITUTIONS',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
  START_UP = 'START_UP'
}

export type RecruitProcedureInput = {
  applicationFileAttachmentIds?: Maybe<Array<Scalars['ID']>>,
  applicationProcedures?: Maybe<Array<ApplicationProcedureInput>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypeId: Scalars['ID'],
  isApplicationProcedureVisible?: Maybe<Scalars['Boolean']>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType: ActivityRecruitType,
};

export enum RecruitScaleType {
  HUNDRED_DIGIT = 'HUNDRED_DIGIT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  ONE_DIGIT = 'ONE_DIGIT',
  TEN_DIGIT = 'TEN_DIGIT'
}

export type RecuritJogType = {
   __typename?: 'RecuritJogType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type RefreshAccessTokenPayload = {
   __typename?: 'RefreshAccessTokenPayload',
  accessToken?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  refreshToken?: Maybe<Scalars['String']>,
};

export type Region = {
   __typename?: 'Region',
  districts?: Maybe<Array<Maybe<RegionDistrict>>>,
  highschools?: Maybe<Array<Maybe<Highschool>>>,
  id: Scalars['ID'],
  level?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  universities?: Maybe<Array<Maybe<University>>>,
  users?: Maybe<Array<Maybe<User>>>,
};

export type RegionDistrict = {
   __typename?: 'RegionDistrict',
  highschools?: Maybe<Array<Maybe<Highschool>>>,
  id: Scalars['ID'],
  name: Scalars['String'],
  region: Region,
};

export type RegionsFilters = {
  excludeUnrestricted?: Maybe<Scalars['Boolean']>,
};

export type RemoveAllBookmarkPayload = {
   __typename?: 'RemoveAllBookmarkPayload',
  count: Scalars['Int'],
};

export type RemoveBookmarkPayload = {
   __typename?: 'RemoveBookmarkPayload',
  newsItem?: Maybe<NewsItem>,
};

export type RemoveBookmarksPayload = {
   __typename?: 'RemoveBookmarksPayload',
  newsItems: Array<Maybe<NewsItem>>,
};

export type RemoveCommunityBookmarkPayLoad = {
   __typename?: 'removeCommunityBookmarkPayLoad',
  bookmarkable?: Maybe<CommunityBookmarkable>,
};

export type RemoveCommunityMenuBookmarkPayLoad = {
   __typename?: 'removeCommunityMenuBookmarkPayLoad',
  bookmarkable?: Maybe<CommunityBookmarkable>,
};

export type RemoveLikePayload = {
   __typename?: 'RemoveLikePayload',
  newsItem?: Maybe<NewsItem>,
};

export type ReorderChatRoomsOnTopInput = {
  chatRoomIds: Array<Scalars['ID']>,
};

export type ReorderChatRoomsOnTopPayload = {
   __typename?: 'ReorderChatRoomsOnTopPayload',
  chatRooms: Array<ChatRoom>,
};

export type Reply = {
   __typename?: 'Reply',
  activity?: Maybe<Activity>,
  avatarUrl?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  hasLiked: Scalars['Boolean'],
  id: Scalars['ID'],
  isDeleted: Scalars['Boolean'],
  isManagerReply: Scalars['Boolean'],
  isOwnerReply: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
  parent?: Maybe<Reply>,
  replies?: Maybe<Array<Maybe<Reply>>>,
  user?: Maybe<User>,
};


export type ReplyRepliesArgs = {
  pagination?: Maybe<Pagination>
};

export type ReplyConnection = {
   __typename?: 'ReplyConnection',
  nodes: Array<Reply>,
  totalCount: Scalars['Int'],
};

export type ReplyCreateInput = {
  content: Scalars['String'],
  pageID: Scalars['Int'],
  pageType: Scalars['Int'],
  replyID?: Maybe<Scalars['Int']>,
};

export type ReplyCreatePayload = {
   __typename?: 'ReplyCreatePayload',
  reply?: Maybe<Reply>,
};

export type ReplyDeleteInput = {
  replyID: Scalars['ID'],
};

export type ReplyDeletePayload = {
   __typename?: 'ReplyDeletePayload',
  reply?: Maybe<Reply>,
};

export type ReplyFilters = {
  activityID?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>,
  pageID?: Maybe<Scalars['ID']>,
  pageType?: Maybe<Scalars['Int']>,
  parentID?: Maybe<Scalars['ID']>,
  q?: Maybe<Scalars['String']>,
};

export type ReplyLike = {
   __typename?: 'ReplyLike',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  reply?: Maybe<Reply>,
  user?: Maybe<User>,
};

export type ReplyLikeInput = {
  liked: Scalars['Boolean'],
  replyID: Scalars['ID'],
};

export type ReplyLikePayload = {
   __typename?: 'ReplyLikePayload',
  replyLike?: Maybe<ReplyLike>,
};

export type ReplyListInput = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  pageID: Scalars['ID'],
  pageType: Scalars['Int'],
};

export type ReplyListPayload = {
   __typename?: 'ReplyListPayload',
  count: Scalars['Int'],
  replies?: Maybe<Array<Reply>>,
};

export type ReplyOrder = {
  direction: OrderDirection,
  field: ReplyOrderField,
};

export enum ReplyOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type ReplyQnaInput = {
  activityStatus?: Maybe<ActivityStatus>,
  replyCount?: Maybe<Scalars['Int']>,
  withClosed?: Maybe<Scalars['Boolean']>,
};

export type ReplyToActivityNotification = SimpleNotification & {
   __typename?: 'ReplyToActivityNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  reply?: Maybe<Reply>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ReplyToReplyNotification = SimpleNotification & {
   __typename?: 'ReplyToReplyNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  reply?: Maybe<Reply>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type Reportable = {
  lastReportReason?: Maybe<Scalars['String']>,
  lastReportedAt?: Maybe<Scalars['Date']>,
  reportCount: Scalars['Int'],
  viewerHasReported: Scalars['Boolean'],
};

export type ReportChatMessageInput = {
  reason: Scalars['String'],
};

export type ReportChatMessagePayload = {
   __typename?: 'ReportChatMessagePayload',
  chatMessage: ChatMessage,
};

export enum ReportedChatMessageSearchField {
  CONTENT = 'CONTENT',
  CHAT_NICKNAME = 'CHAT_NICKNAME',
  CHAT_ROOM_NAME = 'CHAT_ROOM_NAME'
}

export type ReportedChatMessageSearchInput = {
  searchField: ReportedChatMessageSearchField,
  searchKeyword: Scalars['String'],
};

export type ReportedNewsCommentFilter = {
  lastReportedAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

export type ReportedNewsItemFilter = {
  lastReportedAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

export type ReportedReasonAndCount = {
   __typename?: 'ReportedReasonAndCount',
  reason: Scalars['String'],
  count: Scalars['Int'],
};

export type ReportInput = {
  reason?: Maybe<Scalars['String']>,
  reportedId: Scalars['ID'],
};

export type ReportPayload = {
   __typename?: 'ReportPayload',
  result?: Maybe<Reportable>,
};

export type Resume = {
   __typename?: 'Resume',
  address?: Maybe<Scalars['String']>,
  birthday?: Maybe<Scalars['Date']>,
  certifications?: Maybe<Array<Maybe<Certification>>>,
  createdAt: Scalars['Date'],
  education: EducationType,
  email?: Maybe<Scalars['String']>,
  examedAt?: Maybe<Scalars['Date']>,
  gender?: Maybe<Gender>,
  highschools?: Maybe<Array<Maybe<HighschoolProfile>>>,
  id: Scalars['ID'],
  isFirst: Scalars['Boolean'],
  isMain: Scalars['Boolean'],
  isPC: Scalars['Boolean'],
  languages?: Maybe<Array<Maybe<Language>>>,
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  speciality?: Maybe<Scalars['String']>,
  status: Scalars['Boolean'],
  techniques?: Maybe<Array<Maybe<Technique>>>,
  tendencies?: Maybe<Array<Maybe<Tendency>>>,
  title: Scalars['String'],
  updatedAt: Scalars['Date'],
  user: User,
};

export type Reward = {
   __typename?: 'Reward',
  from?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  name: Scalars['String'],
  to?: Maybe<Scalars['Int']>,
};

export type RollbackQnASnapshotPayload = {
   __typename?: 'RollbackQnASnapshotPayload',
  snapshot?: Maybe<QnASnapshot>,
};

export enum SameExamInfoType {
  ALL = 'ALL',
  SUBSIDIARY_JOB = 'SUBSIDIARY_JOB'
}

export type SaveFcmTokenInput = {
  appVersion?: Maybe<Scalars['String']>,
  platform: Platform,
  token: Scalars['String'],
};

export type SaveFcmTokenPayload = {
   __typename?: 'SaveFcmTokenPayload',
  createdAt?: Maybe<Scalars['Date']>,
  token?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type School = {
   __typename?: 'School',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type SchoolCount = {
   __typename?: 'SchoolCount',
  schoolId: Scalars['ID'],
  schoolName: Scalars['String'],
  userCount: Scalars['Int'],
};

export enum SchoolStatus {
  BEING_SCHOOL = 'BEING_SCHOOL',
  DROP_SCHOOL = 'DROP_SCHOOL',
  GRADUATE = 'GRADUATE',
  REST_SCHOOL = 'REST_SCHOOL',
  SOON = 'SOON'
}

export enum SchoolType {
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  UNIVERSITY = 'UNIVERSITY'
}

export type ScoreRangeInput = {
  from?: Maybe<Scalars['Int']>,
  to?: Maybe<Scalars['Int']>,
};

export type ScrapActivityCloseSoonerNotification = SimpleNotification & {
   __typename?: 'ScrapActivityCloseSoonerNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ScrapActivityCloseSoonNotification = SimpleNotification & {
   __typename?: 'ScrapActivityCloseSoonNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ScrapActivityFilter = {
  q?: Maybe<Scalars['String']>,
};

export type SearchChatRoomsByNameWithOffsetFilterBy = {
  activityTypeID?: Maybe<Scalars['Int']>,
  fixedChatRoom?: Maybe<Scalars['Boolean']>,
  chatRoomType?: Maybe<ChatRoomType>,
};

export type SearchExactCoverLetterPayload = {
   __typename?: 'SearchExactCoverLetterPayload',
  count: Scalars['Int'],
  query?: Maybe<Scalars['String']>,
};

export enum SearchKeywordFieldType {
  KEYWORD = 'KEYWORD',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  ROLE = 'ROLE'
}

export type SearchMajorFilters = {
  query: Scalars['String'],
};

export enum SearchPostFromBoardWordField {
  CONTENT = 'CONTENT',
  TITLE = 'TITLE',
  TITLE_WITH_CONTENT = 'TITLE_WITH_CONTENT'
}

export type SearchSchoolFilters = {
  query: Scalars['String'],
  type?: Maybe<SchoolType>,
};

export type SearchSelfIntroductionFilters = {
  searchText?: Maybe<Scalars['String']>,
};

export type SearchUniqueNameOrganizationFilter = {
  includeGroup?: Maybe<Scalars['Boolean']>,
  query: Scalars['String'],
};

export type SearchUniqueNameOrganizationOrder = {
  direction: OrderDirection,
  field: SearchUniqueNameOrganizationOrderField,
};

export enum SearchUniqueNameOrganizationOrderField {
  RELEVANCE = 'RELEVANCE',
  SCORE = 'SCORE'
}

export type SelectExamRestoredAnswerInput = {
  answer: Scalars['Int'],
  questionId: Scalars['ID'],
};

export type SelectExamRestoredAnswerPayload = {
   __typename?: 'SelectExamRestoredAnswerPayload',
  examRestoredAnswer: ExamRestoredAnswer,
};

export type SelfIntroduction = {
   __typename?: 'SelfIntroduction',
  activity?: Maybe<Activity>,
  author?: Maybe<User>,
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  duty?: Maybe<Duty>,
  folder?: Maybe<Folder>,
  id: Scalars['ID'],
  qna?: Maybe<Array<QnA>>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type SelfIntroductionConnection = {
   __typename?: 'SelfIntroductionConnection',
  edges: Array<SelfIntroductionEdge>,
  nodes: Array<Maybe<SelfIntroduction>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type SelfIntroductionEdge = {
   __typename?: 'SelfIntroductionEdge',
  cursor: Scalars['String'],
  node: SelfIntroduction,
};

export type SelfIntroductionFilters = {
  dutyId?: Maybe<Scalars['ID']>,
  folderId?: Maybe<Scalars['ID']>,
};

export type SelfIntroductionOrder = {
  direction: OrderDirection,
  field: SelfIntroductionOrderField,
};

export enum SelfIntroductionOrderField {
  CREATED_AT = 'CREATED_AT',
  EXPIRED_AT = 'EXPIRED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type SelfIntroductionStatistic = {
   __typename?: 'SelfIntroductionStatistic',
  activityCount: Scalars['Int'],
  activityWithQuestionCount: Scalars['Int'],
  createdAt: Scalars['Date'],
  date: Scalars['String'],
  id: Scalars['ID'],
  selfIntroductionCount: Scalars['Int'],
  selfIntroductionUserCount: Scalars['Int'],
};

export type SelfIntroductionStatisticConnection = {
   __typename?: 'SelfIntroductionStatisticConnection',
  nodes: Array<SelfIntroductionStatistic>,
  totalCount: Scalars['Int'],
};

export type SelfIntroductionStatisticFilters = {
  dateRange?: Maybe<DateRange>,
  dateTerm?: Maybe<DateTerm>,
};

export type SelfIntroductionStatus = {
   __typename?: 'SelfIntroductionStatus',
  totalCount: Scalars['Int'],
};

export type SendJobPostingDataToNaverPayload = {
   __typename?: 'SendJobPostingDataToNaverPayload',
  data?: Maybe<Scalars['String']>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type SendMailSelfIntroductionInput = {
  receiverAddress: Scalars['String'],
  selfIntroductionId: Scalars['ID'],
  senderAddress: Scalars['String'],
};

export type SendMailSelfIntroductionPayload = {
   __typename?: 'SendMailSelfIntroductionPayload',
  selfIntroduction?: Maybe<SelfIntroduction>,
};

export type SetNewsItemAsNoticePayload = {
   __typename?: 'SetNewsItemAsNoticePayload',
  newsItem?: Maybe<NewsItem>,
};

export type SetNoticeAsNewsItemPayload = {
   __typename?: 'SetNoticeAsNewsItemPayload',
  newsItem?: Maybe<NewsItem>,
};

export type ShareChatRoomInput = {
  platform: SharePlatform,
};

export type ShareChatRoomPayload = {
   __typename?: 'ShareChatRoomPayload',
  chatRoom: ChatRoom,
};

export enum SharePlatform {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  NAVER = 'NAVER',
  NAVERBAND = 'NAVERBAND',
  KAKAOSTORY = 'KAKAOSTORY',
  KAKAO = 'KAKAO',
  URL = 'URL'
}

export enum SharePlatFormType {
  FACEBOOK = 'FACEBOOK',
  KAKAO = 'KAKAO',
  KAKAOSTORY = 'KAKAOSTORY',
  NAVER = 'NAVER',
  NAVERBAND = 'NAVERBAND',
  TWITTER = 'TWITTER',
  URL = 'URL'
}

export type SignupInput = {
  birthday: Scalars['Date'],
  education: UserEducationInput,
  email: Scalars['String'],
  nickname: Scalars['String'],
  phoneNumber: Scalars['String'],
  preferActivityTypes: Array<Scalars['Int']>,
  preferJobCategoryIds: Array<Scalars['ID']>,
  preferOrganizationIds?: Maybe<Array<Scalars['ID']>>,
  signupToken: Scalars['String'],
  subscribeEmail: Scalars['Boolean'],
  subscribeSMS: Scalars['Boolean'],
};

export type SignupSession = {
   __typename?: 'SignupSession',
  createdAt?: Maybe<Scalars['Date']>,
  defaultEmail?: Maybe<Scalars['String']>,
  defaultNickname?: Maybe<Scalars['String']>,
  displayName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  ipAddress?: Maybe<Scalars['String']>,
  profileURL?: Maybe<Scalars['String']>,
  signup: Scalars['Boolean'],
  signupAt?: Maybe<Scalars['Date']>,
  signupElapsedTimeInSeconds?: Maybe<Scalars['Int']>,
  signupSession?: Maybe<Scalars['String']>,
  signupUser?: Maybe<User>,
  snsID?: Maybe<Scalars['String']>,
  snsType?: Maybe<Scalars['String']>,
  timespread?: Maybe<TimespreadAccount>,
  token?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export type SignupSessionConnection = {
   __typename?: 'SignupSessionConnection',
  nodes?: Maybe<Array<Maybe<SignupSession>>>,
  totalCount: Scalars['Int'],
};

export type SignupSessionFilters = {
  q?: Maybe<Scalars['String']>,
};

export type SignupSessionOrder = {
  direction: OrderDirection,
  field: SignupSessionOrderField,
};

export enum SignupSessionOrderField {
  CREATED_AT = 'CREATED_AT'
}

export type SimpleNotification = {
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type Skill = {
   __typename?: 'Skill',
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
  users?: Maybe<Array<Maybe<User>>>,
};

export type SkillTag = {
   __typename?: 'SkillTag',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type SkipSignupSessionInput = {
  signupToken: Scalars['String'],
};

export type SkipSignupSessionPayload = {
   __typename?: 'SkipSignupSessionPayload',
  user?: Maybe<User>,
};

export enum SnsType {
  FACEBOOK = 'FACEBOOK',
  KAKAO = 'KAKAO',
  NAVER = 'NAVER'
}

export type SpellCheckInput = {
  text: Scalars['String'],
};

export type SpellCheckPayload = {
   __typename?: 'SpellCheckPayload',
  result: SpellCheckResult,
};

export type SpellCheckResult = {
   __typename?: 'SpellCheckResult',
  errorWords: Array<ErrorWord>,
  originalSentence: Scalars['String'],
};

export type SpellCheckWithAutoSplitInput = {
  text: Scalars['String'],
};

export type SpellCheckWithAutoSplitPayload = {
   __typename?: 'SpellCheckWithAutoSplitPayload',
  result: Array<SpellCheckResult>,
};

export type SubmitExamGradingAnswer = {
  subject: ExamSubject,
  submitAnswerItems: Array<SubmitExamGradingAnswerItem>,
};

export type SubmitExamGradingAnswerItem = {
  answers: Array<Scalars['Int']>,
  questionId: Scalars['ID'],
};

export type SubmitExamSurveyInput = {
  inputs: Array<CreateExamSurveyUserAnswerInput>,
  surveyId: Scalars['ID'],
  testId: Scalars['ID'],
};

export type SubmitExamSurveyPayload = {
   __typename?: 'SubmitExamSurveyPayload',
  success: Scalars['Boolean'],
};

export type Suggestion = {
   __typename?: 'Suggestion',
  attachments: AttachmentConnection,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  deviceInfo: DeviceType,
  email: Scalars['String'],
};

export type Tag = {
   __typename?: 'Tag',
  id: Scalars['ID'],
  mentionCount: Scalars['Int'],
  name: Scalars['String'],
};

export type TagCollection = {
   __typename?: 'TagCollection',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
  tags: TagConnection,
  title: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type TagConnection = {
   __typename?: 'TagConnection',
  edges: Array<TagEdge>,
  nodes: Array<Tag>,
  totalCount: Scalars['Int'],
};

export type TagEdge = {
   __typename?: 'TagEdge',
  cursor: Scalars['String'],
  node?: Maybe<Tag>,
};

export type Technique = {
   __typename?: 'Technique',
  count: Scalars['Int'],
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  tech: Scalars['String'],
  workfield?: Maybe<Workfield>,
};

export type Tendency = {
   __typename?: 'Tendency',
  content: Scalars['String'],
  count: Scalars['Int'],
  id: Scalars['ID'],
};

export type TimespreadAccount = {
   __typename?: 'TimespreadAccount',
  birthday?: Maybe<Scalars['Date']>,
  cash?: Maybe<Scalars['Int']>,
  email?: Maybe<Scalars['String']>,
  facebookID?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  kakaoID?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  naverID?: Maybe<Scalars['ID']>,
  phoneNumber?: Maybe<Scalars['String']>,
  profileURL?: Maybe<Scalars['String']>,
};

export enum TimeSpreadApiResponseType {
  LUCKYBOX_ALREADY_OPEN = 'LUCKYBOX_ALREADY_OPEN',
  LUCKYBOX_LIMIT_EXCEED = 'LUCKYBOX_LIMIT_EXCEED',
  LUCKYBOX_NEED_CASH = 'LUCKYBOX_NEED_CASH',
  OBJECT_NOT_EXIST = 'OBJECT_NOT_EXIST',
  OK = 'OK',
  PHONE_ALREADY_AUTHORIZE = 'PHONE_ALREADY_AUTHORIZE',
  PHONE_CODE_DATA_NOT_FOUND = 'PHONE_CODE_DATA_NOT_FOUND',
  PHONE_CODE_REQUEST_EXCEED = 'PHONE_CODE_REQUEST_EXCEED',
  PHONE_FAIL_AUTHORIZE = 'PHONE_FAIL_AUTHORIZE',
  PHONE_NOT_ACCEPTED_IP = 'PHONE_NOT_ACCEPTED_IP',
  PHONE_REQUEST_EXCEED = 'PHONE_REQUEST_EXCEED',
  PHONE_USER_DELETE = 'PHONE_USER_DELETE',
  REQUIRE_PARAMETER = 'REQUIRE_PARAMETER',
  SCREEN_ALREADY_FINISH = 'SCREEN_ALREADY_FINISH'
}

export type TokenSet = {
   __typename?: 'TokenSet',
  accessToken?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  refreshToken?: Maybe<Scalars['String']>,
  signupToken?: Maybe<Scalars['String']>,
};

export type TopFollowingChannelConnection = {
   __typename?: 'TopFollowingChannelConnection',
  edges: Array<TopFollowingChannelEdge>,
  nodes: Array<Maybe<Channel>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TopFollowingChannelEdge = {
   __typename?: 'TopFollowingChannelEdge',
  cursor: Scalars['String'],
  dailyFollowerCount: Scalars['Int'],
  daliyFollowerCount: Scalars['Int'],
  node: Channel,
};

export type UnbanUsersInChatPayload = {
   __typename?: 'UnbanUsersInChatPayload',
  userBans: Array<UserBanInChat>,
};

export type UnFollowPayload = {
   __typename?: 'UnFollowPayload',
  channel?: Maybe<Channel>,
};

export type UnifiedAdPlacementDto = {
   __typename?: 'UnifiedAdPlacementDto',
  code: Scalars['String'],
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type UnifiedAdPlacementGroupDto = {
   __typename?: 'UnifiedAdPlacementGroupDto',
  id: Scalars['ID'],
  name: Scalars['String'],
  unifiedAdPlacements: Array<UnifiedAdPlacementDto>,
};

export type UniqueNameOrganization = {
   __typename?: 'UniqueNameOrganization',
  createdAt: Scalars['Date'],
  group?: Maybe<UniqueNameOrganizationGroup>,
  id: Scalars['ID'],
  isGroup?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type UniqueNameOrganizationConnection = {
   __typename?: 'UniqueNameOrganizationConnection',
  nodes: Array<UniqueNameOrganization>,
  totalCount: Scalars['Int'],
};

export type UniqueNameOrganizationGroup = {
   __typename?: 'UniqueNameOrganizationGroup',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type University = {
   __typename?: 'University',
  count: Scalars['Int'],
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  name: Scalars['String'],
  region?: Maybe<Region>,
};

export type UnmuteUserInput = {
  mutedUserId: Scalars['ID'],
  newsCommentId?: Maybe<Scalars['ID']>,
};

export type UnmuteUserPayload = {
   __typename?: 'UnmuteUserPayload',
  newsComment?: Maybe<NewsComment>,
  user?: Maybe<User>,
};

export type UpdateAdPlacementInput = {
  code?: Maybe<Scalars['String']>,
  defaultImage?: Maybe<Scalars['Upload']>,
  defaultLandingUrl?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  isActive?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Int']>,
};

export type UpdateAdPlacementPayload = {
   __typename?: 'UpdateAdPlacementPayload',
  adPlacement: AdPlacement,
};

export type UpdateCampaignGroupInputDto = {
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type UpdateCampaignGroupPayloadDto = {
   __typename?: 'UpdateCampaignGroupPayloadDto',
  campaignGroup: CampaignGroupDto,
};

export type UpdateCampaignInput = {
  activityId?: Maybe<Scalars['ID']>,
  advertiser?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  endAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  landingUrl?: Maybe<Scalars['String']>,
  mobileImage?: Maybe<Scalars['Upload']>,
  order?: Maybe<Scalars['Int']>,
  pcImage?: Maybe<Scalars['Upload']>,
  startAt?: Maybe<Scalars['Date']>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateCampaignPayload = {
   __typename?: 'UpdateCampaignPayload',
  campaign: Campaign,
};

export type UpdateCareerAdvantageConditionInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  employmentSupportTargetIds?: Maybe<Array<Scalars['ID']>>,
  handicapLevel?: Maybe<Scalars['Int']>,
  isEmploymentSupportTarget?: Maybe<Scalars['Boolean']>,
  isHandicap?: Maybe<Scalars['Boolean']>,
  isMilitary?: Maybe<Scalars['Boolean']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isVeteran?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  militaryEndYearMonth?: Maybe<Scalars['Int']>,
  militaryRank?: Maybe<MilitaryRank>,
  militaryStartYearMonth?: Maybe<Scalars['Int']>,
  militaryStatus?: Maybe<MilitaryStatus>,
  militaryTarget?: Maybe<MilitaryTarget>,
  militaryType?: Maybe<MilitaryType>,
  veteranReason?: Maybe<Scalars['String']>,
};

export type UpdateCareerAdvantageConditionPayload = {
   __typename?: 'UpdateCareerAdvantageConditionPayload',
  careerAdvantageCondition: CareerAdvantageCondition,
};

export type UpdateCareerAwardInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  itemInputs: Array<UpdateCareerAwardItemInput>,
};

export type UpdateCareerAwardItemInput = {
  acquiredDate?: Maybe<Scalars['Date']>,
  content?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  institutionName?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
};

export type UpdateCareerAwardPayload = {
   __typename?: 'UpdateCareerAwardPayload',
  careerAward: CareerAward,
};

export type UpdateCareerExperienceInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  itemInputs: Array<UpdateCareerExperienceItemInput>,
};

export type UpdateCareerExperienceItemInput = {
  dutyIds: Array<Scalars['ID']>,
  endYearMonth?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['ID']>,
  inOffice?: Maybe<Scalars['Boolean']>,
  isExternal: Scalars['Boolean'],
  isOrganizationNamePrivate?: Maybe<Scalars['Boolean']>,
  jobPositionId?: Maybe<Scalars['ID']>,
  jobRankId?: Maybe<Scalars['ID']>,
  organizationName?: Maybe<Scalars['String']>,
  pay?: Maybe<Scalars['Int']>,
  performancePart?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  startYearMonth?: Maybe<Scalars['Int']>,
};

export type UpdateCareerExperiencePayload = {
   __typename?: 'UpdateCareerExperiencePayload',
  careerExperience: CareerExperience,
};

export type UpdateCareerExternshipInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  itemInputs: Array<UpdateCareerExternshipItemInput>,
};

export type UpdateCareerExternshipItemInput = {
  content?: Maybe<Scalars['String']>,
  endYearMonth?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['ID']>,
  inProgress?: Maybe<Scalars['Boolean']>,
  institutionName?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  startYearMonth?: Maybe<Scalars['Int']>,
  type?: Maybe<CareerExternshipType>,
  url?: Maybe<Scalars['String']>,
};

export type UpdateCareerExternshipPayload = {
   __typename?: 'UpdateCareerExternshipPayload',
  careerExternship: CareerExternship,
};

export type UpdateCareerIntroductionInput = {
  briefContent?: Maybe<Scalars['String']>,
  careerProfileId?: Maybe<Scalars['ID']>,
  content?: Maybe<Scalars['String']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
};

export type UpdateCareerIntroductionPayload = {
   __typename?: 'UpdateCareerIntroductionPayload',
  careerIntroduction: CareerIntroduction,
};

export type UpdateCareerJobPreferenceInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  decidedAfterInterview?: Maybe<Scalars['Boolean']>,
  decidedByOrganizationPolicy?: Maybe<Scalars['Boolean']>,
  dutyIds: Array<Scalars['ID']>,
  industryIds: Array<Scalars['ID']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  pay?: Maybe<Scalars['Int']>,
  regionDistrictIds: Array<Scalars['ID']>,
};

export type UpdateCareerJobPreferencePayload = {
   __typename?: 'UpdateCareerJobPreferencePayload',
  careerJobPreference: CareerJobPreference,
};

export type UpdateCareerLanguageInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  itemInputs: Array<UpdateCareerLanguageItemInput>,
};

export type UpdateCareerLanguageItemInput = {
  acquiredDate?: Maybe<Scalars['Date']>,
  id?: Maybe<Scalars['ID']>,
  level?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['Int']>,
  seq: Scalars['Int'],
  status?: Maybe<CareerLanguageConversationStatus>,
  type: CareerLanguageType,
};

export type UpdateCareerLanguagePayload = {
   __typename?: 'UpdateCareerLanguagePayload',
  careerLanguage: CareerLanguage,
};

export type UpdateCareerLicenseInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  itemInputs: Array<UpdateCareerLicenseItemInput>,
};

export type UpdateCareerLicenseItemInput = {
  acquiredDate?: Maybe<Scalars['Date']>,
  id?: Maybe<Scalars['ID']>,
  institutionName?: Maybe<Scalars['String']>,
  isExternal: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
};

export type UpdateCareerLicensePayload = {
   __typename?: 'UpdateCareerLicensePayload',
  careerLicense: CareerLicense,
};

export type UpdateCareerPortfolioFileInput = {
  deleteCareerPortfolioFileIds?: Maybe<Array<Scalars['ID']>>,
  files?: Maybe<Array<Scalars['Upload']>>,
};

export type UpdateCareerPortfolioImageInput = {
  id?: Maybe<Scalars['ID']>,
  image?: Maybe<Scalars['Upload']>,
  seq: Scalars['Int'],
};

export type UpdateCareerPortfolioInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  fileInput: UpdateCareerPortfolioFileInput,
  imageInputs: Array<UpdateCareerPortfolioImageInput>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  urlInputs: Array<UpdateCareerPortfolioUrlInput>,
};

export type UpdateCareerPortfolioPayload = {
   __typename?: 'UpdateCareerPortfolioPayload',
  careerPortfolio: CareerPortfolio,
};

export type UpdateCareerPortfolioUrlInput = {
  id?: Maybe<Scalars['ID']>,
  type: CareerPortfolioUrlType,
  url: Scalars['String'],
};

export type UpdateCareerProfileInput = {
  id: Scalars['ID'],
  isSharing?: Maybe<Scalars['Boolean']>,
  template?: Maybe<CareerProfileTemplate>,
  theme?: Maybe<CareerProfileTheme>,
};

export type UpdateCareerProfileOrderInput = {
  advantageConditionOrder: Scalars['Int'],
  awardOrder: Scalars['Int'],
  careerProfileId: Scalars['ID'],
  experienceOrder: Scalars['Int'],
  externshipOrder: Scalars['Int'],
  jobPreferenceOrder: Scalars['Int'],
  languageOrder: Scalars['Int'],
  licenseOrder: Scalars['Int'],
  portfolioOrder: Scalars['Int'],
  schoolOrder: Scalars['Int'],
  skillOrder: Scalars['Int'],
};

export type UpdateCareerProfileOrderPayload = {
   __typename?: 'UpdateCareerProfileOrderPayload',
  careerProfileOrder: CareerProfileOrder,
};

export type UpdateCareerProfilePayload = {
   __typename?: 'UpdateCareerProfilePayload',
  careerProfile: CareerProfile,
};

export type UpdateCareerSchoolInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  itemInputs: Array<UpdateCareerSchoolItemInput>,
};

export type UpdateCareerSchoolItemInput = {
  entranceYearMonth?: Maybe<Scalars['Int']>,
  gradePerfectScore?: Maybe<Scalars['Float']>,
  gradeScore?: Maybe<Scalars['Float']>,
  graduationStatus?: Maybe<CareerSchoolGraduationStatus>,
  graduationYearMonth?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['ID']>,
  isTransfer?: Maybe<Scalars['Boolean']>,
  majorInputs: Array<UpdateCareerSchoolMajorInput>,
  schoolId?: Maybe<Scalars['ID']>,
  seq: Scalars['Int'],
  type?: Maybe<CareerSchoolType>,
};

export type UpdateCareerSchoolMajorInput = {
  majorId: Scalars['ID'],
  type: CareerSchoolMajorType,
};

export type UpdateCareerSchoolPayload = {
   __typename?: 'UpdateCareerSchoolPayload',
  careerSchool: CareerSchool,
};

export type UpdateCareerSkillInput = {
  careerProfileId?: Maybe<Scalars['ID']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  isWritten?: Maybe<Scalars['Boolean']>,
  skillTagIds: Array<Scalars['ID']>,
};

export type UpdateCareerSkillPayload = {
   __typename?: 'UpdateCareerSkillPayload',
  careerSkill: CareerSkill,
};

export type UpdateCareerUserInformationInput = {
  address?: Maybe<Scalars['String']>,
  addressDetail?: Maybe<Scalars['String']>,
  bcode?: Maybe<Scalars['String']>,
  birthday?: Maybe<Scalars['Date']>,
  bname?: Maybe<Scalars['String']>,
  careerProfileId?: Maybe<Scalars['ID']>,
  email?: Maybe<Scalars['String']>,
  gender?: Maybe<Gender>,
  isWritten?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  profileImage?: Maybe<Scalars['Upload']>,
  sido?: Maybe<Scalars['String']>,
  sigungu?: Maybe<Scalars['String']>,
  sigunguCode?: Maybe<Scalars['String']>,
  zoneCode?: Maybe<Scalars['String']>,
};

export type UpdateCareerUserInformationPayload = {
   __typename?: 'UpdateCareerUserInformationPayload',
  careerUserInformation: CareerUserInformation,
};

export type UpdateChannelAndOrganizationPayload = {
   __typename?: 'UpdateChannelAndOrganizationPayload',
  channel?: Maybe<Channel>,
  organization?: Maybe<Organization>,
};

export type UpdateChannelInput = {
  briefDescription?: Maybe<Scalars['String']>,
  coverAttachmentId?: Maybe<Scalars['ID']>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  tagNames?: Maybe<Array<Scalars['String']>>,
};

export type UpdateChannelPayload = {
   __typename?: 'UpdateChannelPayload',
  channel?: Maybe<Channel>,
};

export type UpdateChannelViewCountPayload = {
   __typename?: 'UpdateChannelViewCountPayload',
  channel?: Maybe<Channel>,
};

export type UpdateChatRoomNotificationInput = {
  chatRoomId: Scalars['ID'],
  enableNotification: Scalars['Boolean'],
};

export type UpdateChatRoomNotificationPayload = {
   __typename?: 'UpdateChatRoomNotificationPayload',
  id: Scalars['ID'],
  memberId: Scalars['Int'],
  chatRoomId: Scalars['Int'],
  enableNotification: Scalars['Boolean'],
};

export type UpdateChatSubscribeInput = {
  subscribeChatNotification: Scalars['Boolean'],
};

export type UpdateCommunityBoardUrlInput = {
  communityBoardUrlId: Scalars['ID'],
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type UpdateCommunityMentorPostInput = {
  duties?: Maybe<Array<Maybe<PostDutyV2>>>,
  dutyId?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  organizationGroupName?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizations?: Maybe<Array<Maybe<PostOrganizationV2>>>,
  parentDutyId?: Maybe<Scalars['ID']>,
  rootDutyId?: Maybe<Scalars['ID']>,
  schoolName?: Maybe<Scalars['String']>,
  schoolNames?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type UpdateCommunityPostRelatedWithActivityInput = {
  communityPostId?: Maybe<Scalars['ID']>,
  communityPostRelatedWithActivityId?: Maybe<Scalars['ID']>,
  seq?: Maybe<Scalars['Int']>,
};

export type UpdateCoverLetterFieldByQueryInput = {
  field: CoverLetterField,
  newName?: Maybe<Scalars['String']>,
  oldName: Scalars['String'],
};

export type UpdateCoverLetterFieldByQueryPayload = {
   __typename?: 'UpdateCoverLetterFieldByQueryPayload',
  updatedCoverLetters?: Maybe<Array<Maybe<CoverLetter>>>,
};

export type UpdateCoverLetterRelatedWithActivityInput = {
  coverLetterId?: Maybe<Scalars['ID']>,
  coverLetterRelatedWithActivityId?: Maybe<Scalars['ID']>,
  seq?: Maybe<Scalars['Int']>,
};

export type UpdateCurrentUserInput = {
  acceptedMentorCommentReward?: Maybe<Scalars['Boolean']>,
  birthday?: Maybe<Scalars['Date']>,
  email: Scalars['String'],
  matchedMentorCommentReward?: Maybe<Scalars['Boolean']>,
  mentorPost?: Maybe<Scalars['Boolean']>,
  nickname: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  profileURL?: Maybe<Scalars['String']>,
  recommendedMentorPost?: Maybe<Scalars['Boolean']>,
  smsAuthedToken?: Maybe<Scalars['String']>,
  subscribeComment?: Maybe<Scalars['Boolean']>,
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeInfo?: Maybe<Scalars['Boolean']>,
  subscribePost?: Maybe<Scalars['Boolean']>,
  subscribeSMS?: Maybe<Scalars['Boolean']>,
  subscribeScrap?: Maybe<Scalars['Boolean']>,
};

export type UpdateCurrentUserPayload = {
   __typename?: 'UpdateCurrentUserPayload',
  user?: Maybe<User>,
};

export type UpdateDetailedActivityInput = {
  activityEndAt?: Maybe<Scalars['Date']>,
  activityID: Scalars['ID'],
  activityStartAt?: Maybe<Scalars['Date']>,
  activityTypeID: Scalars['ID'],
  additionalBenefit?: Maybe<Scalars['String']>,
  applicationFileAttachmentIDs?: Maybe<Array<Scalars['ID']>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypeIDs?: Maybe<Array<Scalars['String']>>,
  awards?: Maybe<Scalars['String']>,
  benefits?: Maybe<Array<Scalars['ID']>>,
  categories?: Maybe<Array<Scalars['ID']>>,
  cost?: Maybe<Scalars['Int']>,
  country?: Maybe<Scalars['String']>,
  detailText?: Maybe<Scalars['String']>,
  detailTextID?: Maybe<Scalars['String']>,
  educationTypes?: Maybe<Array<EducationDetailType>>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  facetimeDay?: Maybe<Scalars['String']>,
  facetimePeriod?: Maybe<Scalars['String']>,
  facetimeTime?: Maybe<Scalars['String']>,
  facetimeWeekday?: Maybe<Scalars['String']>,
  interests?: Maybe<Array<Scalars['ID']>>,
  isManagerEmailVisible?: Maybe<Scalars['Boolean']>,
  isManagerPhoneNumberVisible?: Maybe<Scalars['Boolean']>,
  isOffline?: Maybe<Scalars['Boolean']>,
  isOnline?: Maybe<Scalars['Boolean']>,
  isSponsored?: Maybe<Scalars['Boolean']>,
  isSubmit?: Maybe<Scalars['Boolean']>,
  logoImage?: Maybe<Scalars['ID']>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName: Scalars['String'],
  organizationTypeID?: Maybe<Scalars['String']>,
  outlink?: Maybe<Scalars['String']>,
  percent?: Maybe<Scalars['String']>,
  posterAttachmentID?: Maybe<Scalars['String']>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType?: Maybe<ActivityRecruitType>,
  regionDistricts?: Maybe<Array<Scalars['ID']>>,
  regions?: Maybe<Array<Scalars['ID']>>,
  skills?: Maybe<Array<Scalars['ID']>>,
  targets?: Maybe<Array<Scalars['ID']>>,
  thumbnailAttachmentID?: Maybe<Scalars['ID']>,
  title: Scalars['String'],
  youtubeURL?: Maybe<Scalars['String']>,
};

export type UpdateDetailedActivityPayload = {
   __typename?: 'UpdateDetailedActivityPayload',
  activity: Activity,
};

export type UpdateDetailedRecruitInput = {
  activityId: Scalars['ID'],
  detailText?: Maybe<Scalars['String']>,
  detailTextId?: Maybe<Scalars['ID']>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  isAllSameQuestion?: Maybe<Scalars['Boolean']>,
  isSubmit: Scalars['Boolean'],
  manager: RecruitManagerInput,
  organization: RecruitOrganizationInput,
  qualification: ActivityQualificationInput,
  questionType?: Maybe<QuestionType>,
  recruitInformations: Array<RecruitInformationInput>,
  recruitProcedure: RecruitProcedureInput,
  title: Scalars['String'],
  workingCondition: WorkingConditionInput,
};

export type UpdateDetailedRecruitPayload = {
   __typename?: 'UpdateDetailedRecruitPayload',
  activity: Activity,
};

export type UpdateExamGradingQuestionInput = {
  answers?: Maybe<Array<Scalars['Int']>>,
  content?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  seq: Scalars['Int'],
  subject: ExamSubject,
};

export type UpdateExamReserveInformationInput = {
  companyId: Scalars['ID'],
  departmentId?: Maybe<Scalars['ID']>,
  hasAgreedToPrivacy?: Maybe<Scalars['Boolean']>,
  jobId?: Maybe<Scalars['ID']>,
  subsidiaryId?: Maybe<Scalars['ID']>,
  termId: Scalars['ID'],
  testId: Scalars['ID'],
};

export type UpdateExamReserveInformationPayload = {
   __typename?: 'UpdateExamReserveInformationPayload',
  updatedExamReserveInformation: ExamReserveInformation,
};

export type UpdateExamTestInput = {
  companyId?: Maybe<Scalars['ID']>,
  date?: Maybe<Scalars['Date']>,
  deleteGradingQuestionIds?: Maybe<Array<Scalars['ID']>>,
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  termId?: Maybe<Scalars['ID']>,
  updateGradingQuestions?: Maybe<Array<UpdateExamGradingQuestionInput>>,
};

export type UpdateExamTestPayload = {
   __typename?: 'UpdateExamTestPayload',
  examTest: ExamTest,
};

export type UpdateFolderInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ID']>,
};

export type UpdateFolderPayload = {
   __typename?: 'UpdateFolderPayload',
  folder: Folder,
};

export type UpdateJobCurationActivityInput = {
  activityId?: Maybe<Scalars['ID']>,
  endDate?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
  startDate?: Maybe<Scalars['Date']>,
  type?: Maybe<JobCurationActivityType>,
};

export type UpdateJobCurationActivityPayload = {
   __typename?: 'UpdateJobCurationActivityPayload',
  jobCurationActivity: JobCurationActivity,
};

export type UpdateJobCurationCenterBannerInput = {
  endDate?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  landingUrl?: Maybe<Scalars['String']>,
  mobileImage?: Maybe<Scalars['Upload']>,
  pcImage?: Maybe<Scalars['Upload']>,
  seq?: Maybe<Scalars['Int']>,
  startDate?: Maybe<Scalars['Date']>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateJobCurationCenterBannerPayload = {
   __typename?: 'UpdateJobCurationCenterBannerPayload',
  jobCurationCenterBanner: JobCurationCenterBanner,
};

export type UpdateJobCurationMentorBannerInput = {
  id: Scalars['ID'],
  landingUrl?: Maybe<Scalars['String']>,
  pcImage?: Maybe<Scalars['Upload']>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateJobCurationMentorBannerPayload = {
   __typename?: 'UpdateJobCurationMentorBannerPayload',
  jobCurationMentorBanner: JobCurationMentorBanner,
};

export type UpdateJobCurationOrganizationContentInput = {
  id: Scalars['ID'],
  image?: Maybe<Scalars['Upload']>,
  landingUrl?: Maybe<Scalars['String']>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateJobCurationOrganizationContentsInput = {
  inputs: Array<UpdateJobCurationOrganizationContentInput>,
};

export type UpdateJobCurationOrganizationContentsPayload = {
   __typename?: 'UpdateJobCurationOrganizationContentsPayload',
  jobCurationOrganizationContents: Array<JobCurationOrganizationContent>,
};

export type UpdateJobCurationPostInput = {
  id: Scalars['ID'],
  postId?: Maybe<Scalars['ID']>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateJobCurationPostsInput = {
  inputs: Array<UpdateJobCurationPostInput>,
};

export type UpdateJobCurationPostsPayload = {
   __typename?: 'UpdateJobCurationPostsPayload',
  jobCurationPosts: Array<JobCurationPost>,
};

export type UpdateManyCommunityBoardUrlPayload = {
   __typename?: 'UpdateManyCommunityBoardUrlPayload',
  communityBoardUrls?: Maybe<Array<Maybe<CommunityBoardUrl>>>,
};

export type UpdateManyCommunityBoardUrlsInput = {
  updateManyCommunityBoardUrls: Array<UpdateCommunityBoardUrlInput>,
};

export type UpdateManyCommunityPostRelatedWithActivityInput = {
  activityId: Scalars['ID'],
  updateCommunityPostRelatedWithActivityInputs: Array<Maybe<UpdateCommunityPostRelatedWithActivityInput>>,
};

export type UpdateManyCommunityPostRelatedWithActivityPayload = {
   __typename?: 'UpdateManyCommunityPostRelatedWithActivityPayload',
  communityPostsRelatedWithActivity?: Maybe<Array<Maybe<CommunityPostRelatedWithActivity>>>,
};

export type UpdateManyCoverLetterRelatedWithActivityInput = {
  activityId: Scalars['ID'],
  updateCoverLetterRelatedWithActivityInputs: Array<Maybe<UpdateCoverLetterRelatedWithActivityInput>>,
};

export type UpdateManyCoverLetterRelatedWithActivityPayload = {
   __typename?: 'UpdateManyCoverLetterRelatedWithActivityPayload',
  coverLettersRelatedWithActivity?: Maybe<Array<Maybe<CoverLetterRelatedWithActivity>>>,
};

export type UpdateManyRecommendSearchKeywordInput = {
  id?: Maybe<Scalars['ID']>,
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type: RecommendSearchKeywordType,
};

export type UpdateNewsCommentInput = {
  content: Scalars['String'],
  id: Scalars['ID'],
};

export type UpdateNewsCommentPayload = {
   __typename?: 'UpdateNewsCommentPayload',
  newsComment?: Maybe<NewsComment>,
};

export type UpdateNewsItemInput = {
  content?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  imageIds?: Maybe<Array<Scalars['ID']>>,
  tagNames?: Maybe<Array<Scalars['String']>>,
};

export type UpdateNewsItemPayload = {
   __typename?: 'UpdateNewsItemPayload',
  newsItem?: Maybe<NewsItem>,
};

export type UpdateNewsItemViewCountPayload = {
   __typename?: 'UpdateNewsItemViewCountPayload',
  newsItem?: Maybe<NewsItem>,
};

export type UpdateOrganizationInput = {
  address?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  establishedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  jobCategoryId?: Maybe<Scalars['ID']>,
  logoAttachmentId?: Maybe<Scalars['ID']>,
  phoneNumber?: Maybe<Scalars['String']>,
  scale?: Maybe<OrganizationScale>,
  websiteUrl?: Maybe<Scalars['String']>,
};

export type UpdateQnAInput = {
  answer?: Maybe<Scalars['String']>,
  blankType?: Maybe<BlankType>,
  charMaxSize?: Maybe<Scalars['Int']>,
  charSize?: Maybe<CharSize>,
  id: Scalars['ID'],
  question?: Maybe<Scalars['String']>,
};

export type UpdateQnAPayload = {
   __typename?: 'UpdateQnAPayload',
  qna?: Maybe<QnA>,
};

export type UpdateRecommendSearchKeywordInput = {
  id: Scalars['ID'],
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<RecommendSearchKeywordType>,
};

export type UpdateRecommendSearchKeywordPayload = {
   __typename?: 'UpdateRecommendSearchKeywordPayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type UpdateRecommendSearchKeywordsInput = {
  recommendSearchKeywords: Array<UpdateManyRecommendSearchKeywordInput>,
};

export type UpdateRecommendSearchKeywordsPayload = {
   __typename?: 'UpdateRecommendSearchKeywordsPayload',
  recommendSearchKeywords?: Maybe<Array<Maybe<RecommendSearchKeyword>>>,
};

export type UpdateRecruitInput = {
  activityID: Scalars['ID'],
  applicationFileAttachmentIDs?: Maybe<Array<Scalars['String']>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypeID?: Maybe<Scalars['ID']>,
  categories?: Maybe<Array<Scalars['ID']>>,
  detailText?: Maybe<Scalars['String']>,
  detailTextID?: Maybe<Scalars['ID']>,
  duties?: Maybe<Array<DutyInput>>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  isAllSameQuestion?: Maybe<Scalars['Boolean']>,
  isManagerEmailVisible?: Maybe<Scalars['Boolean']>,
  isManagerPhoneNumberVisible?: Maybe<Scalars['Boolean']>,
  isSubmit?: Maybe<Scalars['Boolean']>,
  jobTypes?: Maybe<Array<JobType>>,
  logo?: Maybe<ActivityFileAttachmentInput>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  outlink?: Maybe<Scalars['String']>,
  questionType?: Maybe<QuestionType>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType?: Maybe<ActivityRecruitType>,
  regionDistricts?: Maybe<Array<Scalars['ID']>>,
  regions?: Maybe<Array<Scalars['ID']>>,
  title: Scalars['String'],
};

export type UpdateRecruitPayload = {
   __typename?: 'UpdateRecruitPayload',
  activity?: Maybe<Activity>,
};

export type UpdateSelfIntroductionInput = {
  folderId?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  qnas?: Maybe<Array<UpdateQnAInput>>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateSelfIntroductionPayload = {
   __typename?: 'UpdateSelfIntroductionPayload',
  selfIntroduction?: Maybe<SelfIntroduction>,
};

export type UpdateSubscriptionInput = {
  acceptedMentorCommentReward?: Maybe<Scalars['Boolean']>,
  activity?: Maybe<Scalars['Boolean']>,
  activityReply?: Maybe<Scalars['Boolean']>,
  chatMessageReply?: Maybe<Scalars['Boolean']>,
  comment?: Maybe<Scalars['Boolean']>,
  info?: Maybe<Scalars['Boolean']>,
  matchedMentorCommentReward?: Maybe<Scalars['Boolean']>,
  mentorPost?: Maybe<Scalars['Boolean']>,
  newsComment?: Maybe<Scalars['Boolean']>,
  newsItem?: Maybe<Scalars['Boolean']>,
  post?: Maybe<Scalars['Boolean']>,
  recommendedMentorPost?: Maybe<Scalars['Boolean']>,
  scrap?: Maybe<Scalars['Boolean']>,
};

export type UpdateSubscriptionPayload = {
   __typename?: 'UpdateSubscriptionPayload',
  user?: Maybe<User>,
};

export type UpdateTagCollectionInput = {
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
  tags?: Maybe<Array<UpsertTagInput>>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateTagCollectionPayload = {
   __typename?: 'UpdateTagCollectionPayload',
  tagCollection?: Maybe<TagCollection>,
};

export type UpdateTagCollectionsInput = {
  id?: Maybe<Scalars['ID']>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
};

export type UpdateTagCollectionsPayload = {
   __typename?: 'UpdateTagCollectionsPayload',
  tagCollections?: Maybe<Array<Maybe<TagCollection>>>,
};

export type UpdateTestUserInput = {
  email?: Maybe<Scalars['String']>,
  specupJoined?: Maybe<Scalars['Boolean']>,
  username?: Maybe<Scalars['String']>,
};

export type UpdateTestUserPayload = {
   __typename?: 'UpdateTestUserPayload',
  user?: Maybe<User>,
};

export type UpdateUserInitialInformationInput = {
  birthday?: Maybe<Scalars['Date']>,
  education: UserEducationInput,
  phoneNumber: Scalars['String'],
  preferActivityTypes: Array<Scalars['Int']>,
  preferJobCategoryIds: Array<Scalars['ID']>,
  preferOrganizationIds?: Maybe<Array<Scalars['ID']>>,
  smsAuthedToken: Scalars['String'],
  subscribeSMS: Scalars['Boolean'],
};

export type UpdateUserInitialInformationPayload = {
   __typename?: 'UpdateUserInitialInformationPayload',
  user: User,
};

export type UpdateUserPhoneNumberByAuthUserIdInput = {
  phoneNumber: Scalars['String'],
  userID: Scalars['Int'],
};

export type UpdateUserPhoneNumberByAuthUserIdPayload = {
   __typename?: 'UpdateUserPhoneNumberByAuthUserIdPayload',
  user?: Maybe<User>,
};


export type UpsertLinkareerMagazineInput = {
  content?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  pcImage?: Maybe<Scalars['String']>,
  pcImageId: Scalars['ID'],
  seq: Scalars['Int'],
  title: Scalars['String'],
  url: Scalars['String'],
};

export type UpsertManyLinkareerMagazineInput = {
  linkareerMagazines: Array<UpsertLinkareerMagazineInput>,
};

export type UpsertManyLinkareerMagazinePayload = {
   __typename?: 'UpsertManyLinkareerMagazinePayload',
  linkareerMagazines: Array<Maybe<LinkareerMagazine>>,
};

export type UpsertTagInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
};

export type User = {
   __typename?: 'User',
  chatMentorAuthenticationStatus?: Maybe<Scalars['String']>,
  chatMentorOrganizationName?: Maybe<Scalars['String']>,
  chatMentorDutyName?: Maybe<Scalars['String']>,
  chatUserType?: Maybe<UserType>,
  chatOrganization?: Maybe<ChatOrganization>,
  id: Scalars['ID'],
  chatNickname: Scalars['String'],
  chatNicknameModifiableCount: Scalars['Int'],
  profileImage: ProfileImageTemplate,
  chatBannedUntil?: Maybe<Scalars['Date']>,
  chatSubscription?: Maybe<UserSubscription>,
  chatIsBanned: Scalars['Boolean'],
  chatRooms: ChatRoomConnection,
  chatBlockedUsers?: Maybe<Array<User>>,
  chatLastUserBan?: Maybe<UserBanInChat>,
  activitiesCountByUser?: Maybe<Scalars['Int']>,
  avatarUrl?: Maybe<Scalars['String']>,
  benefits?: Maybe<Array<Maybe<Benefit>>>,
  birthday?: Maybe<Scalars['Date']>,
  bizActivities?: Maybe<ActivityConnection>,
  bizActivityCountByStatus: Array<BizActivityCountByStatusPayload>,
  bookmarks?: Maybe<BookmarkedConnection>,
  categories?: Maybe<Array<Maybe<Category>>>,
  channel?: Maybe<Channel>,
  communityUserId?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['Date']>,
  customizedActivities?: Maybe<ActivityConnection>,
  deactivated: Scalars['Boolean'],
  deletedAt?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  facebook?: Maybe<FacebookAccount>,
  favoriteCategory?: Maybe<Category>,
  followings?: Maybe<FollowingConnection>,
  gender?: Maybe<Gender>,
  interests?: Maybe<Array<Maybe<Interest>>>,
  isCheckedParagraphScrapGuide?: Maybe<Scalars['Boolean']>,
  isFirst: Scalars['Boolean'],
  isInitializedUserInfo: Scalars['Boolean'],
  isStaff: Scalars['Boolean'],
  isSuperuser: Scalars['Boolean'],
  kakao?: Maybe<KakaoAccount>,
  lastLoginAt?: Maybe<Scalars['Date']>,
  lastLoginSession?: Maybe<Scalars['ID']>,
  likes: LikedConnection,
  login?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  mentorAuthenticationStatus?: Maybe<Scalars['String']>,
  mentorDutyName?: Maybe<Scalars['String']>,
  mentorOrganizationName?: Maybe<Scalars['String']>,
  naver?: Maybe<NaverAccount>,
  newsComments?: Maybe<NewsCommentConnection>,
  newsItemFeed?: Maybe<NewsItemConnection>,
  notificationUnreadCount?: Maybe<Scalars['Int']>,
  notifications?: Maybe<NotificationConnection>,
  organization?: Maybe<Organization>,
  persona?: Maybe<Persona>,
  phoneNumber?: Maybe<Scalars['String']>,
  preferInterests?: Maybe<Array<Maybe<Interest>>>,
  preferTypes?: Maybe<Array<Maybe<ActivityType>>>,
  profile?: Maybe<Profile>,
  receiveEmailAt?: Maybe<Scalars['Date']>,
  receiveSMSAt?: Maybe<Scalars['Date']>,
  regions?: Maybe<Array<Maybe<Region>>>,
  resumes?: Maybe<Array<Maybe<Resume>>>,
  scrapCount?: Maybe<Scalars['Int']>,
  scraps?: Maybe<Array<Maybe<UserScrap>>>,
  signupSession?: Maybe<SignupSession>,
  skills?: Maybe<Array<Maybe<Skill>>>,
  specupJoined: Scalars['Boolean'],
  specupJoinedAt?: Maybe<Scalars['Date']>,
  specupJoinedSession?: Maybe<Scalars['ID']>,
  subscribeAcceptedMentorCommentReward: Scalars['Boolean'],
  subscribeActivity: Scalars['Boolean'],
  subscribeActivityReply: Scalars['Boolean'],
  subscribeComment: Scalars['Boolean'],
  subscribeEmail: Scalars['Boolean'],
  subscribeInfo: Scalars['Boolean'],
  subscribeMatchedMentorCommentReward: Scalars['Boolean'],
  subscribeMentorPost: Scalars['Boolean'],
  subscribeNewsComment: Scalars['Boolean'],
  subscribeNewsItem: Scalars['Boolean'],
  subscribePost: Scalars['Boolean'],
  subscribeRecommendedMentorPost: Scalars['Boolean'],
  subscribeSMS: Scalars['Boolean'],
  subscribeScrap: Scalars['Boolean'],
  timespread?: Maybe<TimespreadAccount>,
  timespreadAuthKey?: Maybe<Scalars['String']>,
  timespreadID?: Maybe<Scalars['ID']>,
  tokenSet?: Maybe<TokenSet>,
  type: UserType,
  username?: Maybe<Scalars['String']>,
};


export type UserChatRoomsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<UserChatRoomsFilterBy>
};


export type UserBizActivitiesArgs = {
  filterBy: BizActivityFilter,
  orderBy?: Maybe<BizActivityOrder>,
  pagination?: Maybe<Pagination>
};


export type UserBookmarksArgs = {
  pagination?: Maybe<Pagination>
};


export type UserFollowingsArgs = {
  filterBy?: Maybe<FollowingFilter>,
  pagination?: Maybe<Pagination>
};


export type UserLikesArgs = {
  pagination?: Maybe<Pagination>
};


export type UserNewsCommentsArgs = {
  pagination?: Maybe<Pagination>
};


export type UserNewsItemFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<NewsItemOrder>
};


export type UserNotificationUnreadCountArgs = {
  notificationType?: Maybe<NotificationScope>
};


export type UserNotificationsArgs = {
  filterBy?: Maybe<NotificationsFilter>,
  pagination?: Maybe<Pagination>
};

export type UserAuthorizePhoneNumberInput = {
  authID: Scalars['Int'],
  code: Scalars['String'],
  phoneNumber: Scalars['String'],
  signupToken?: Maybe<Scalars['String']>,
};

export type UserAuthorizePhoneNumberPayload = {
   __typename?: 'UserAuthorizePhoneNumberPayload',
  authorizeID?: Maybe<Scalars['Int']>,
};

export type UserBanInChat = {
   __typename?: 'UserBanInChat',
  id: Scalars['ID'],
  bannedAt: Scalars['Date'],
  bannedUntil: Scalars['Date'],
  unbannedAt?: Maybe<Scalars['Date']>,
  bannedUser: User,
  bannedReason: Scalars['String'],
};

export type UserBanOffsetConnection = {
   __typename?: 'UserBanOffsetConnection',
  nodes: Array<UserBanInChat>,
  totalCount: Scalars['Int'],
};

export type UserChatRoomsFilterBy = {
  activityTypeID?: Maybe<Scalars['Int']>,
  chatRoomType?: Maybe<ChatRoomType>,
};

export type UserCheckPhoneNumberAuthorizationPayload = {
   __typename?: 'UserCheckPhoneNumberAuthorizationPayload',
  authID?: Maybe<Scalars['Int']>,
};

export type UserConnection = {
   __typename?: 'UserConnection',
  nodes?: Maybe<Array<Maybe<User>>>,
  totalCount: Scalars['Int'],
};

export type UserDeactivatePayload = {
   __typename?: 'UserDeactivatePayload',
  user?: Maybe<User>,
};

export type UserDeleteManyPayload = {
   __typename?: 'UserDeleteManyPayload',
  users: Array<Maybe<User>>,
};

export type UserDeletePayload = {
   __typename?: 'UserDeletePayload',
  user?: Maybe<User>,
};

export enum UserDeleteReasonType {
  ETC = 'ETC',
  LACK_OF_CONTENT_QUALITY = 'LACK_OF_CONTENT_QUALITY',
  LOW_FREQUENCY_USES = 'LOW_FREQUENCY_USES',
  OUT_OF_JOB_MARKET = 'OUT_OF_JOB_MARKET',
  POOR_SECURITY = 'POOR_SECURITY',
  POOR_UX_UI = 'POOR_UX_UI'
}

export enum UserEducation {
  COLLEGE_GRADUATE_2_3_YEARS = 'COLLEGE_GRADUATE_2_3_YEARS',
  COLLEGE_GRADUATE_4_YEARS = 'COLLEGE_GRADUATE_4_YEARS',
  HIGH_SCHOOL_GRADUATE = 'HIGH_SCHOOL_GRADUATE',
  MASTER_GRADUATE = 'MASTER_GRADUATE'
}

export type UserEducationDto = {
   __typename?: 'UserEducationDto',
  educationType: UserEducation,
  major?: Maybe<Major>,
  school?: Maybe<School>,
};

export type UserEducationInput = {
  finalEducationType: UserEducation,
  major?: Maybe<Scalars['String']>,
  schoolName: Scalars['String'],
};

export type UserFilters = {
  isConfirmed?: Maybe<Scalars['Boolean']>,
  isOrganization?: Maybe<Scalars['Boolean']>,
  q?: Maybe<Scalars['String']>,
  signupStep?: Maybe<UserSignupStepFilter>,
};

export type UserInput = {
  alias?: Maybe<Scalars['String']>,
  deleteLicenseImageIds?: Maybe<Array<Scalars['ID']>>,
  email?: Maybe<Scalars['String']>,
  isConfirmed?: Maybe<Scalars['Boolean']>,
  licenseImages?: Maybe<Array<Scalars['Upload']>>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  password?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  specupJoined?: Maybe<Scalars['Boolean']>,
  type?: Maybe<UserType>,
  username?: Maybe<Scalars['String']>,
};

export type UserIsDuplicateLoginInput = {
  login: Scalars['String'],
};

export type UserIsDuplicateNicknameInput = {
  nickname: Scalars['String'],
  signupToken: Scalars['String'],
};

export type UserIsDuplicateNicknameWithoutTokenInput = {
  nickname: Scalars['String'],
};

export type UserOrder = {
  direction: OrderDirection,
  field: UserOrderField,
};

export enum UserOrderField {
  CONFIRMATION_REQUESTED_AT = 'CONFIRMATION_REQUESTED_AT',
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  LAST_LOGIN_AT = 'LAST_LOGIN_AT',
  SPECUP_JOINED = 'SPECUP_JOINED'
}

export type UserScrap = {
   __typename?: 'UserScrap',
  activity?: Maybe<Activity>,
  coverLetter?: Maybe<CoverLetter>,
  coverLetterParagraphScrap?: Maybe<CoverLetterParagraphScrap>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  scrapType: UserScrapType,
  scrapedURL: Scalars['String'],
  user?: Maybe<User>,
  userOS: Scalars['String'],
};

export type UserScrapCommunity = {
   __typename?: 'UserScrapCommunity',
  createdAt?: Maybe<Scalars['Date']>,
  externalID?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  listOrder?: Maybe<Scalars['Int']>,
  post?: Maybe<Post>,
  postID: Scalars['ID'],
  userID: Scalars['ID'],
};

export type UserScrapCommunityOrder = {
  direction: OrderDirection,
  field: UserScrapCommunityOrderField,
};

export enum UserScrapCommunityOrderField {
  CREATED_AT = 'CREATED_AT',
  LIST_ORDER = 'LIST_ORDER'
}

export type UserScrapCountsPayload = {
   __typename?: 'UserScrapCountsPayload',
  activityCounts: Scalars['Int'],
  communityCounts: Scalars['Int'],
  coverLetterAndParagraphCounts: Scalars['Int'],
  coverLetterCounts: Scalars['Int'],
  coverLetterParagraphCounts: Scalars['Int'],
  newsItemCounts: Scalars['Int'],
};

export type UserScrapDeleteAllPayload = {
   __typename?: 'UserScrapDeleteAllPayload',
  count: Scalars['Int'],
  coverLetterParagraphScraps: Array<CoverLetterParagraphScrap>,
  deletedUserScraps: Array<DeletedUserScrap>,
};

export enum UserScrapOrderField {
  CREATED_AT = 'CREATED_AT'
}

export type UserScrapResult = NewsItem | UserScrap | UserScrapCommunity;

export type UserScrapsConnection = {
   __typename?: 'UserScrapsConnection',
  nodes?: Maybe<Array<Maybe<UserScrapResult>>>,
  totalCount: Scalars['Int'],
};

export type UserScrapsFilter = {
  userScrapType: UserScrapType,
};

export type UserScrapsOrder = {
  direction: OrderDirection,
  field: UserScrapOrderField,
};

export enum UserScrapType {
  ACTIVITY = 'ACTIVITY',
  COMMUNITY = 'COMMUNITY',
  COVER_LETTER = 'COVER_LETTER',
  COVER_LETTER_AND_PARAGRAPH = 'COVER_LETTER_AND_PARAGRAPH',
  COVER_LETTER_PARAGRAPH = 'COVER_LETTER_PARAGRAPH',
  NEWS_ITEM = 'NEWS_ITEM'
}

export type UserSignupPayload = {
   __typename?: 'UserSignupPayload',
  accessToken?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  refreshToken?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export enum UserSignupStepFilter {
  ALL = 'ALL',
  ONLY_LINKAREER = 'ONLY_LINKAREER',
  ONLY_TIMESPREAD = 'ONLY_TIMESPREAD',
  SIGNUP_SKIP = 'SIGNUP_SKIP',
  SOMETHING_WRONG = 'SOMETHING_WRONG',
  SPECUP_JOINED = 'SPECUP_JOINED'
}

export type UserSignupWithEmailInput = {
  birthday?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  nickname?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  phoneNumberAuthID?: Maybe<Scalars['Int']>,
  profileURL?: Maybe<Scalars['String']>,
  signupToken: Scalars['String'],
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeSMS?: Maybe<Scalars['Boolean']>,
};

export type UserSignupWithOAuthInput = {
  birthday?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  nickname?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  phoneNumberAuthID?: Maybe<Scalars['Int']>,
  profileURL?: Maybe<Scalars['String']>,
  signupToken: Scalars['String'],
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeSMS?: Maybe<Scalars['Boolean']>,
};

export type UserStatistic = {
   __typename?: 'UserStatistic',
  createdAt: Scalars['Date'],
  date: Scalars['Date'],
  id: Scalars['ID'],
  normalSignupCount?: Maybe<Scalars['Int']>,
  orgSignupCount?: Maybe<Scalars['Int']>,
  totalSignupCount?: Maybe<Scalars['Int']>,
  updatedAt: Scalars['Date'],
};

export type UserStatisticConnection = {
   __typename?: 'UserStatisticConnection',
  nodes?: Maybe<Array<UserStatistic>>,
  totalCount: Scalars['Int'],
};

export type UserSubscription = {
   __typename?: 'UserSubscription',
  subscribeChatNotification: Scalars['Boolean'],
};

export enum UserType {
  NORMAL = 'NORMAL',
  MANAGER = 'MANAGER',
  WRITER = 'WRITER',
  MANAGER_VERIFIED = 'MANAGER_VERIFIED',
  MANAGER_UNVERIFIED = 'MANAGER_UNVERIFIED',
  MANAGER_REJECTED = 'MANAGER_REJECTED'
}

export type UserUnlinkTimespreadPayload = {
   __typename?: 'UserUnlinkTimespreadPayload',
  user?: Maybe<User>,
};

export type UserUpdateBirthInput = {
  birthday: Scalars['Date'],
};

export type UserUpdatePayload = {
   __typename?: 'UserUpdatePayload',
  user?: Maybe<User>,
};

export type UserUpdatePhoneNumberAuthorizationInput = {
  authorizeID: Scalars['Int'],
  phoneNumber: Scalars['String'],
};

export type ViewerCheckedParagraphScrapGuidePayload = {
   __typename?: 'ViewerCheckedParagraphScrapGuidePayload',
  user?: Maybe<User>,
};

export type ViewerNewsItemFeedOrder = {
  direction: OrderDirection,
  field: ViewerNewsItemFeedOrderField,
};

export enum ViewerNewsItemFeedOrderField {
  CREATED_AT = 'CREATED_AT'
}

export type WelfareBenefit = {
   __typename?: 'WelfareBenefit',
  children: Array<WelfareBenefit>,
  id: Scalars['ID'],
  name: Scalars['String'],
  parent?: Maybe<WelfareBenefit>,
};

export type Workfield = {
   __typename?: 'Workfield',
  field: Scalars['String'],
  id: Scalars['ID'],
};

export type WorkingConditionInput = {
  salary: WorkingConditionSalaryInput,
  workHour?: Maybe<WorkingConditionWorkHourInput>,
  workingRegions: Array<WorkingConditionRegionInput>,
};

export type WorkingConditionRegionInput = {
  address: Scalars['String'],
  bcode: Scalars['String'],
  bname: Scalars['String'],
  detailAddress?: Maybe<Scalars['String']>,
  isAbroad: Scalars['Boolean'],
  isPossibleWorkingFromHome: Scalars['Boolean'],
  sido: Scalars['String'],
  sigungu: Scalars['String'],
  sigunguCode: Scalars['String'],
  zoneCode: Scalars['String'],
};

export type WorkingConditionSalaryInput = {
  doesSalaryDecidedAfterInterview: Scalars['Boolean'],
  doesSalaryDecidedByCompanyPolicy: Scalars['Boolean'],
  maxSalary?: Maybe<Scalars['Int']>,
  minSalary?: Maybe<Scalars['Int']>,
  salaryType?: Maybe<ActivityWorkingConditionSalarySalaryType>,
};

export type WorkingConditionWorkHourInput = {
  doesWorkHourDecidedAfterInterview?: Maybe<Scalars['Boolean']>,
  workEndTime?: Maybe<Scalars['String']>,
  workHourType?: Maybe<ActivityWorkingConditionWorkHourType>,
  workStartTime?: Maybe<Scalars['String']>,
  workingHours?: Maybe<Scalars['String']>,
};

export type AuthLoginMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type AuthLoginMutation = (
  { __typename?: 'Mutation' }
  & { login: Maybe<(
    { __typename?: 'LoginPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type AuthLogoutMutationVariables = {};


export type AuthLogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type AuthCheckQueryVariables = {};


export type AuthCheckQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isSuperuser'>
  )> }
);

export type AuthGetPermissionsQueryVariables = {};


export type AuthGetPermissionsQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);


export const AuthLoginDocument = gql`
    mutation AuthLogin($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
    }
  }
}
    `;
export type AuthLoginMutationFn = ApolloReactCommon.MutationFunction<AuthLoginMutation, AuthLoginMutationVariables>;

/**
 * __useAuthLoginMutation__
 *
 * To run a mutation, you first call `useAuthLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLoginMutation, { data, loading, error }] = useAuthLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthLoginMutation, AuthLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthLoginMutation, AuthLoginMutationVariables>(AuthLoginDocument, baseOptions);
      }
export type AuthLoginMutationHookResult = ReturnType<typeof useAuthLoginMutation>;
export type AuthLoginMutationResult = ApolloReactCommon.MutationResult<AuthLoginMutation>;
export type AuthLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthLoginMutation, AuthLoginMutationVariables>;
export const AuthLogoutDocument = gql`
    mutation AuthLogout {
  logout
}
    `;
export type AuthLogoutMutationFn = ApolloReactCommon.MutationFunction<AuthLogoutMutation, AuthLogoutMutationVariables>;

/**
 * __useAuthLogoutMutation__
 *
 * To run a mutation, you first call `useAuthLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLogoutMutation, { data, loading, error }] = useAuthLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthLogoutMutation, AuthLogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthLogoutMutation, AuthLogoutMutationVariables>(AuthLogoutDocument, baseOptions);
      }
export type AuthLogoutMutationHookResult = ReturnType<typeof useAuthLogoutMutation>;
export type AuthLogoutMutationResult = ApolloReactCommon.MutationResult<AuthLogoutMutation>;
export type AuthLogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthLogoutMutation, AuthLogoutMutationVariables>;
export const AuthCheckDocument = gql`
    query AuthCheck {
  currentUser {
    id
    isSuperuser
  }
}
    `;

/**
 * __useAuthCheckQuery__
 *
 * To run a query within a React component, call `useAuthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthCheckQuery, AuthCheckQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, baseOptions);
      }
export function useAuthCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthCheckQuery, AuthCheckQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, baseOptions);
        }
export type AuthCheckQueryHookResult = ReturnType<typeof useAuthCheckQuery>;
export type AuthCheckLazyQueryHookResult = ReturnType<typeof useAuthCheckLazyQuery>;
export type AuthCheckQueryResult = ApolloReactCommon.QueryResult<AuthCheckQuery, AuthCheckQueryVariables>;
export const AuthGetPermissionsDocument = gql`
    query AuthGetPermissions {
  currentUser {
    id
  }
}
    `;

/**
 * __useAuthGetPermissionsQuery__
 *
 * To run a query within a React component, call `useAuthGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthGetPermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>(AuthGetPermissionsDocument, baseOptions);
      }
export function useAuthGetPermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>(AuthGetPermissionsDocument, baseOptions);
        }
export type AuthGetPermissionsQueryHookResult = ReturnType<typeof useAuthGetPermissionsQuery>;
export type AuthGetPermissionsLazyQueryHookResult = ReturnType<typeof useAuthGetPermissionsLazyQuery>;
export type AuthGetPermissionsQueryResult = ApolloReactCommon.QueryResult<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>;